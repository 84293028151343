const serviks = {
  en: {
    "cleaned_floor": "cleaned floor",
    "cleaned_toilet": "cleaned toilet",
    "cleaned_shower": "cleaned shower",
    "cleaned_sink": "cleaned sink",
    "cleaned_mirror": "cleaned mirror",
    "cleaned_stainless_steel": "cleaned stainless steel",
    "bad_smell": "bad smell",
    "dirty_floor": "dirty floor",
    "dirty_lavatory_seat": "dirty lavatory seat",
    "dirty_shower": "dirty shower",
    "dirty_sink": "dirty sink",
    "dirty_mirror": "dirty mirror",
    "dirty_stainless_steel": "dirty stainless steel",
    "missing_paper_towel": "missing paper towel",
    "missing_soap": "missing soap",
    "missing_toilet_paper": "missing toilet paper",
    "problem_shower": "problem shower",
    "problem_sink": "problem sink",
    "problem_mirror": "problem mirror",
    "problem_stainless_steel": "problem stainless steel",
    "problem_blockage": "problem blockage",
    "problem_door": "problem door",
    "problem_fluid_taps": "problem fluid taps",
    "problem_lightning": "problem lightning",
    "problem_broken_toilet": "problem broken toilet",
    "fixed_shower": "fixed shower",
    "fixed_sink": "fixed sink",
    "fixed_mirror": "fixed mirror",
    "fixed_stainless_steel": "fixed stainless steel",
    "fixed_blockage": "fixed blockage",
    "fixed_door": "fixed door",
    "fixed_fluid_taps": "fixed fluid taps",
    "fixed_lightning": "fixed lightning",
    "fixed_broken_toilet": "fixed broken toilet",
    "very_good": "very good",
    "excellent": "excellent",
    "good": "good",
    "like": "like",
    "needs_improvement": "needs improvement",
    "not_good": "not good",
    "supervise": "supervise",
    "maintenance": "maintenance",
    "cleaner": "cleaner",
    "tag_cleaner": "tag cleaner",
    "zone_is_clean": "zone is clean",
    "zone_is_dirty": "zone is dirty",
    "cleaner_arrived": "cleaner arrived",
    "start_clean_zone": "start clean zone",
    "counter": "counter",
    "counter_men": "counter men",
    "counter_woman": "counter woman",
    "counter_handycap": "counter handycap",
    "full_garbage_can": "full garbage can",
    "maintenance_fixed": "maintenance fixed",
    "maintenance_problem": "maintenance problem",
    "problem_soap_dispenser": "problem soap dispenser",
    "problem_paper_dispenser": "problem paper dispenser",
    "end_clean_zone": "end clean zone",
    "status_toilet_paper": "status toilet paper",
    "status_hend_paper": "status hend paper",
    "fixed": "fixed",
    "status_soap": "status soap",
    "status_garbage_can": "status garbage can",
    "general_cleaning": "general cleaning",
    "coffee_tea": "coffee tea",
    "water_bar": "water bar",
    "disposable_dishes": "disposable dishes",
    "head_coverage": "head coverage",
    "something_else": "something else",
    "missing_clothing": "missing clothing",
    "maintenance_link": "maintenance link",
    "problem_maintenance": "problem maintenance",
    "supervise_link": "supervise link",
    "cleaner_link": "cleaner link",
    "room_men+link": "room men + link",
    "room_men-link": "room men - link",
    "room_women+link": "room women + link",
    "room_women-link": "room women - link",
    "hebrew": "hebrew",
    "español": "español",
    "français": "français",
    "english": "english",
    "русский": "русский",
    "italiano": "italiano",
    "አማርኛ": "አማርኛ",
    "português": "português",
    "arabic": "arabic",
    "partial_information": "partial information",
    "professionalism": "professionalism",
    "the_attitude_of_the_representative": "the attitude of the representative",
    "waiting_time_for_service": "waiting time for service",
    "other_aspect_of_service": "other aspect of service",
    "language_of_the_service": "language of the service",
    "procedure_for_making_an_appointment": "procedure for making an appointment",
    "physical_conditions": "physical conditions",
    "found_another": "found another",
    "found_similar": "found similar",
    "did_not_find": "did not find",
    "found": "found",
    "need_improved": "need improved",
    "liked": "liked",
    "pleasant": "pleasant",
    "eilat_resident": "eilat resident",
    "once_a_week": "once a week",
    "once_a_month": "once a month",
    "twice_a_year": "twice a year",
    "once_a_year": "once a year",
    "friend": "friend",
    "newspaper": "newspaper",
    "google": "google",
    "zap": "zap",
    "vacuum_cleaner": "vacuum cleaner",
    "gaming_consoles": "gaming consoles",
    "laptop": "laptop",
    "cellular": "cellular",
    "shop": "shop",
    "restaurant_complex_cleaning": "restaurant complex cleaning",
    "dining_room": "dining room",
    "food_variety": "food variety",
    "quality_of_the_food": "quality of the food",
    "amount_of_the_food": "amount of the food",
    "quality_of_cooking": "quality of cooking",
    "side_courses": "side courses",
    "meat": "meat",
    "desserts": "desserts",
    "vegetarian": "vegetarian",
    "salad_bar": "salad bar",
    "main_courses": "main courses",
    "service": "service",
    "dining_room_cleanliness": "dining room cleanliness",
    "plates_and_cutlery_cleanliness": "plates and cutlery cleanliness",
    "product_inventory": "product inventory",
    "furnishers": "furnishers",
    "toilets": "toilets",
    "problem_coffee_machine": "problem coffee machine",
    "problem_water_bar": "problem water bar",
    "dirty_stairs": "dirty stairs",
    "toiletries": "toiletries",
    "fixed_soap_dispenser": "fixed soap dispenser",
    "fixed_paper_dispenser": "fixed paper dispenser",
    "fixed_coffee_machine": "fixed coffee machine",
    "fixed_water_bar": "fixed water bar",
    "problem_urinals": "problem urinals",
    "blockage_toilet": "blockage toilet",
    "appliance_problem": "appliance problem",
    "problem_broken_door": "problem broken door",
    "problem_door_lock": "problem door lock",
    "problem_broken_toilet_seat": "problem broken toilet seat",
    "problem_broken_niagara": "problem broken niagara",
    " broken_hand_paper_dispenser_hand_dryer": "broken hand paper dispenser + hand dryer",
    "rug": "rug",
    "tables": "tables",
    "kitchen": "kitchen",
    "air_condition": "air condition",
    "dirty_urinals": "dirty urinals",
    "hand_dryer": "hand_dryer",
    "non_sticky_carpet": "non-sticky carpet",
    "problem_bad_smell": "problem bad smell",
    "problem_broken_toilet_handle": "problem broken toilet handle",
    "problem_handle_door": "problem handle door",
    "call_cleaner": "call cleaner",
    "wall_painting": "wall painting",
    "missing_paper": "missing paper",
    "clean_surface": "clean surface",
    "problem_right_elevator": "problem right elevator",
    "problem_left_elevator": "problem left elevator",
    "missing_hand_sanitizer": "missing hand sanitizer",
    "problem_sink_blockage": "problem sink blockage",
    "electric_problem": "electric problem",
    "missing_coffe_tea": "missing coffe tea",
    "problem_air_condition": "problem air condition",
    "dishwashing": "dishwashing",
    "kitchen_cleanliness": "kitchen cleanliness",
    "weekly_cleaning": "weekly cleaning",
    "general_arrangement": "general arrangement",
    "meeting_room": "meeting room",
    "refrigerator": "refrigerator",
    "kitchen_supplies": "kitchen supplies",
    "toilet_services": "toilet services",
    "carousel_door": "carousel door",
    "desk": "desk",
    "compressor": "compressor",
    "compressor_rails": "compressor rails",
    "compressor_drainage": "compressor drainage",
    "dust": "dust",
    "door": "door",
    "scotch": "scotch",
    "food": "food",
    "room_conditions": "room conditions",
    "kindness_sensitivity": "kindness and sensitivity",
    "explain_process": "explain the process",
    "personal_attention": "personal attention",
    "service_problem": "service problem",
    "welcome_you": "welcome you",
    "invoice_submitted": "invoice submitted",
    "thank_you_bought": "thank you bought",
    "problem_seller": "problem seller",
    "lapel_pin": "lapel pin",
    "proper_performance": "proper performance",
    "name_tag": "name tag",
    "operational": "operational",
    "app_revealed": "app revealed",
    "register_load": "register load",
    "visibility_problem": "visibility problem",
    "clean_storefront": "clean storefront",
    "clean_garbage": "clean garbage",
    "clean_tables_chairs": "clean tables and chairs",
    "clean_shop_floor": "clean shop floor",
    "product_show": "view of product",
    "refrigerators_show": "view of refrigerators",
    "coffee_accessories": "coffee accessories and equipped",
    "cleanliness": "cleanliness",
    "convenience_scheduling_appointments": "convenience of scheduling appointments",
    "waiting_haircut": "waiting for a haircut",
    "courtesy_books": "courtesy of the books",
    "books_professionalism": "the professionalism of the books",
    "received_time": "received me on time",
    "dnot_appointment": "i did not make an appointment",
    "an_appointment": "i made an appointment",
    "tasty": "tasty",
    "courtesy_service": "courtesy of the service",
    "overall_experience": "the overall experience",
    "not_clean": "not clean",
    "diningroom_not_clean": "the dining room is not clean",
    "unclean_dishes": "unclean dishes",
    "so-so": "so-so",
    "not_tasty": "not tasty",
    "kind": "kind",
    "not_kind": "not kind",
    "clean": "clean",
    "hairdresser": "hairdresser",
    "wrong_price": "wrong price",
    "produc_has_expired": "product has expired",
    "missing_item": "missing item",
    "item_is_faulty_in_the_machine": "item is faulty in the machine",
    "machine_cleaning": "machine cleaning",
    "improper_temperature": "improper temperature",
    "green": "green",
    "orange": "orange",
    "red": "red",
    "yellow": "yellow",
    "red not prob": "red not prob",
    "professional": "professional",
    "unprofessional": "unprofessional",
    "delicacies": "delicacies",
    "sandwiches": "sandwiches",
    "vegetables": "vegetables",
    "fruits": "fruits",
    "salads": "salads",
    "milk": "milk",
    "room_link": "room link",
    "floor_link": "floor link",
    "services": "services",
    "check_list": "check list",
    "lighting_problem_lobby": "lighting problem lobby",
    "lighting_problem_garbage_room": "lighting problem garbage room",
    "lighting_problem_stairwell": "lighting problem stairwell",
    "problem_elevator_1": "problem elevator 1",
    "problem_elevator_2": "problem elevator 2",
    "problem_elevator_3": "problem elevator 3",
    "problem_elevator_4": "problem elevator 4",
    "problem_elevator_5": "problem elevator 5",
    "problem_elevator_6": "problem elevator 6",
    "problem_elevator_7": "problem elevator 7",
    "problem_elevator_8": "problem elevator 8",
    "problem_elevator_9": "problem elevator 9",
    "problem_elevator_10": "problem elevator 10",
    "stuck_item": "stuck item",
    "spoiling_product": "spoiling product",
    "machine_malfunction": "machine malfunction",
    "unreadable_product": "unreadable product",
    "i_am_satisfied": "i am satisfied",
    "beverage": "beverage",
    "program_malfunction": "program malfunction",
    "machine_1": "machine 1",
    "machine_2": "machine 2",
    "machine_3": "machine 3",
    "machine_4": "machine 4",
    "program malfunction 1": "program malfunction 1",
    "program malfunction 2": "program malfunction 2",
    "program malfunction 3": "program malfunction 3",
    "program malfunction 4": "program malfunction 4",
    "fish": "fish",
    "chicken": "chicken",
    "full_dining_room": "full dining room",
    "missing_amount": "missing amount",
    "missing_trays": "missing trays",
    "dirty_floor_g1": "dirty floor g1",
    "dirty_floor_g2": "dirty floor g2",
    "dirty_floor_g3": "dirty floor g3",
    "dirty_floor_g4": "dirty floor g4",
    "dirty_floor_g5": "dirty floor g5",
    "dirty_floor_eo": "dirty floor eo",
    "dirty_floor_ceo": "dirty floor ceo",
    "dirty_floor_storage": "dirty floor storage",
    "dirty_floor_inflorescence": "dirty floor inflorescence",
    "operating_instructions": "operating instructions",
    "general": "general",
    "connection_problem": "connection problem",
    "lighting": "lighting",
    "not _fixed": "not fixed",
    "cleaning_needed": "cleaning needed",
    "missing_milk": "missing milk",
    "no_sugar_coffee": "no sugar / coffee",
    "missing_tools": "missing tools",
    "coffee_water_machine": "coffee / water machine",
    "appliances": "appliances",
    "products": "products",
    "broken_chair": "broken furniture",
    "broken_cabinet": "broken cabinet",
    "micro": "micro",
    "toaster": "toaster",
    "clogged_sink_malfunction": "clogged sink malfunction",
    "coffee_cooling_malfunction": "coffee cooling malfunction",
    "no_coffee_beans": "no coffee beans",
    "machine_failure": "machine failure",
    "that_dirty": "that dirty",
    "dirty_refrigerator": "dirty refrigerator",
    "dirty_tables": "dirty tables",
    "plates": "plates",
    "silver_spoon": "silver_spoon",
    "cold_drinks_cups": "cold drinks cups",
    "hot_drink_cups": "hot drink cups",
    "a_dripping_faucet": "a dripping faucet",
    "disposable dishes": "disposable dishes",
    "problem_ coffee_water_machine": "problem coffee / water machine",
    "problem_micro": "problem micro",
    "problem_toaster": "problem toaster",
    "problem_machine_failure": "problem machine failure",
    "problem refrigerator": "problem refrigerator",
    "no_sugar/coffee": "no sugar / coffee",
    "missing_dishes": "missing dishes",
    "coffee/water_machine": "coffee / water machine",
    "coffee_machine_door_open": "coffee machine door open",
    "coffee_machine_door_close": "coffee machine door close",
    "coffee_machine_bin_full": "coffee machine bin full",
    "coffee_machine_bin_empty": "coffee machine bin empty",
    "coffee_machine_coffee_full": "coffee machine coffee full",
    "coffee_machine_coffee_empty": "coffee machine coffee empty",
    "coffee_machine_water_tank_empty": "coffee machine water tank empty",
    "coffee_machine_ water_tank_full": "coffee machine watertank full",
    "coffee_machine_milk_cleaning_not_performed": "coffee machine milk cleaning not performed",
    "coffee_machine _milk_cleaning_performed": "coffee machine milk cleaning performed",
    "coffee_machine_head_cleaning_not_performed": "coffee machine head cleaning not performed",
    "coffee_machine_head_ cleaning_performed": "coffee machine head cleaning performed",
    "coffee_machine_descaling_not_performed": "coffee machine  descaling not performed",
    "coffee_machine_descaling_performed": "coffee machine descaling performed",
    "floor_one": "floor 1",
    "floor_two": "floor 2",
    "floor_three": "floor 3",
    "floor_four": "floor 4",
    "floor_five": "floor 5",
    "floor_six": "floor 6",
    "floor_seven": "floor 7",
    "floor_eight": "floor 8",
    "floor_nine": "floor 9",
    "floor_ten": "floor 10",
    "floor_eleven": "floor 11",
    "floor_twelve": "floor 12",
    "floor_thirteen": "floor 13",
    "floor_fourteen": "floor 14",
    "floor_fifteen": "floor 15",
    "floor_sixteen": "floor 16",
    "floor_seventeen": "floor 17",
    "floor_eighteen": "floor 18",
    "floor_nineteen": "floor 19",
    "floor_twenty": "floor 20",
    "floor_twenty_one": "floor 21",
    "floor_twenty_two": "floor 22",
    "floor_twenty_three": "floor 23",
    "floor_twenty_four": "floor 24",
    "floor_twenty_five": "floor 25",
    "status_toilet_paper_cell_01_-_empty": "Status Toilet Paper Cell 01 - Empty",
    "status_toilet_paper_cell_02_-_empty": "Status Toilet Paper Cell 02 - Empty",
    "status_toilet_paper_cell_03_-_empty": "Status Toilet Paper Cell 03 - Empty",
    "status_toilet_paper_cell_04_-_empty": "Status Toilet Paper Cell 04 - Empty",
    "status_toilet_paper_cell_05_-_empty": "Status Toilet Paper Cell 05 - Empty",
    "status_toilet_paper_cell_06_-_empty": "Status Toilet Paper Cell 06 - Empty",
    "status_toilet_paper_cell_07_-_empty": "Status Toilet Paper Cell 07 - Empty",
    "status_toilet_paper_cell_08_-_empty": "Status Toilet Paper Cell 08 - Empty",
    "status_toilet_paper_cell_09_-_empty": "Status Toilet Paper Cell 09 - Empty",
    "status_toilet_paper_cell_10_-_empty": "Status Toilet Paper Cell 10 - Empty",
    "status_toilet_paper_cell_11_-_empty": "Status Toilet Paper Cell 11 - Empty",
    "status_toilet_paper_cell_12_-_empty": "Status Toilet Paper Cell 12 - Empty",
    "status_toilet_paper_cell_13_-_empty": "Status Toilet Paper Cell 13 - Empty",
    "status_toilet_paper_cell_14_-_empty": "Status Toilet Paper Cell 14 - Empty",
    "status_toilet_paper_cell_15_-_empty": "Status Toilet Paper Cell 15 - Empty",
    "status_toilet_paper_cell_16_-_empty": "Status Toilet Paper Cell 16 - Empty",
    "status_toilet_paper_cell_17_-_empty": "Status Toilet Paper Cell 17 - Empty",
    "status_toilet_paper_cell_18_-_empty": "Status Toilet Paper Cell 18 - Empty",
    "status_toilet_paper_cell_19_-_empty": "Status Toilet Paper Cell 19 - Empty",
    "status_toilet_paper_cell_20_-_empty": "Status Toilet Paper Cell 20 - Empty",
    "status_toilet_paper_cell_01_-_almost_empty": "Status Toilet Paper Cell 01 - Almost Empty",
    "status_toilet_paper_cell_02_-_almost_empty": "Status Toilet Paper Cell 02 - Almost Empty",
    "status_toilet_paper_cell_03_-_almost_empty": "Status Toilet Paper Cell 03 - Almost Empty",
    "status_toilet_paper_cell_04_-_almost_empty": "Status Toilet Paper Cell 04 - Almost Empty",
    "status_toilet_paper_cell_05_-_almost_empty": "Status Toilet Paper Cell 05 - Almost Empty",
    "status_toilet_paper_cell_06_-_almost_empty": "Status Toilet Paper Cell 06 - Almost Empty",
    "status_toilet_paper_cell_07_-_almost_empty": "Status Toilet Paper Cell 07 - Almost Empty",
    "status_toilet_paper_cell_08_-_almost_empty": "Status Toilet Paper Cell 08 - Almost Empty",
    "status_toilet_paper_cell_09_-_almost_empty": "Status Toilet Paper Cell 09 - Almost Empty",
    "status_toilet_paper_cell_10_-_almost_empty": "Status Toilet Paper Cell 10 - Almost Empty",
    "status_toilet_paper_cell_11_-_almost_empty": "Status Toilet Paper Cell 11 - Almost Empty",
    "status_toilet_paper_cell_12_-_almost_empty": "Status Toilet Paper Cell 12 - Almost Empty",
    "status_toilet_paper_cell_13_-_almost_empty": "Status Toilet Paper Cell 13 - Almost Empty",
    "status_toilet_paper_cell_14_-_almost_empty": "Status Toilet Paper Cell 14 - Almost Empty",
    "status_toilet_paper_cell_15_-_almost_empty": "Status Toilet Paper Cell 15 - Almost Empty",
    "status_toilet_paper_cell_16_-_almost_empty": "Status Toilet Paper Cell 16 - Almost Empty",
    "status_toilet_paper_cell_17_-_almost_empty": "Status Toilet Paper Cell 17 - Almost Empty",
    "status_toilet_paper_cell_18_-_almost_empty": "Status Toilet Paper Cell 18 - Almost Empty",
    "status_toilet_paper_cell_19_-_almost_empty": "Status Toilet Paper Cell 19 - Almost Empty",
    "status_toilet_paper_cell_20_-_almost_empty": "Status Toilet Paper Cell 20 - Almost Empty",
  },
  he: {
    "cleaned_floor": "הריצפה נקיה",
    "cleaned_toilet": "השירותים נוקו",
    "cleaned_shower": "המלתחות נוקו",
    "cleaned_sink": "הכיור נוקה",
    "cleaned_mirror": "המראה נקיה",
    "cleaned_stainless_steel": "נירוסטה נוקתה",
    "bad_smell": "ריח רע",
    "dirty_floor": "רצפה מלוכלכת",
    "dirty_lavatory_seat": "האסלה מלוכלכת",
    "dirty_shower": "המלתחות מלוכלכות",
    "dirty_sink": "כיור מלוכלך",
    "dirty_mirror": "מראה מלוכלכת",
    "dirty_stainless_steel": "נירוסטה מלוכלכת",
    "missing_paper_towel": "חסר ניר ידיים",
    "missing_soap": "חסר סבון",
    "missing_toilet_paper": "חסר נייר טואלט",
    "problem_shower": "בעיה במלתחה",
    "problem_sink": "בעיה בכיור",
    "problem_mirror": "בעיה במראה",
    "problem_stainless_steel": "בעיה בנירוסטה",
    "problem_blockage": "סתימה",
    "problem_door": "בעיה בדלת",
    "problem_fluid_taps": "ברזים נוזלים",
    "problem_lightning": "בעיית תאורה",
    "problem_broken_toilet": "אסלה שבורה",
    "fixed_shower": "המלתחות סודרו",
    "fixed_sink": "הכיור סודר",
    "fixed_mirror": "המראה סודרה",
    "fixed_stainless_steel": "הנירוסטה סודרה",
    "fixed_blockage": "סתימה סודרה",
    "fixed_door": "הדלת סודרה",
    "fixed_fluid_taps": "הנזילה סודרה",
    "fixed_lightning": "התאורה סודרה",
    "fixed_broken_toilet": "סודרה האסלה",
    "very_good": "טוב מאוד",
    "excellent": "מצויין",
    "good": "טוב",
    "like": "לייק",
    "needs_improvement": "טעון שיפור",
    "not_good": "לא טוב",
    "supervise": "מפקח",
    "maintenance": "תחזוקה",
    "cleaner": "מנקה",
    "tag_cleaner": "חישן הגעת מנקה",
    "zone_is_clean": "מפקח האזור נקי",
    "zone_is_dirty": "מפקח האזור מלוכלך",
    "cleaner_arrived": "מנקה הגיע",
    "start_clean_zone": "תחילת ניקיון",
    "counter": "קאונטר",
    "counter_men": "מספר גברים",
    "counter_woman": "מספר נשים",
    "counter_handycap": "מספר נכים",
    "full_garbage_can": "פח אשפה מלא",
    "maintenance_fixed": "תחזוקה סודרה",
    "maintenance_problem": "בעית תחזוקה",
    "problem_soap_dispenser": "בעית מתקן סבון",
    "problem_paper_dispenser": "בעית מתקן נייר",
    "end_clean_zone": "סיום ניקיון",
    "status_toilet_paper": "חישן ניר טואלט",
    "status_hend_paper": "חישן ניר ידים",
    "fixed": "תקין",
    "status_soap": "חישן נוזל סבון",
    "status_garbage_can": "חישן פח אשפה",
    "general_cleaning": "ניקיון כללי",
    "coffee_tea": "קפה / תה",
    "water_bar": "מתקן מים",
    "disposable_dishes": "כלים חד פעמיים",
    "head_coverage": "כיסוי חד״פ",
    "something_else": "משהו אחר",
    "missing_clothing": "חוסר בביגוד",
    "maintenance_link": "מעבר אחזקה",
    "problem_maintenance": "בעיית אחזקה",
    "supervise_link": "מעבר מפקח",
    "cleaner_link": "מעבר מנקה",
    "room_men+link": " מעבר חדר חיובי גברים",
    "room_men-link": " מעבר חדר שלילי גברים",
    "room_women+link": " מעבר חדר חיובי נשים",
    "room_women-link": " מעבר חדר שלילי נשים",
    "hebrew": "עברית",
    "español": "ספרדית",
    "français": "צרפתית",
    "english": "אנגלית",
    "русский": "רוסית",
    "italiano": "איטלקית",
    "አማርኛ": "אמהרית",
    "português": "פורטוגזית",
    "arabic": "ערבית",
    "partial_information": "מידע חלקי",
    "professionalism": "מקצועיות",
    "the_attitude_of_the_representative": "עמדתו של המייצג",
    "waiting_time_for_service": "זמן המתנה לשירות",
    "other_aspect_of_service": "היבט אחר של השירות",
    "language_of_the_service": "שפת השירות",
    "procedure_for_making_an_appointment": "נקיטת קביעת פגישה",
    "physical_conditions": "תנאים גופניים",
    "found_another": "בסוף מצאתי אחר",
    "found_similar": "מצאתי משהו דומה",
    "did_not_find": "לא מצאתי",
    "found": "מצאתי את המוצר",
    "need_improved": "ניתן לשפר",
    "liked": "אהבתי",
    "pleasant": "נעימה",
    "eilat_resident": "אני תושב אילת",
    "once_a_week": "פעם בשבוע",
    "once_a_month": "פעם בחודש",
    "twice_a_year": "פעמיים שלוש בשנה",
    "once_a_year": "פעם בשנה",
    "friend": "חבר",
    "newspaper": "עיתון",
    "google": "גוגל",
    "zap": "זאפ",
    "vacuum_cleaner": "שואב אבק",
    "gaming_consoles": "קונסולות גיימינג",
    "laptop": "מחשב נייד",
    "cellular": "סלולרי",
    "shop": "חנוית",
    "restaurant_complex_cleaning": "ניקיון מתחם מסעדה",
    "dining_room": "חדר אוכל",
    "food_variety": "מגוון המזון",
    "quality_of_the_food": "איכות המזון",
    "amount_of_the_food": "כמות  המזון",
    "quality_of_cooking": "איכות הבישול",
    "side_courses": "תוספות",
    "meat": "בשרי",
    "desserts": "קינוחים",
    "vegetarian": "צמחוני",
    "salad_bar": "סלטים",
    "main_courses": "המנה העיקרית",
    "service": "שירות",
    "dining_room_cleanliness": "ניקיון המתחם",
    "plates_and_cutlery_cleanliness": "ניקיון הסכום",
    "product_inventory": "מלאי פרודוקטים",
    "furnishers": "ריהוט",
    "toilets": "שירותים",
    "problem_coffee_machine": "בעיה מכונת קפה",
    "problem_water_bar": "בעיה בר מים",
    "dirty_stairs": "מדרגות כניסה מלוכלכות",
    "toiletries": "מוצרי טואלטיקה",
    "fixed_soap_dispenser": "מתקן סבון סודר",
    "fixed_paper_dispenser": "מתקן נייר סודר",
    "fixed_coffee_machine": "מכונת קפה סודרה",
    "fixed_water_bar": "בר מים סודר",
    "problem_urinals": "משתנה תקולה",
    "blockage_toilet": "ניאגרה תקולה",
    "appliance_problem": "מכשיר חשמל תקול",
    "problem_broken_door": "דלת שבורה",
    "problem_door_lock": "בעיה מנעול דלת",
    "problem_broken_toilet_seat": "אסלה שבורה",
    "problem_broken_niagara": "ניאגארה שבורה",
    " broken_hand_paper_dispenser_hand_dryer": "בעיה מיבש ידיים או דיספנסר",
    "rug": "שטיח",
    "tables": "שולחנות",
    "kitchen": "מטבח",
    "air_condition": "מיזוג",
    "dirty_urinals": "משתנה מלוכלכת",
    "hand_dryer": "מיבש ידים",
    "non_sticky_carpet": "שטיח לא דביק",
    "problem_bad_smell": "בעיה ריח רע",
    "problem_broken_toilet_handle": "בעיה ידית שירותים",
    "problem_handle_door": "בעיה ידית דלת",
    "call_cleaner": "קריאה למנקה",
    "wall_painting": "צביעת קירות",
    "missing_paper": "חסר נייר",
    "clean_surface": "נקיון משטחים",
    "problem_right_elevator": "תקלה מעלית ימין",
    "problem_left_elevator": "תקלה מעלית שמאל",
    "missing_hand_sanitizer": "חסר ספריי חיטוי",
    "problem_sink_blockage": "תקלה כיור סתום",
    "electric_problem": "בעיית חשמל",
    "missing_coffe_tea": "חסר קפה תה",
    "problem_air_condition": "בעיית מיזוג",
    "dishwashing": "שטיפת כליים",
    "kitchen_cleanliness": "נקיון מטבח",
    "weekly_cleaning": "נקיון שבועי",
    "general_arrangement": "סידור כללי",
    "meeting_room": "חדר ישיבות",
    "refrigerator": "מקרר",
    "kitchen_supplies": "אספקה מטבח",
    "toilet_services": "אספקה שירותים",
    "carousel_door": "דלת קרוסל ",
    "desk": "דלפק",
    "compressor": "דחסן",
    "compressor_rails": "דחסן מסילות",
    "compressor_drainage": "דחסן ניקוז",
    "dust": "אבק",
    "door": "דלת",
    "scotch": "סקוטש",
    "food": "אוכל",
    "room_conditions": "תנאים בחדר",
    "kindness_sensitivity": "אדיבות ורגישות",
    "explain_process": "הסבר התהליך",
    "personal_attention": "יחס אישי",
    "service_problem": "בעית שירות",
    "welcome_you": "קבל אותך בברכה",
    "invoice_submitted": "הגיש החשבונית תואמת מוצר",
    "thank_you_bought": "ברך תודה שקנית yellow ",
    "problem_seller": "בעית מוכרן",
    "lapel_pin": "עונד סיכת דש עגולה אפליקציה",
    "proper_performance": "בהופעה תקינה",
    "name_tag": "עונד תג שם",
    "operational": "היצא מבצעי החודש",
    "app_revealed": "חשף אותך לאפליקציית",
    "register_load": "עומס בקופה",
    "visibility_problem": "בעית נראות",
    "clean_storefront": "רחבת חזית החנות נקיה",
    "clean_garbage": "פחי האשפה נקיים ואינם",
    "clean_tables_chairs": "השולחנות וכיסאות נקיים",
    "clean_shop_floor": "רצפת החנות נקיה",
    "product_show": "תצוגת המוצרים מלאה",
    "refrigerators_show": "תצוגת המקררים מלאה ונקיה",
    "coffee_accessories": "מתקן לאביזרי קפה נקי ומאובזר",
    "cleanliness": "ניקיון",
    "convenience_scheduling_appointments": "נוחות קביעת תורים",
    "waiting_haircut": "המתנה לתספורת",
    "courtesy_books": "אדיבות הספרים",
    "books_professionalism": "מקצועיות הספרים",
    "received_time": "קיבלו אותי בזמן",
    "dnot_appointment": "לא תאמתי תור",
    "an_appointment": "תאמתי תור",
    "tasty": "טעים",
    "courtesy_service": "אדיבות השירות",
    "overall_experience": "החוויה הכללית",
    "not_clean": "לא נקי",
    "diningroom_not_clean": "חדר האוכל אינו נקי",
    "unclean_dishes": "כלים לא נקיים",
    "so-so": "ככה ככה",
    "not_tasty": "לא טעים",
    "kind": "אדיב",
    "not_kind": "לא אדיב",
    "clean": "נקי",
    "hairdresser": "ספר",
    "wrong_price": "מחיר שגוי",
    "produc_has_expired": "מוצר פג תוקף",
    "missing_item": "פריט חסר",
    "item_is_faulty_in_the_machine": "פריט תקוע תקלה במכונה",
    "machine_cleaning": "ניקיון מכונה",
    "improper_temperature": "טמפרטורה לא תקינה",
    "green": "ירוק",
    "orange": "כתום",
    "red": "אדום",
    "yellow": "צהוב",
    "red not prob": "אדום",
    "professional": "מקצועי",
    "unprofessional": "לא מקצועי",
    "delicacies": "מעדנים",
    "sandwiches": "כריכים",
    "vegetables": "ירקות",
    "fruits": "פירות",
    "salads": "סלטים",
    "milk": "חלב",
    "room_link": "חדר מעבר",
    "floor_link": "קומה מעבר",
    "services": "הספקה",
    "check_list": "רשימה",
    "lighting_problem_lobby": "בעיית תאורה בלובי",
    "lighting_problem_garbage_room": "בעיית תאורה בחדר אשפה",
    "lighting_problem_stairwell": "בעיית תאורה בחדר מדרגות",
    "problem_elevator_1": "תקלה במעלית 1",
    "problem_elevator_2": "תקלה במעלית 2",
    "problem_elevator_3": "תקלה במעלית 3",
    "problem_elevator_4": "תקלה במעלית 4",
    "problem_elevator_5": "תקלה במעלית 5",
    "problem_elevator_6": "תקלה במעלית 6",
    "problem_elevator_7": "תקלה במעלית 7",
    "problem_elevator_8": "תקלה במעלית 8",
    "problem_elevator_9": "תקלה במעלית 9",
    "problem_elevator_10": "תקלה במעלית 10",
    "stuck_item": "פריט תקוע",
    "spoiling_product": "מוצר מקלקל",
    "machine_malfunction": "תקלה במכונה",
    "unreadable_product": "מוצר לא קריא",
    "i_am_satisfied": "אני מרוצה",
    "beverage": "שתייה",
    "program_malfunction": "תקלה תכנית",
    "machine_1": "מכונה 1",
    "machine_2": "מכונה 2",
    "machine_3": "מכונה 3",
    "machine_4": "מכונה 4",
    "program malfunction 1": "תקלה בתכנית 1.",
    "program malfunction 2": "תקלה בתוכנית 2.",
    "program malfunction 3": "תקלה בתוכנית 3.",
    "program malfunction 4": "תקלה בתוכנית 4.",
    "fish": "דג",
    "chicken": "עוף",
    "full_dining_room": "חדר אוכל מלא",
    "missing_amount": "חסר סכום",
    "missing_trays": "חסר מגשים",
    "dirty_floor_g1": "ניקיון חממה 1",
    "dirty_floor_g2": "ניקיון חממה 2",
    "dirty_floor_g3": "ניקיון חממה 3",
    "dirty_floor_g4": "ניקיון חממה 4",
    "dirty_floor_g5": "ניקיון חממה 5",
    "dirty_floor_eo": "ניקיון לשכת סמנכ\"ל",
    "dirty_floor_ceo": "ניקיון לשכת מנכ\"ל",
    "dirty_floor_storage": "ניקיון בקומת מחסן",
    "dirty_floor_inflorescence": "ניקיון הפרחה",
    "operating_instructions": "הוראות הפעלה",
    "general": "כללי",
    "connection_problem": "בעיית חיבור",
    "lighting": "תאורה",
    "not _fixed": "לא תקין",
    "cleaning_needed": "דרוש ניקיון",
    "missing_milk": "חסר חלב",
    "no_sugar_coffee": "חסר סוכר/קפה",
    "missing_tools": "חסר כלים",
    "coffee_water_machine": "מכונות קפה / מכונות מים",
    "appliances": "מוצרי חשמל",
    "products": "פרודקטים",
    "broken_chair": "ריהוט תקול",
    "broken_cabinet": "ארונית שבורה",
    "micro": "מיקרו",
    "toaster": "טוסטר",
    "clogged_sink_malfunction": "תקלה כיור סתום",
    "coffee_cooling_malfunction": "תקלה בקירור הקפה",
    "no_coffee_beans": "חסר פולי קפה",
    "machine_failure": "תקלה במכונה",
    "that_dirty": "שיש מלוכלך",
    "dirty_refrigerator": "מקרר מלוכלך",
    "dirty_tables": "שולחנות מלוכלכים",
    "plates": "צלחות",
    "silver_spoon": "סכו״ם",
    "cold_drinks_cups": "כוסות שתיה קרה",
    "hot_drink_cups": "כוסות שתיה חמה",
    "a_dripping_faucet": "ברז מטפטף",
    "disposable dishes": "כלים חד פעמיים",
    "problem_ coffee_water_machine": "בעיה מכונות קפה / מכונות מים",
    "problem_micro": "בעיה מיקרו",
    "problem_toaster": "בעיה טוסטר",
    "problem_machine_failure": "בעיה תקלה במכונה",
    "problem refrigerator": "בעיה מקרר",
    "no_sugar/coffee": "חסר סוכר/קפה",
    "missing_dishes": "חסר כלים",
    "coffee/water_machine": "מכונות קפה / מכונות מים",
    "coffee_machine_door_open": "מכונת קפה דלת פתוחה",
    "coffee_machine_door_close": "מכונת קפה דלת נסגרה",
    "coffee_machine_bin_full": "מכונת קפה פח מלא",
    "coffee_machine_bin_empty": "מכונת קפה פח ריק",
    "coffee_machine_coffee_full": "מכונת קפה יש פוליים",
    "coffee_machine_coffee_empty": "מכונת קפה אין פוליים",
    "coffee_machine_water_tank_empty": "מכונת קפה מיכל מים ריק",
    "coffee_machine_ water_tank_full": "מכונת קפה מיכל מים מלא",
    "coffee_machine_milk_cleaning_not_performed": "מכונת קפה ניקוי חלב לא בוצע",
    "coffee_machine _milk_cleaning_performed": "מכונת קפה ניקוי חלב בוצע",
    "coffee_machine_head_cleaning_not_performed": "מכונת קפה ניקוי ראש לא ביצע",
    "coffee_machine_head_ cleaning_performed": "מכונת קפה ניקוי ראש בוצע",
    "coffee_machine_descaling_not_performed": "מכונת קפה הסרת אבנית לא בuצע",
    "coffee_machine_descaling_performed": "מכונת קפה הסרת אבנית ביצע",
    "floor_one": "קומה 1",
    "floor_two": "קומה 2",
    "floor_three": "קומה 3",
    "floor_four": "קומה 4",
    "floor_five": "קומה 5",
    "floor_six": "קומה 6",
    "floor_seven": "קומה 7",
    "floor_eight": "קומה 8",
    "floor_nine": "קומה 9",
    "floor_ten": "קומה 10",
    "floor_eleven": "קומה 11",
    "floor_twelve": "קומה 12",
    "floor_thirteen": "קומה 13",
    "floor_fourteen": "קומה 14",
    "floor_fifteen": "קומה 15",
    "floor_sixteen": "קומה 16",
    "floor_seventeen": "קומה 17",
    "floor_eighteen": "קומה 18",
    "floor_nineteen": "קומה 19",
    "floor_twenty": "קומה 20",
    "floor_twenty_one": "קומה 21",
    "floor_twenty_two": "קומה 22",
    "floor_twenty_three": "קומה 23",
    "floor_twenty_five": "קומה 25",
    "status_toilet_paper_cell_01_-_empty": "תא 01 חסר נייר טואלט",
    "status_toilet_paper_cell_02_-_empty": "תא 02 חסר נייר טואלט",
    "status_toilet_paper_cell_03_-_empty": "תא 03 חסר נייר טואלט",
    "status_toilet_paper_cell_04_-_empty": "תא 04 חסר נייר טואלט",
    "status_toilet_paper_cell_05_-_empty": "תא 05 חסר נייר טואלט",
    "status_toilet_paper_cell_06_-_empty": "תא 06 חסר נייר טואלט",
    "status_toilet_paper_cell_07_-_empty": "תא 07 חסר נייר טואלט",
    "status_toilet_paper_cell_08_-_empty": "תא 08 חסר נייר טואלט",
    "status_toilet_paper_cell_09_-_empty": "תא 09 חסר נייר טואלט",
    "status_toilet_paper_cell_10_-_empty": "תא 10 חסר נייר טואלט",
    "status_toilet_paper_cell_11_-_empty": "תא 11 חסר נייר טואלט",
    "status_toilet_paper_cell_12_-_empty": "תא 12 חסר נייר טואלט",
    "status_toilet_paper_cell_13_-_empty": "תא 13 חסר נייר טואלט",
    "status_toilet_paper_cell_14_-_empty": "תא 14 חסר נייר טואלט",
    "status_toilet_paper_cell_15_-_empty": "תא 15 חסר נייר טואלט",
    "status_toilet_paper_cell_16_-_empty": "תא 16 חסר נייר טואלט",
    "status_toilet_paper_cell_17_-_empty": "תא 17 חסר נייר טואלט",
    "status_toilet_paper_cell_18_-_empty": "תא 18 חסר נייר טואלט",
    "status_toilet_paper_cell_19_-_empty": "תא 19 חסר נייר טואלט",
    "status_toilet_paper_cell_20_-_empty": "תא 20 חסר נייר טואלט",
    "status_toilet_paper_cell_01_-_almost_empty": "תא 01 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_02_-_almost_empty": "תא 02 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_03_-_almost_empty": "תא 03 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_04_-_almost_empty": "תא 04 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_05_-_almost_empty": "תא 05 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_06_-_almost_empty": "תא 06 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_07_-_almost_empty": "תא 07 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_08_-_almost_empty": "תא 08 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_09_-_almost_empty": "תא 09 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_10_-_almost_empty": "תא 10 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_11_-_almost_empty": "תא 11 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_12_-_almost_empty": "תא 12 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_13_-_almost_empty": "תא 13 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_14_-_almost_empty": "תא 14 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_15_-_almost_empty": "תא 15 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_16_-_almost_empty": "תא 16 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_17_-_almost_empty": "תא 17 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_18_-_almost_empty": "תא 18 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_19_-_almost_empty": "תא 19 עומד להיגמר נייר טואלט",
    "status_toilet_paper_cell_20_-_almost_empty": "תא 20 עומד להיגמר נייר טואלט",
  },
  pr: {
    "cleaned_floor": "chão limpo",
    "cleaned_toilet": "banheiro limp",
    "cleaned_shower": "chuveiro limpo",
    "cleaned_sink": "pia limpa",
    "cleaned_mirror": "espelho limpo",
    "cleaned_stainless_steel": "peças de inox limpas",
    "bad_smell": "mal cheiro",
    "dirty_floor": "chão sujo",
    "dirty_lavatory_seat": "assento do vaso sanitário sujo",
    "dirty_shower": "chuveiro sujo",
    "dirty_sink": "pia suja",
    "dirty_mirror": "espelho sujo",
    "dirty_stainless_steel": "peças de inox sujas",
    "missing_paper_towel": "sem papel toalha",
    "missing_soap": "sem sabão",
    "missing_toilet_paper": "sem papel higiênico",
    "problem_shower": "chuveiro com problema",
    "problem_sink": "pia com problema",
    "problem_mirror": "espelho com problema",
    "problem_stainless_steel": "peças de inox com problema",
    "problem_blockage": "entupimento",
    "problem_door": "porta com problema",
    "problem_fluid_taps": "torneira sem água",
    "problem_lightning": "iluminação com problema",
    "problem_broken_toilet": "vaso sanitário quebrado",
    "fixed_shower": "chuveuiro consertado",
    "fixed_sink": "pia consertada",
    "fixed_mirror": "espelho consertado",
    "fixed_stainless_steel": "peças de inox consertadas",
    "fixed_blockage": "entupimento consertado",
    "fixed_door": "porta consertada",
    "fixed_fluid_taps": "torneira consertada",
    "fixed_lightning": "iluminação consertada",
    "fixed_broken_toilet": "vaso sanitário consertado",
    "very_good": "muito bom",
    "excellent": "excelente",
    "good": "bom",
    "like": "gostei",
    "needs_improvement": "necessita melhorias",
    "not_good": "não é bom",
    "supervise": "supervisionar",
    "maintenance": "manutenção",
    "cleaner": "funcionário",
    "tag_cleaner": "tag do funcionário",
    "zone_is_clean": "área esta limpa",
    "zone_is_dirty": "área esta suja",
    "cleaner_arrived": "funcionário chegou",
    "start_clean_zone": "inicie a zona limpa",
    "counter": "contador",
    "counter_men": "contador masculino",
    "counter_woman": "contador feminino",
    "counter_handycap": "contados pcd",
    "full_garbage_can": "lata de lixo cheia",
    "maintenance_fixed": "manutenção realizada",
    "maintenance_problem": "problema de manutenção",
    "problem_soap_dispenser": "dispenser de sabão com problema",
    "problem_paper_dispenser": "dispenser de papel com problema",
    "end_clean_zone": "zona limpa final",
    "status_toilet_paper": "status do papel higiénico",
    "status_hend_paper": "status papel toalha",
    "fixed": "fixo",
    "status_soap": "status sabão",
    "status_garbage_can": "status lata de lixo",
    "general_cleaning": "limpeza geral",
    "coffee_tea": "café e chã",
    "water_bar": "água e bebidas",
    "disposable_dishes": "pratos descartáveis",
    "head_coverage": "cobertura de cabeça",
    "something_else": "algo mais",
    "missing_clothing": "roupas desaparecida",
    "maintenance_link": "ligação de manutenção",
    "problem_maintenance": "manutenção",
    "supervise_link": "supervisionar",
    "cleaner_link": "funcionário chegou",
    "room_men+link": "homens em todo o quarto adicional",
    "room_men-link": "homens em todo o negativo adicional",
    "room_women+link": "mulheres em todo o quarto adicional",
    "room_women-link": "mulheres em todo o negativo quarto",
    "hebrew": "hebraico",
    "español": "español",
    "français": "français",
    "english": "inglês",
    "русский": "русский",
    "italiano": "italiano",
    "አማርኛ": "አማርኛ",
    "português": "português",
    "arabic": "árabe",
    "partial_information": "informações parciais",
    "professionalism": "profissionalismo",
    "the_attitude_of_the_representative": "a atitude do representante",
    "waiting_time_for_service": "tempo de espera para o serviço",
    "other_aspect_of_service": "outro aspecto do serviço",
    "language_of_the_service": "linguagem do serviço",
    "procedure_for_making_an_appointment": "procedimento para fazer uma nomeação",
    "physical_conditions": "condições físicas",
    "found_another": "encontrou outro",
    "found_similar": "encontrados similares",
    "did_not_find": "não encontrou",
    "found": "encontrado",
    "need_improved": "necessita melhorias",
    "liked": "apreciado",
    "pleasant": "prazeroso",
    "eilat_resident": "residente eilat",
    "once_a_week": "uma vez por semana",
    "once_a_month": "uma vez por mês",
    "twice_a_year": "duas vezes por ano",
    "once_a_year": "uma vez por ano",
    "friend": "amigo",
    "newspaper": "jornal",
    "google": "google",
    "zap": "zap",
    "vacuum_cleaner": "aspirador de pó",
    "gaming_consoles": "video game",
    "laptop": "laptop",
    "cellular": "celular",
    "shop": "fazer compras",
    "restaurant_complex_cleaning": "restaurante de limpeza complexo",
    "dining_room": "sala de jantar",
    "food_variety": "variedade de alimentos",
    "quality_of_the_food": "qualidade dos alimentos",
    "amount_of_the_food": "quantidade de alimentos",
    "quality_of_cooking": "qualidade do cozinheiro",
    "side_courses": "acompanhamentos",
    "meat": "eu no",
    "desserts": "sobremesas",
    "vegetarian": "vegetariano",
    "salad_bar": "buffet de salada",
    "main_courses": "prato principal",
    "service": "serviço",
    "dining_room_cleanliness": "limpeza sala de jantar",
    "plates_and_cutlery_cleanliness": "limpeza pratos e talheres",
    "product_inventory": "estoque de produtos",
    "furnishers": "mobiliario",
    "toilets": "banheiros",
    "problem_coffee_machine": "problema com a maquina de café",
    "problem_water_bar": "problema agua e bebidas",
    "dirty_stairs": "escadas sujas",
    "toiletries": "artigos de higiene pessoal",
    "fixed_soap_dispenser": "dispenser de sabão consertado",
    "fixed_paper_dispenser": "dispenser de papel consertado",
    "fixed_coffee_machine": "máquina de café consertada",
    "fixed_water_bar": "água e bebidas consertado",
    "problem_urinals": "problema mictórios",
    "blockage_toilet": "vaso sanitário entupido",
    "appliance_problem": "problema eletrodoméstico",
    "problem_broken_door": "problema porta quebrada",
    "problem_door_lock": "problema fechadura quebrada",
    "problem_broken_toilet_seat": "problema quebrado assento do vaso sanitário",
    "problem_broken_niagara": "problema niagara quebrado",
    " broken_hand_paper_dispenser_hand_dryer": "dispenser de papel toalha e secador com problema",
    "rug": "tapete",
    "tables": "mesas",
    "kitchen": "cozinha",
    "air_condition": "ar condicionado",
    "dirty_urinals": "mictório sujo",
    "hand_dryer": "secador de mãos",
    "non_sticky_carpet": "tapete antiderrapante",
    "problem_bad_smell": "problema mal cheiro",
    "problem_broken_toilet_handle": "problema alça do banheiro",
    "problem_handle_door": "problema maçaneta da porta",
    "call_cleaner": "chamar funcionário",
    "wall_painting": "pintura da parede",
    "missing_paper": "faltando papel",
    "clean_surface": "superficie limpa",
    "problem_right_elevator": "problema no elevador direito",
    "problem_left_elevator": "problema no elevador esquerdo",
    "missing_hand_sanitizer": "faltando desinfetante para as mãos",
    "problem_sink_blockage": "pia entupida",
    "electric_problem": "problema elétrico",
    "missing_coffe_tea": "faltando café / chá",
    "problem_air_condition": "problema ar condicionado",
    "dishwashing": "lavar pratos",
    "kitchen_cleanliness": "cozinha limpeza",
    "weekly_cleaning": "limpeza semanal",
    "general_arrangement": "arranjo geral",
    "meeting_room": "sala de reuniões",
    "refrigerator": "frigorífico",
    "kitchen_supplies": "material de cozinha",
    "toilet_services": "serviços sanitários",
    "carousel_door": "porta carrossel",
    "desk": "escrivaninha",
    "compressor": "compressor",
    "compressor_rails": "rails compressor",
    "compressor_drainage": "drenagem compressor",
    "dust": "pó",
    "door": "porta",
    "scotch": "escocês",
    "food": "comida",
    "room_conditions": "condições da sala",
    "kindness_sensitivity": "amabilidade e sensibilidade",
    "explain_process": "explicar o processo",
    "personal_attention": "atenção pessoal",
    "service_problem": "serviço problem",
    "welcome_you": "recebê-lo",
    "invoice_submitted": "factura apresentada",
    "thank_you_bought": "thank you adquiridos",
    "problem_seller": "problema vendedor",
    "lapel_pin": "alfinete de lapela",
    "proper_performance": "desempenho adequado",
    "name_tag": "crachá",
    "operational": "operacional",
    "app_revealed": "app revelou",
    "register_load": "registo de carga",
    "visibility_problem": "problema de visibilidade",
    "clean_storefront": "vitrine limpa",
    "clean_garbage": "lixo limpo",
    "clean_tables_chairs": "mesas e cadeiras limpas",
    "clean_shop_floor": "chão de fábrica limpa",
    "product_show": "vista do produto",
    "refrigerators_show": "ver de refrigeradores",
    "coffee_accessories": "acessórios de café e equipado",
    "cleanliness": "limpeza",
    "convenience_scheduling_appointments": "conveniência de agendamento de compromissos",
    "waiting_haircut": "esperando por um corte de cabelo",
    "courtesy_books": "cortesia dos livros",
    "books_professionalism": "o profissionalismo dos livros",
    "received_time": "me recebeu a tempo",
    "dnot_appointment": "eu não marcasse uma consulta",
    "an_appointment": "eu fiz uma consulta",
    "tasty": "saboroso",
    "courtesy_service": "cortesia do serviço",
    "overall_experience": "a experiência geral",
    "not_clean": "não limpo",
    "diningroom_not_clean": "a sala de jantar não é limpa",
    "unclean_dishes": "pratos impuros",
    "so-so": "mais ou menos",
    "not_tasty": "não é saboroso",
    "kind": "gentil",
    "not_kind": "nada gentil",
    "clean": "limpar",
    "hairdresser": "cabeleireiro",
    "wrong_price": "preço errado",
    "produc_has_expired": "produto expirou",
    "missing_item": "item ausente",
    "item_is_faulty_in_the_machine": "item é com defeito na máquina",
    "machine_cleaning": "limpeza da máquina.",
    "improper_temperature": "temperatura imprópria",
    "green": "verde",
    "orange": "laranja",
    "red": "vermelho",
    "yellow": "amarelo",
    "red not prob": "vermelho",
    "professional": "loading...",
    "unprofessional": "loading...",
    "delicacies": "delicadeiras.",
    "sandwiches": "sanduíches",
    "vegetables": "legumes",
    "fruits": "frutas",
    "salads": "saladas.",
    "milk": "leite",
    "room_link": "link da sala",
    "floor_link": "ligação chão",
    "services": "serviços",
    "check_list": "lista de controle",
    "lighting_problem_lobby": "lobby de problemas de iluminação",
    "lighting_problem_garbage_room": "quarto de lixo do problema de iluminação",
    "lighting_problem_stairwell": "problema de iluminação stairwell.",
    "problem_elevator_1": "elevador do problema 1.",
    "problem_elevator_2": "elevador problema 2.",
    "problem_elevator_3": "elevador problema 3.",
    "problem_elevator_4": "elevador problema 4.",
    "problem_elevator_5": "elevador problema 5.",
    "problem_elevator_6": "elevador problema 6.",
    "problem_elevator_7": "elevador problema 7.",
    "problem_elevator_8": "elevador do problema 8.",
    "problem_elevator_9": "elevador do problema 9.",
    "problem_elevator_10": "elevador de problemas 10.",
    "stuck_item": "item preso",
    "spoiling_product": "estragar produto",
    "machine_malfunction": "mau funcionamento da máquina",
    "unreadable_product": "produto ilegível",
    "i_am_satisfied": "eu estou satisfeito",
    "beverage": "bebida",
    "program_malfunction": "mau funcionamento do programa",
    "machine_1": "máquina 1.",
    "machine_2": "máquina 2.",
    "machine_3": "máquina 3.",
    "machine_4": "máquina 4.",
    "program malfunction 1": "Malfunction do programa 1.",
    "program malfunction 2": "Programa de mau funcionamento 2.",
    "program malfunction 3": "Programa de mau funcionamento 3.",
    "program malfunction 4": "Programa de mau funcionamento 4.",
    "fish": "peixe",
    "chicken": "frango",
    "full_dining_room": "sala de jantar completa",
    "missing_amount": "quantidade ausente",
    "missing_trays": "bandejas ausentes",
    "dirty_floor_g1": "piso g1 sujo",
    "dirty_floor_g2": "piso g2 sujo",
    "dirty_floor_g3": "piso g3 sujo",
    "dirty_floor_g4": "piso g4 sujo",
    "dirty_floor_g5": "piso g5 sujo",
    "dirty_floor_eo": "piso eo sujo",
    "dirty_floor_ceo": "piso ceo sujo",
    "dirty_floor_storage": "armazenamento chão sujo",
    "dirty_floor_inflorescence": "piso inflorescência sujo",
    "operating_instructions": "instruções de operação",
    "general": "em geral",
    "connection_problem": "problema de conexão",
    "lighting": "iluminação",
    "not _fixed": "não consertado",
    "cleaning_needed": "limpeza necessária",
    "missing_milk": "leite em falta",
    "no_sugar_coffee": "sem açúcar / café",
    "missing_tools": "ferramentas faltando",
    "coffee_water_machine": "café / máquina de água",
    "appliances": "eletrodomésticos",
    "products": "produtos",
    "broken_chair": "móveis quebrados",
    "broken_cabinet": "armário quebrado",
    "micro": "micro",
    "toaster": "torradeira",
    "clogged_sink_malfunction": "entupidos mau funcionamento pia",
    "coffee_cooling_malfunction": "café mau funcionamento de refrigeração",
    "no_coffee_beans": "não há grãos de café",
    "machine_failure": "falha da máquina",
    "that_dirty": "isso suja",
    "dirty_refrigerator": "geladeira suja",
    "dirty_tables": "mesas sujas",
    "plates": "pratos",
    "silver_spoon": "colher de prata",
    "cold_drinks_cups": "frio bebe copos",
    "hot_drink_cups": "copos de bebidas quentes",
    "a_dripping_faucet": "loading...",
    "disposable dishes": "pratos descartáveis",
    "problem_ coffee_water_machine": "café / máquina de água",
    "problem_micro": "micro",
    "problem_toaster": "torradeira",
    "problem_machine_failure": "falha da máquina",
    "problem refrigerator": "frigorífico",
    "no_sugar/coffee": "sem açúcar / café",
    "missing_dishes": "ferramentas faltando",
    "coffee/water_machine": "café / máquina de água",
    "coffee_machine_door_open": "porta da máquina de café aberta",
    "coffee_machine_door_close": "porta de café perto da máquina",
    "coffee_machine_bin_full": "escaninha de máquina de café",
    "coffee_machine_bin_empty": "máquina de café lixo vazio",
    "coffee_machine_coffee_full": "café café café cheio",
    "coffee_machine_coffee_empty": "café máquina de café vazio",
    "coffee_machine_water_tank_empty": "tanque de água de máquina de café vazio",
    "coffee_machine_ water_tank_full": "máquina de café watertank completo",
    "coffee_machine_milk_cleaning_not_performed": "limpeza de leite de máquina de café não realizada",
    "coffee_machine _milk_cleaning_performed": "limpeza de leite de máquina de café realizada",
    "coffee_machine_head_cleaning_not_performed": "limpeza da cabeça da máquina de café não realizada",
    "coffee_machine_head_ cleaning_performed": "limpeza da cabeça da máquina de café realizada",
    "coffee_machine_descaling_not_performed": "descalcificação da máquina de café não realizada",
    "coffee_machine_descaling_performed": "descalificação da máquina de café realizada",
    "floor_one": "andar 1",
    "floor_two": "andar 2",
    "floor_three": "andar 3",
    "floor_four": "andar 4",
    "floor_five": "andar 5",
    "floor_six": "andar 6",
    "floor_seven": "andar 7",
    "floor_eight": "andar 8",
    "floor_nine": "andar 9",
    "floor_ten": "andar 10",
    "floor_eleven": "andar 11",
    "floor_twelve": "andar 12",
    "floor_thirteen": "andar 13",
    "floor_fourteen": "andar 14",
    "floor_fifteen": "andar 15",
    "floor_sixteen": "andar 16",
    "floor_seventeen": "andar 17",
    "floor_eighteen": "andar 18",
    "floor_nineteen": "andar 19",
    "floor_twenty": "andar 20",
    "floor_twenty_one": "andar 21",
    "floor_twenty_two": "andar 22",
    "floor_twenty_three": "andar 23",
    "floor_twenty_four": "andar 24",
    "floor_twenty_five": "andar 25",
    "status_toilet_paper_cell_01_-_empty": "Status do papel higiênico da célula 01 - vazio",
    "status_toilet_paper_cell_02_-_empty": "Status do papel higiênico da célula 02 - vazio",
    "status_toilet_paper_cell_03_-_empty": "Status do papel higiênico da célula 03 - vazio",
    "status_toilet_paper_cell_04_-_empty": "Status do papel higiênico da célula 04 - vazio",
    "status_toilet_paper_cell_05_-_empty": "Status do papel higiênico da célula 05 - vazio",
    "status_toilet_paper_cell_06_-_empty": "Status do papel higiênico da célula 06 - vazio",
    "status_toilet_paper_cell_07_-_empty": "Status do papel higiênico da célula 07 - vazio",
    "status_toilet_paper_cell_08_-_empty": "Status do papel higiênico da célula 08 - vazio",
    "status_toilet_paper_cell_09_-_empty": "Status do papel higiênico da célula 09 - vazio",
    "status_toilet_paper_cell_10_-_empty": "Status do papel higiênico da célula 10 - vazio",
    "status_toilet_paper_cell_11_-_empty": "Status do papel higiênico da célula 11 - vazio",
    "status_toilet_paper_cell_12_-_empty": "Status do papel higiênico da célula 12 - vazio",
    "status_toilet_paper_cell_13_-_empty": "Status do papel higiênico da célula 13 - vazio",
    "status_toilet_paper_cell_14_-_empty": "Status do papel higiênico da célula 14 - vazio",
    "status_toilet_paper_cell_15_-_empty": "Status do papel higiênico da célula 15 - vazio",
    "status_toilet_paper_cell_16_-_empty": "Status do papel higiênico da célula 16 - vazio",
    "status_toilet_paper_cell_17_-_empty": "Status do papel higiênico da célula 17 - vazio",
    "status_toilet_paper_cell_18_-_empty": "Status do papel higiênico da célula 18 - vazio",
    "status_toilet_paper_cell_19_-_empty": "Status do papel higiênico da célula 19 - vazio",
    "status_toilet_paper_cell_20_-_empty": "Status do papel higiênico da célula 20 - vazio",
    "status_toilet_paper_cell_01_-_almost_empty": "Status do papel higiênico da célula 01 - quase vazio",
    "status_toilet_paper_cell_02_-_almost_empty": "Status do papel higiênico da célula 02 - quase vazio",
    "status_toilet_paper_cell_03_-_almost_empty": "Status do papel higiênico da célula 03 - quase vazio",
    "status_toilet_paper_cell_04_-_almost_empty": "Status do papel higiênico da célula 04 - quase vazio",
    "status_toilet_paper_cell_05_-_almost_empty": "Status do papel higiênico da célula 05 - quase vazio",
    "status_toilet_paper_cell_06_-_almost_empty": "Status do papel higiênico da célula 06 - quase vazio",
    "status_toilet_paper_cell_07_-_almost_empty": "Status do papel higiênico da célula 07 - quase vazio",
    "status_toilet_paper_cell_08_-_almost_empty": "Status do papel higiênico da célula 08 - quase vazio",
    "status_toilet_paper_cell_09_-_almost_empty": "Status do papel higiênico da célula 09 - quase vazio",
    "status_toilet_paper_cell_10_-_almost_empty": "Status do papel higiênico da célula 10 - quase vazio",
    "status_toilet_paper_cell_11_-_almost_empty": "Status do papel higiênico da célula 11 - quase vazio",
    "status_toilet_paper_cell_12_-_almost_empty": "Status do papel higiênico da célula 12 - quase vazio",
    "status_toilet_paper_cell_13_-_almost_empty": "Status do papel higiênico da célula 13 - quase vazio",
    "status_toilet_paper_cell_14_-_almost_empty": "Status do papel higiênico da célula 14 - quase vazio",
    "status_toilet_paper_cell_15_-_almost_empty": "Status do papel higiênico da célula 15 - quase vazio",
    "status_toilet_paper_cell_16_-_almost_empty": "Status do papel higiênico da célula 16 - quase vazio",
    "status_toilet_paper_cell_17_-_almost_empty": "Status do papel higiênico da célula 17 - quase vazio",
    "status_toilet_paper_cell_18_-_almost_empty": "Status do papel higiênico da célula 18 - quase vazio",
    "status_toilet_paper_cell_19_-_almost_empty": "Status do papel higiênico da célula 19 - quase vazio",
    "status_toilet_paper_cell_20_-_almost_empty": "Status do papel higiênico da célula 20 - quase vazio",
  },
  ru: {
    "cleaned_floor": "пол чистый",
    "cleaned_toilet": "туалет убран",
    "cleaned_shower": "душевые убраны",
    "cleaned_sink": "раковина убрана",
    "cleaned_mirror": "зеркало чистое",
    "cleaned_stainless_steel": "нероста чисная",
    "bad_smell": "плохой запах",
    "dirty_floor": "Грязный пол",
    "dirty_lavatory_seat": "грязное сиденье унитаза",
    "dirty_shower": "грязная душевая",
    "dirty_sink": "грязная раковина",
    "dirty_mirror": "грязное зеркало",
    "dirty_stainless_steel": "грязная нероста",
    "missing_paper_towel": "нет бумажного полотенца ",
    "missing_soap": "нет мыло",
    "missing_toilet_paper": "нет туалетной бумаги",
    "problem_shower": "проблема в душевой ",
    "problem_sink": "проблема с раковиной",
    "problem_mirror": "проблема с зеркалом",
    "problem_stainless_steel": "проблема с неростай",
    "problem_blockage": "есть засорение",
    "problem_door": "проблема с дверью",
    "problem_fluid_taps": "протекает кран",
    "problem_lightning": "проблема с освещением",
    "problem_broken_toilet": "сломанный унитаз",
    "fixed_shower": "душевые починены",
    "fixed_sink": "раковина починена",
    "fixed_mirror": "зеркало починено",
    "fixed_stainless_steel": "нироста исправлена",
    "fixed_blockage": "засор исправлен",
    "fixed_door": "дверь исправлена",
    "fixed_fluid_taps": "протечка исправлена",
    "fixed_lightning": "освещение исправлено",
    "fixed_broken_toilet": "унитаз исправлен",
    "very_good": "очень хорошо",
    "excellent": "отлично",
    "good": "хорошо",
    "like": "клас",
    "needs_improvement": "требуется улучшение",
    "not_good": "не хорошо",
    "supervise": "инспектор",
    "maintenance": "техобслуживание",
    "cleaner": "уборщик",
    "tag_cleaner": "тег очиститель",
    "zone_is_clean": "зона чистая",
    "zone_is_dirty": "зона грязная",
    "cleaner_arrived": "прибыл уборщик",
    "start_clean_zone": "Начать чистую зону",
    "counter": "счетчик",
    "counter_men": "количество мужчин",
    "counter_woman": "количество женщин",
    "counter_handycap": "количество инвалидов",
    "full_garbage_can": "полная мусорка",
    "maintenance_fixed": "техобслуживание окончено",
    "maintenance_problem": "проблема техобслуживание",
    "problem_soap_dispenser": "проблема с диспенсером мыла",
    "problem_paper_dispenser": "проблема с диспенсером бумаги",
    "end_clean_zone": "Конец чистой зоны",
    "status_toilet_paper": "сенсор туалетной бумаги",
    "status_hend_paper": "сенсор бумаги для рук",
    "fixed": "фиксированный",
    "status_soap": "сенсор мыла",
    "status_garbage_can": "сенсор мусорки",
    "general_cleaning": "генеральная уборка",
    "coffee_tea": "чай кофе",
    "water_bar": "кулер",
    "disposable_dishes": "одноразовая посуда",
    "head_coverage": "покрытие головы",
    "something_else": "что то другое",
    "missing_clothing": "недостаточно одежды",
    "maintenance_link": "ссылка обслуживание",
    "problem_maintenance": "обслуживание проблемы",
    "supervise_link": "контролировать связь",
    "cleaner_link": "уборщик ссылка",
    "room_men+link": "Мужчины через заряды номера",
    "room_men-link": "Мужчины по всему отрицательному номера",
    "room_women+link": "Женщины во зарядах номеров",
    "room_women-link": "Женщины по всему отрицательному номер",
    "hebrew": "иврит",
    "español": "испанский",
    "français": "французский",
    "english": "английский",
    "русский": "русский",
    "italiano": "итальянский",
    "አማርኛ": "амхарский",
    "português": "португальский",
    "arabic": "арабский",
    "partial_information": "частичная информация",
    "professionalism": "профессионализм",
    "the_attitude_of_the_representative": "отношение представителя",
    "waiting_time_for_service": "время ожидания обслуживания",
    "other_aspect_of_service": "Другой аспект службы",
    "language_of_the_service": "язык обслуживания",
    "procedure_for_making_an_appointment": "Порядок предварительной записи",
    "physical_conditions": "физические условия",
    "found_another": "нашел другое",
    "found_similar": "нашел похожие",
    "did_not_find": "не нашел продукт",
    "found": "нашел продукт",
    "need_improved": "можно улучшить",
    "liked": "понравилось",
    "pleasant": "приятноя",
    "eilat_resident": "я житель эйлата",
    "once_a_week": "раз в неделю",
    "once_a_month": "раз в месяц",
    "twice_a_year": "два три раза в гор",
    "once_a_year": "раз в год",
    "friend": "друг",
    "newspaper": "газета",
    "google": "гугл",
    "zap": "зап",
    "vacuum_cleaner": "пылесос",
    "gaming_consoles": "игровая приставка",
    "laptop": "ноутбук",
    "cellular": "сотовый телефон",
    "shop": "магазин",
    "restaurant_complex_cleaning": "ресторанный комплекс очистки",
    "dining_room": "столовая",
    "food_variety": "разнообразие блюд",
    "quality_of_the_food": "качество пищи",
    "amount_of_the_food": "количество еды",
    "quality_of_cooking": "качество приготовления",
    "side_courses": "добавки",
    "meat": "мясное",
    "desserts": "десерты",
    "vegetarian": "вегетарианский",
    "salad_bar": "салаты",
    "main_courses": "основное блюдо",
    "service": "обслуживание",
    "dining_room_cleanliness": "чистота столовой",
    "plates_and_cutlery_cleanliness": "чистота столовых предметов",
    "product_inventory": "запас продуктов",
    "furnishers": "мебель",
    "toilets": "туалет",
    "problem_coffee_machine": "проблема с кофемашиной",
    "problem_water_bar": "проблема с кулерам",
    "dirty_stairs": "грязная входная лестница",
    "toiletries": "туалетные принадлежности",
    "fixed_soap_dispenser": "мыльница исправлена",
    "fixed_paper_dispenser": "диспенсер для бумаги исправлен",
    "fixed_coffee_machine": "кофемашина исправлена",
    "fixed_water_bar": "кулер исправен",
    "problem_urinals": "сломанный писсуар",
    "blockage_toilet": "сломанный сливной бачок",
    "appliance_problem": "неисправный электроприбор",
    "problem_broken_door": "сломанная дверь",
    "problem_door_lock": "проблема с замком в двери",
    "problem_broken_toilet_seat": "сломанный унитаз",
    "problem_broken_niagara": "сломанный сливной бачок",
    " broken_hand_paper_dispenser_hand_dryer": "проблема с сушилкой для рук или диспенсером",
    "rug": "ковер",
    "tables": "столы",
    "kitchen": "кухня",
    "air_condition": "кондиционер",
    "dirty_urinals": "грязный писсуар",
    "hand_dryer": "сушилка для рук",
    "non_sticky_carpet": "нелипкий ковер",
    "problem_bad_smell": "проблема с запахом",
    "problem_broken_toilet_handle": "неисправная ручка туалета",
    "problem_handle_door": "неисправная ручка двери",
    "call_cleaner": "требуется уборка",
    "wall_painting": "покраска стен",
    "missing_paper": "Отсутствует бумага",
    "clean_surface": "уборка поверхностей",
    "problem_right_elevator": "неисправность в правом лифте",
    "problem_left_elevator": "неисправность в левом лифте",
    "missing_hand_sanitizer": "отсутствует дезинфицирующий спрей",
    "problem_sink_blockage": "засоренная раковина",
    "electric_problem": "проблема с электрическим питанием",
    "missing_coffe_tea": "не хватает кофе чая",
    "problem_air_condition": "проблема с кондиционером",
    "dishwashing": "мытье посуды",
    "kitchen_cleanliness": "чистота кухня",
    "weekly_cleaning": "еженедельная уборка",
    "general_arrangement": "общее расположение",
    "meeting_room": "комната для переговоров",
    "refrigerator": "холодильник",
    "kitchen_supplies": "кухонные принадлежности",
    "toilet_services": "туалет услуги",
    "carousel_door": "карусельная дверь",
    "desk": "стол письменный",
    "compressor": "компрессор",
    "compressor_rails": "компрессорные рельсы",
    "compressor_drainage": "дренаж компрессора",
    "dust": "пыль",
    "door": "дверь",
    "scotch": "скотч",
    "food": "Еда",
    "room_conditions": "комнатные условия",
    "kindness_sensitivity": "Доброта и чувствительность",
    "explain_process": "Объяснить процесс",
    "personal_attention": "Персональное внимание",
    "service_problem": "Обслуживание Проблема",
    "welcome_you": "Приветствует Вас",
    "invoice_submitted": "Счет-фактура, представленный",
    "thank_you_bought": "Спасибо купили",
    "problem_seller": "Проблема Продавец",
    "lapel_pin": "контактный нагрудные",
    "proper_performance": "надлежащее исполнение",
    "name_tag": "Именная метка",
    "operational": "эксплуатационный",
    "app_revealed": "App показал",
    "register_load": "загрузка регистра",
    "visibility_problem": "проблема Видимость",
    "clean_storefront": "Чистая витрина",
    "clean_garbage": "Чистый мусор",
    "clean_tables_chairs": "Чистые столы и стулья",
    "clean_shop_floor": "Чистый цех",
    "product_show": "Вид продукта",
    "refrigerators_show": "Вид холодильников",
    "coffee_accessories": "Кофейные аксессуары и оборудованы",
    "cleanliness": "чистота",
    "convenience_scheduling_appointments": "Удобство планирования назначений",
    "waiting_haircut": "В ожидании стрижки стрижки",
    "courtesy_books": "вежливость книг",
    "books_professionalism": "Профессионализм книг",
    "received_time": "получил меня вовремя",
    "dnot_appointment": "Я не записал встречу",
    "an_appointment": "Я назначил встречу",
    "tasty": "вкусные",
    "courtesy_service": "вежливость службы",
    "overall_experience": "Общий опыт",
    "not_clean": "не чисто",
    "diningroom_not_clean": "Столовая не чистая",
    "unclean_dishes": "нечистые блюда",
    "so-so": "так себе",
    "not_tasty": "не вкусно",
    "kind": "своего рода",
    "not_kind": "не добрая",
    "clean": "чистый",
    "hairdresser": "парикмахерская",
    "wrong_price": "Неправильная цена",
    "produc_has_expired": "Продукт истек",
    "missing_item": "Отсутствует пункт",
    "item_is_faulty_in_the_machine": "Товар неисправен в машине",
    "machine_cleaning": "Машинная очистка",
    "improper_temperature": "Неправильная температура",
    "green": "зеленый",
    "orange": "оранжевый",
    "red": "красный",
    "yellow": "желтый",
    "red not prob": "красный",
    "professional": "профессиональный",
    "unprofessional": "непрофессиональный",
    "delicacies": "Деликатесы",
    "sandwiches": "Бутерброды",
    "vegetables": "овощи",
    "fruits": "фрукты",
    "salads": "Салаты",
    "milk": "Молоко",
    "room_link": "номер ссылка",
    "floor_link": "пол ссылка",
    "services": "Сервисы",
    "check_list": "Проверка списка",
    "lighting_problem_lobby": "Освещение Проблема Лобби",
    "lighting_problem_garbage_room": "Освещение проблемная мусорная комната",
    "lighting_problem_stairwell": "Лестница освещения лестницы",
    "problem_elevator_1": "Проблема лифта 1.",
    "problem_elevator_2": "Проблема лифта 2.",
    "problem_elevator_3": "Проблема лифта 3",
    "problem_elevator_4": "Проблема лифта 4",
    "problem_elevator_5": "Проблема лифта 5.",
    "problem_elevator_6": "Проблема лифта 6.",
    "problem_elevator_7": "Проблема лифта 7.",
    "problem_elevator_8": "Проблема лифта 8.",
    "problem_elevator_9": "Проблема лифта 9.",
    "problem_elevator_10": "Проблема лифта 10.",
    "stuck_item": "Застрял предмет",
    "spoiling_product": "Прятный продукт",
    "machine_malfunction": "Неисправность машины",
    "unreadable_product": "Непричитанный продукт",
    "i_am_satisfied": "я удовлетворен",
    "beverage": "Напиток",
    "program_malfunction": "Неисправность программы",
    "machine_1": "Машина 1.",
    "machine_2": "Машина 2.",
    "machine_3": "Машина 3.",
    "machine_4": "Машина 4.",
    "program malfunction 1": "Неисправность программы 1.",
    "program malfunction 2": "Неисправность программы 2.",
    "program malfunction 3": "Неисправность программы 3.",
    "program malfunction 4": "Неисправность программы 4.",
    "fish": "Рыбы",
    "chicken": "курица",
    "full_dining_room": "Полная столовая",
    "missing_amount": "Отсутствующая сумма",
    "missing_trays": "Отсутствующие лотки",
    "dirty_floor_g1": "грязный пол g1",
    "dirty_floor_g2": "грязный пол g2",
    "dirty_floor_g3": "грязный пол g3",
    "dirty_floor_g4": "грязный пол g4",
    "dirty_floor_g5": "грязный пол g5",
    "dirty_floor_eo": "грязный пол ео",
    "dirty_floor_ceo": "уборка офиса генерального директора",
    "dirty_floor_storage": "уборка на этаже со складом",
    "dirty_floor_inflorescence": "грязный пол соцветие",
    "operating_instructions": "Инструкция по эксплуатации",
    "general": "Генеральная",
    "connection_problem": "Проблема соединения",
    "lighting": "освещение",
    "not _fixed": "не фиксируется",
    "cleaning_needed": "Очистка необходима",
    "missing_milk": "Недостающее молоко",
    "no_sugar_coffee": "Нет сахар / кофе",
    "missing_tools": "Отсутствующие инструменты",
    "coffee_water_machine": "Кофе машина воды /",
    "appliances": "бытовая техника",
    "products": "продукты",
    "broken_chair": "Сломанная мебель",
    "broken_cabinet": "Сломанный шкаф",
    "micro": "Микро",
    "toaster": "тостер",
    "clogged_sink_malfunction": "Засорение неисправность раковины",
    "coffee_cooling_malfunction": "Кофе охлаждение неисправность",
    "no_coffee_beans": "Нет кофе в зернах",
    "machine_failure": "отказ машины",
    "that_dirty": "Этот грязный",
    "dirty_refrigerator": "Грязный холодильник",
    "dirty_tables": "Грязные столы",
    "plates": "Тарелки",
    "silver_spoon": "Серебряная ложка",
    "cold_drinks_cups": "Холодные напитки чашки",
    "hot_drink_cups": "Горячие чашки напитка",
    "a_dripping_faucet": "капающий смеситель",
    "disposable dishes": "одноразовая посуда",
    "problem_ coffee_water_machine": "Кофе машина воды /",
    "problem_micro": "Микро",
    "problem_toaster": "тостер",
    "problem_machine_failure": "отказ машины",
    "problem refrigerator": "холодильник",
    "no_sugar/coffee": "Нет сахар / кофе",
    "missing_dishes": "Отсутствующие инструменты",
    "coffee/water_machine": "Кофе машина воды /",
    "coffee_machine_door_open": "Кофемашина открыта дверь",
    "coffee_machine_door_close": "Кофемашина двери закрывается",
    "coffee_machine_bin_full": "Кофемашина Bin полный",
    "coffee_machine_bin_empty": "Кофемашина Bin пустой",
    "coffee_machine_coffee_full": "кофемашина кофе полный",
    "coffee_machine_coffee_empty": "кофемашина кофе пусто",
    "coffee_machine_water_tank_empty": "Кофемашина для воды Бак пустой",
    "coffee_machine_ water_tank_full": "Кофемашина Watertank полностью",
    "coffee_machine_milk_cleaning_not_performed": "Кофемашина Молочная уборка не выполнена",
    "coffee_machine _milk_cleaning_performed": "Кофемашина Молочная уборка выполнена",
    "coffee_machine_head_cleaning_not_performed": "Чистка головки кофемашины не выполнена",
    "coffee_machine_head_ cleaning_performed": "Уборка головки кофемашины выполнены",
    "coffee_machine_descaling_not_performed": "Кофемашина накипи не выполняется",
    "coffee_machine_descaling_performed": "Кофемашина для удаления накипи",
    "floor_one": "Пол 1",
    "floor_two": "Пол 2",
    "floor_three": "Пол 3",
    "floor_four": "Пол 4",
    "floor_five": "Пол 5",
    "floor_six": "Пол 6",
    "floor_seven": "Пол 7",
    "floor_eight": "Пол 8",
    "floor_nine": "Пол 9",
    "floor_ten": "Пол 10",
    "floor_eleven": "Пол 11",
    "floor_twelve": "Пол 12",
    "floor_thirteen": "Пол 13",
    "floor_fourteen": "Пол 14",
    "floor_fifteen": "Пол 15",
    "floor_sixteen": "Пол 16",
    "floor_seventeen": "Пол 17",
    "floor_eighteen": "Пол 18",
    "floor_nineteen": "Пол 19",
    "floor_twenty": "Пол 20",
    "floor_twenty_one": "Пол 21",
    "floor_twenty_two": "Пол 22",
    "floor_twenty_three": "Пол 23",
    "floor_twenty_four": "Пол 24",
    "floor_twenty_five": "Пол 25",
    "status_toilet_paper_cell_01_-_empty": "Статус туалетной бумаги ячейка 01 - пусто",
    "status_toilet_paper_cell_02_-_empty": "Статус туалетной бумаги ячейка 02 - пусто",
    "status_toilet_paper_cell_03_-_empty": "Статус туалетной бумаги ячейка 03 - пусто",
    "status_toilet_paper_cell_04_-_empty": "Статус туалетной бумаги ячейка 04 - пусто",
    "status_toilet_paper_cell_05_-_empty": "Статус туалетной бумаги ячейка 05 - пусто",
    "status_toilet_paper_cell_06_-_empty": "Статус туалетной бумаги ячейка 06 - пусто",
    "status_toilet_paper_cell_07_-_empty": "Статус туалетной бумаги ячейка 07 - пусто",
    "status_toilet_paper_cell_08_-_empty": "Статус туалетной бумаги ячейка 08 - пусто",
    "status_toilet_paper_cell_09_-_empty": "Статус туалетной бумаги ячейка 09 - пусто",
    "status_toilet_paper_cell_10_-_empty": "Статус туалетной бумаги ячейка 10 - пусто",
    "status_toilet_paper_cell_11_-_empty": "Статус туалетной бумаги ячейка 11 - пусто",
    "status_toilet_paper_cell_12_-_empty": "Статус туалетной бумаги ячейка 12 - пусто",
    "status_toilet_paper_cell_13_-_empty": "Статус туалетной бумаги ячейка 13 - пусто",
    "status_toilet_paper_cell_14_-_empty": "Статус туалетной бумаги ячейка 14 - пусто",
    "status_toilet_paper_cell_15_-_empty": "Статус туалетной бумаги ячейка 15 - пусто",
    "status_toilet_paper_cell_16_-_empty": "Статус туалетной бумаги ячейка 16 - пусто",
    "status_toilet_paper_cell_17_-_empty": "Статус туалетной бумаги ячейка 17 - пусто",
    "status_toilet_paper_cell_18_-_empty": "Статус туалетной бумаги ячейка 18 - пусто",
    "status_toilet_paper_cell_19_-_empty": "Статус туалетной бумаги ячейка 19 - пусто",
    "status_toilet_paper_cell_20_-_empty": "Статус туалетной бумаги ячейка 20 - пусто",
    "status_toilet_paper_cell_01_-_almost_empty": "Статус туалетной бумаги ячейка 01 - почти пусто",
    "status_toilet_paper_cell_02_-_almost_empty": "Статус туалетной бумаги ячейка 02 - почти пусто",
    "status_toilet_paper_cell_03_-_almost_empty": "Статус туалетной бумаги ячейка 03 - почти пусто",
    "status_toilet_paper_cell_04_-_almost_empty": "Статус туалетной бумаги ячейка 04 - почти пусто",
    "status_toilet_paper_cell_05_-_almost_empty": "Статус туалетной бумаги ячейка 05 - почти пусто",
    "status_toilet_paper_cell_06_-_almost_empty": "Статус туалетной бумаги ячейка 06 - почти пусто",
    "status_toilet_paper_cell_07_-_almost_empty": "Статус туалетной бумаги ячейка 07 - почти пусто",
    "status_toilet_paper_cell_08_-_almost_empty": "Статус туалетной бумаги ячейка 08 - почти пусто",
    "status_toilet_paper_cell_09_-_almost_empty": "Статус туалетной бумаги ячейка 09 - почти пусто",
    "status_toilet_paper_cell_10_-_almost_empty": "Статус туалетной бумаги ячейка 10 - почти пусто",
    "status_toilet_paper_cell_11_-_almost_empty": "Статус туалетной бумаги ячейка 11 - почти пусто",
    "status_toilet_paper_cell_12_-_almost_empty": "Статус туалетной бумаги ячейка 12 - почти пусто",
    "status_toilet_paper_cell_13_-_almost_empty": "Статус туалетной бумаги ячейка 13 - почти пусто",
    "status_toilet_paper_cell_14_-_almost_empty": "Статус туалетной бумаги ячейка 14 - почти пусто",
    "status_toilet_paper_cell_15_-_almost_empty": "Статус туалетной бумаги ячейка 15 - почти пусто",
    "status_toilet_paper_cell_16_-_almost_empty": "Статус туалетной бумаги ячейка 16 - почти пусто",
    "status_toilet_paper_cell_17_-_almost_empty": "Статус туалетной бумаги ячейка 17 - почти пусто",
    "status_toilet_paper_cell_18_-_almost_empty": "Статус туалетной бумаги ячейка 18 - почти пусто",
    "status_toilet_paper_cell_19_-_almost_empty": "Статус туалетной бумаги ячейка 19 - почти пусто",
    "status_toilet_paper_cell_20_-_almost_empty": "Статус туалетной бумаги ячейка 20 - почти пусто",
  },
  es: {
    "cleaned_floor": "piso limpio",
    "cleaned_toilet": "baño limpio",
    "cleaned_shower": "ducha limpia",
    "cleaned_sink": "fregadero limpio",
    "cleaned_mirror": "espejo limpio",
    "cleaned_stainless_steel": "acero inoxidable limpiado",
    "bad_smell": "mal olor",
    "dirty_floor": "piso sucio",
    "dirty_lavatory_seat": "asiento de inodoro sucio",
    "dirty_shower": "ducha sucia",
    "dirty_sink": "fregadero sucio",
    "dirty_mirror": "espejo sucio",
    "dirty_stainless_steel": "acero inoxidable sucio",
    "missing_paper_towel": "falta toalla de papel",
    "missing_soap": "falta jabon",
    "missing_toilet_paper": "falta papel higiénico",
    "problem_shower": "problemas con la ducha",
    "problem_sink": "problemas en el fregadero",
    "problem_mirror": "problemas con el espejo",
    "problem_stainless_steel": "problema con el acero inoxidable",
    "problem_blockage": "problema de bloqueo",
    "problem_door": "problemas con la puerta",
    "problem_fluid_taps": "problemas con el gripo",
    "problem_lightning": "problema de iluminación",
    "problem_broken_toilet": "inodoro roto",
    "fixed_shower": "ducha arreglada",
    "fixed_sink": "fregadero arreglado",
    "fixed_mirror": "espejo arreglado",
    "fixed_stainless_steel": "acero inoxidable arreglado",
    "fixed_blockage": "bloqueo eliminado",
    "fixed_door": "puerta arreglada",
    "fixed_fluid_taps": "grifo arreglado",
    "fixed_lightning": "iluminación arreglada",
    "fixed_broken_toilet": "inodoro arreglado",
    "very_good": "muy bueno",
    "excellent": "excelente",
    "good": "bueno",
    "like": "me gusta",
    "needs_improvement": "debe mejorar",
    "not_good": "nada bueno",
    "supervise": "supervisar",
    "maintenance": "mantenimiento",
    "cleaner": "persona de limpieza",
    "tag_cleaner": "credencial de limpiador",
    "zone_is_clean": "la zona está limpia",
    "zone_is_dirty": "la zona está sucia",
    "cleaner_arrived": "la persona de limpieza llegó",
    "start_clean_zone": "iniciar zona limpia",
    "counter": "contador",
    "counter_men": "contador hombres",
    "counter_woman": "contador mujeres",
    "counter_handycap": "contador discapacitados",
    "full_garbage_can": "cesto de basura lleno",
    "maintenance_fixed": "mantenimiento arreglado",
    "maintenance_problem": "problemas de mantenimiento",
    "problem_soap_dispenser": "problemas con el dispensador de jabón",
    "problem_paper_dispenser": "problemas con el dispensador de papel",
    "end_clean_zone": "zona limpia final",
    "status_toilet_paper": "estatus de papel de baño",
    "status_hend_paper": "estatus de papel de mano",
    "fixed": "reparado",
    "status_soap": "estatos de jabón",
    "status_garbage_can": "estatus del cesto de basura",
    "general_cleaning": "limpieza general",
    "coffee_tea": "café té",
    "water_bar": "barra de bebidas",
    "disposable_dishes": "platos descartables",
    "head_coverage": "cobertura de cabeza",
    "something_else": "algo más",
    "missing_clothing": "ropa extraviada/ falta ropa",
    "maintenance_link": "link/transición de mantenimiento",
    "problem_maintenance": "problemas de mantenimiento",
    "supervise_link": "link de supervisor",
    "cleaner_link": "link de la persona de limpieza",
    "room_men+link": "link + cuarto de hombres",
    "room_men-link": "link - cuarto de hombres",
    "room_women+link": "link + cuarto de mujeres",
    "room_women-link": "link - cuarto de mujeres",
    "hebrew": "hebreo",
    "español": "español",
    "français": "francés",
    "english": "inglés",
    "русский": "ruso",
    "italiano": "italiano",
    "አማርኛ": "amárico",
    "português": "portugués",
    "arabic": "árabe",
    "partial_information": "información parcial",
    "professionalism": "profesionalismo",
    "the_attitude_of_the_representative": "la actitud del representante",
    "waiting_time_for_service": "tiempo de espera del servicio",
    "other_aspect_of_service": "otros aspectos del servicio",
    "language_of_the_service": "lenguaje del servicio",
    "procedure_for_making_an_appointment": "procedimiento para agendar una cita",
    "physical_conditions": "condiciones físicas",
    "found_another": "encontrar otro",
    "found_similar": "encontrar similar",
    "did_not_find": "no se encuentra",
    "found": "se encontró",
    "need_improved": "necesita mejorar",
    "liked": "le ha gustado",
    "pleasant": "agradable",
    "eilat_resident": "residente de eilat",
    "once_a_week": "una a la semana",
    "once_a_month": "una al mes",
    "twice_a_year": "dos al año",
    "once_a_year": "una al año",
    "friend": "amigo",
    "newspaper": "diario",
    "google": "google",
    "zap": "zap",
    "vacuum_cleaner": "aspiradora",
    "gaming_consoles": "videojuegos",
    "laptop": "laptop",
    "cellular": "celular",
    "shop": "shop",
    "restaurant_complex_cleaning": "limpieza de restaurante",
    "dining_room": "comedor",
    "food_variety": "variedad de comidas",
    "quality_of_the_food": "calidad de la comida",
    "amount_of_the_food": "cantidad de la comida",
    "quality_of_cooking": "calidad de la cocina",
    "side_courses": "acompañamientos",
    "meat": "carne",
    "desserts": "postre",
    "vegetarian": "vegetariana",
    "salad_bar": "barra de ensaladas",
    "main_courses": "plato principal",
    "service": "servicio",
    "dining_room_cleanliness": "limpieza del comedor",
    "plates_and_cutlery_cleanliness": "limpieza de platos y cubiertos",
    "product_inventory": "stock de productos",
    "furnishers": "muebles",
    "toilets": "baños",
    "problem_coffee_machine": "problemas con la máquina de café",
    "problem_water_bar": "problema con la barra de bebidas",
    "dirty_stairs": "escaleras sucias",
    "toiletries": "artículos de aseo",
    "fixed_soap_dispenser": "dispensador de jabón arreglado",
    "fixed_paper_dispenser": "dispensador de papel arreglado",
    "fixed_coffee_machine": "máquina de café arreglada",
    "fixed_water_bar": "barra de bebidas arreglada",
    "problem_urinals": "problemas en migitorios",
    "blockage_toilet": "inodoro tapado",
    "appliance_problem": "problemas en electrodomésticos",
    "problem_broken_door": "puerta rota",
    "problem_door_lock": "puerta trabada",
    "problem_broken_toilet_seat": "asiento de inodoro roto",
    "problem_broken_niagara": "problema inodoro roto/descompuesto",
    " broken_hand_paper_dispenser_hand_dryer": "secador de manos dispensador de papel roto",
    "rug": "alfombra",
    "tables": "mesas",
    "kitchen": "cocina",
    "air_condition": "aire acondicionado",
    "dirty_urinals": "migitorios sucios",
    "hand_dryer": "secadora de manos",
    "non_sticky_carpet": "alfombra antiadherente",
    "problem_bad_smell": "mal olor",
    "problem_broken_toilet_handle": "manija de inodoro rota",
    "problem_handle_door": "picaporte roto",
    "call_cleaner": "llamar persona de limpieza",
    "wall_painting": "pared pintada",
    "missing_paper": "missing papel",
    "clean_surface": "superficie limpia",
    "problem_right_elevator": "problemas en ascensor derecho",
    "problem_left_elevator": "problemas en ascensor izquierdo",
    "missing_hand_sanitizer": "falta desinfectante de manos",
    "problem_sink_blockage": "fregadero tapado",
    "electric_problem": "problema eléctrico",
    "missing_coffe_tea": "falta café o té",
    "problem_air_condition": "falla el aire acondicionado",
    "dishwashing": "lavar platos",
    "kitchen_cleanliness": "cocina limpieza",
    "weekly_cleaning": "limpieza semanal",
    "general_arrangement": "acuerdo general",
    "meeting_room": "sala de reuniones",
    "refrigerator": "refrigerador",
    "kitchen_supplies": "utensilios de cocina",
    "toilet_services": "servicios higiénicos",
    "carousel_door": "puerta de carrusel",
    "desk": "escritorio",
    "compressor": "compresor",
    "compressor_rails": "carriles compresor",
    "compressor_drainage": "el drenaje del compresor",
    "dust": "polvo",
    "door": "puerta",
    "scotch": "escocés",
    "food": "comida",
    "room_conditions": "condiciones de la sala",
    "kindness_sensitivity": "la bondad y la sensibilidad",
    "explain_process": "explicar el proceso",
    "personal_attention": "atención personal",
    "service_problem": "problema servicio",
    "welcome_you": "bienvenido",
    "invoice_submitted": "factura presentada",
    "thank_you_bought": "gracias comprado",
    "problem_seller": "problema del vendedor",
    "lapel_pin": "perno de la solapa",
    "proper_performance": "rendimiento adecuado",
    "name_tag": "etiqueta con su nombre",
    "operational": "operacional",
    "app_revealed": "aplicación reveló",
    "register_load": "registro de carga",
    "visibility_problem": "problema de la visibilidad",
    "clean_storefront": "tienda limpia",
    "clean_garbage": "basura limpia",
    "clean_tables_chairs": "limpiar las mesas y sillas",
    "clean_shop_floor": "planta de producción limpia",
    "product_show": "vista del producto",
    "refrigerators_show": "vista de refrigeradores",
    "coffee_accessories": "accesorios café y equipado",
    "cleanliness": "limpieza",
    "convenience_scheduling_appointments": "conveniencia de programación de citas",
    "waiting_haircut": "esperando un corte de pelo",
    "courtesy_books": "cortesía de los libros",
    "books_professionalism": "la profesionalidad de los libros.",
    "received_time": "me recibió a tiempo",
    "dnot_appointment": "no hice una cita",
    "an_appointment": "yo hice una cita",
    "tasty": "sabroso",
    "courtesy_service": "cortesía del servicio",
    "overall_experience": "la experiencia general",
    "not_clean": "no está limpio",
    "diningroom_not_clean": "el comedor no está limpio.",
    "unclean_dishes": "platos sucios",
    "so-so": "regular",
    "not_tasty": "no es sabroso",
    "kind": "amable",
    "not_kind": "no grato",
    "clean": "limpio",
    "hairdresser": "peluquero",
    "wrong_price": "precio erróneo",
    "produc_has_expired": "el producto ha caducado",
    "missing_item": "objeto perdido",
    "item_is_faulty_in_the_machine": "el artículo está defectuoso en la máquina.",
    "machine_cleaning": "limpieza de la máquina",
    "improper_temperature": "temperatura incorrecta",
    "green": "verde",
    "orange": "naranja",
    "red": "rojo",
    "yellow": "amarillo",
    "red not prob": "rojo",
    "professional": "loading...",
    "unprofessional": "loading...",
    "delicacies": "delicias",
    "sandwiches": "sándwiches",
    "vegetables": "verduras",
    "fruits": "frutas",
    "salads": "ensaladas",
    "milk": "leche",
    "room_link": "vínculo de la sala",
    "floor_link": "enlace baja",
    "services": "servicios",
    "check_list": "lista de verificación",
    "lighting_problem_lobby": "problema de lugar lobby",
    "lighting_problem_garbage_room": "problema de iluminación sala de basura",
    "lighting_problem_stairwell": "problema de iluminación escalera",
    "problem_elevator_1": "problema elevador 1",
    "problem_elevator_2": "problema elevador 2",
    "problem_elevator_3": "problema elevador 3",
    "problem_elevator_4": "problema elevador 4",
    "problem_elevator_5": "problema elevador 5",
    "problem_elevator_6": "problema elevador 6",
    "problem_elevator_7": "problema elevador 7",
    "problem_elevator_8": "problema elevador 8",
    "problem_elevator_9": "problema elevador 9",
    "problem_elevator_10": "problema elevador 10",
    "stuck_item": "artículo atascado",
    "spoiling_product": "producto de arruinado",
    "machine_malfunction": "mal funcionamiento de la máquina",
    "unreadable_product": "producto ilegible",
    "i_am_satisfied": "estoy satisfecho",
    "beverage": "bebida",
    "program_malfunction": "programa mal funcionamiento",
    "machine_1": "máquina 1",
    "machine_2": "máquina 2",
    "machine_3": "máquina 3",
    "machine_4": "máquina 4",
    "program malfunction 1": "Programa mal funcionamiento 1",
    "program malfunction 2": "Programa mal funcionamiento 2",
    "program malfunction 3": "Programa mal funcionamiento 3",
    "program malfunction 4": "Programa mal funcionamiento 4",
    "fish": "pescado",
    "chicken": "pollo",
    "full_dining_room": "comedor completo",
    "missing_amount": "cantidad faltante",
    "missing_trays": "bandejas faltantes",
    "dirty_floor_g1": "piso sucio g1",
    "dirty_floor_g2": "piso sucio g2",
    "dirty_floor_g3": "piso sucio g3",
    "dirty_floor_g4": "piso sucio g4",
    "dirty_floor_g5": "piso sucio g5",
    "dirty_floor_eo": "piso sucio eo",
    "dirty_floor_ceo": "piso sucio ceo",
    "dirty_floor_storage": "piso sucio del almacenamiento",
    "dirty_floor_inflorescence": "inflorescencia del piso sucio",
    "operating_instructions": "instrucciones de operación",
    "general": "general",
    "connection_problem": "problema de conexión",
    "lighting": "encendiendo",
    "not _fixed": "no arreglado",
    "cleaning_needed": "limpieza necesaria",
    "missing_milk": "la leche que falta",
    "no_sugar_coffee": "sin azúcar / café",
    "missing_tools": "herramientas que faltan",
    "coffee_water_machine": "máquina de café / agua",
    "appliances": "accesorios",
    "products": "productos",
    "broken_chair": "muebles rotos",
    "broken_cabinet": "mueble roto",
    "micro": "micro",
    "toaster": "tostadora",
    "clogged_sink_malfunction": "obstruido mal funcionamiento del fregadero",
    "coffee_cooling_malfunction": "coffee mal funcionamiento de refrigeración",
    "no_coffee_beans": "no hay granos de café",
    "machine_failure": "fallo de la máquina",
    "that_dirty": "esa sucia",
    "dirty_refrigerator": "nevera sucia",
    "dirty_tables": "mesas sucias",
    "plates": "platos",
    "silver_spoon": "cuchara de plata",
    "cold_drinks_cups": "frío bebe copas",
    "hot_drink_cups": "tazas de bebidas calientes",
    "a_dripping_faucet": "loading...",
    "disposable dishes": "platos descartables",
    "problem_ coffee_water_machine": "máquina de café / agua",
    "problem_micro": "micro",
    "problem_toaster": "tostadora",
    "problem_machine_failure": "fallo de la máquina",
    "problem refrigerator": "refrigerador",
    "no_sugar/coffee": "sin azúcar / café",
    "missing_dishes": "herramientas que faltan",
    "coffee/water_machine": "máquina de café / agua",
    "coffee_machine_door_open": "puerta de la máquina de café abierta",
    "coffee_machine_door_close": "coffee machine puerta cerrar",
    "coffee_machine_bin_full": "máquina de café bin completa",
    "coffee_machine_bin_empty": "papelera de café vacío",
    "coffee_machine_coffee_full": "cafetera café llena",
    "coffee_machine_coffee_empty": "cafetera cafetera vacía",
    "coffee_machine_water_tank_empty": "tanque de agua de la máquina de café vacía",
    "coffee_machine_ water_tank_full": "máquina de café acuática llena",
    "coffee_machine_milk_cleaning_not_performed": "máquina de café limpieza de leche no realizado",
    "coffee_machine _milk_cleaning_performed": "máquina de café limpieza de leche realizada",
    "coffee_machine_head_cleaning_not_performed": "cafetera cabeza limpieza no realizada",
    "coffee_machine_head_ cleaning_performed": "coffee machine head limpieza realizada",
    "coffee_machine_descaling_not_performed": "máquina de café descalificante no realizada",
    "coffee_machine_descaling_performed": "máquina de café descalificada realizada.",
    "floor_one": "piso 1",
    "floor_two": "piso 2",
    "floor_three": "piso 3",
    "floor_four": "piso 4",
    "floor_five": "piso 5",
    "floor_six": "piso 6",
    "floor_seven": "piso 7",
    "floor_eight": "piso 8",
    "floor_nine": "piso 9",
    "floor_ten": "piso 10",
    "floor_eleven": "piso 11",
    "floor_twelve": "piso 12",
    "floor_thirteen": "piso 13",
    "floor_fourteen": "piso 14",
    "floor_fifteen": "piso 15",
    "floor_sixteen": "piso 16",
    "floor_seventeen": "piso 17",
    "floor_eighteen": "piso 18",
    "floor_nineteen": "piso 19",
    "floor_twenty": "piso 20",
    "floor_twenty_one": "piso 21",
    "floor_twenty_two": "piso 22",
    "floor_twenty_three": "piso 23",
    "floor_twenty_four": "piso 24",
    "floor_twenty_five": "piso 25",
    "status_toilet_paper_cell_01_-_empty": "Estado de la celda de papel higiénico 01 - vacío",
    "status_toilet_paper_cell_02_-_empty": "Estado de la celda de papel higiénico 02 - vacío",
    "status_toilet_paper_cell_03_-_empty": "Estado de la celda de papel higiénico 03 - vacío",
    "status_toilet_paper_cell_04_-_empty": "Estado de la celda de papel higiénico 04 - vacío",
    "status_toilet_paper_cell_05_-_empty": "Estado de la celda de papel higiénico 05 - vacío",
    "status_toilet_paper_cell_06_-_empty": "Estado de la celda de papel higiénico 06 - vacío",
    "status_toilet_paper_cell_07_-_empty": "Estado de la celda de papel higiénico 07 - vacío",
    "status_toilet_paper_cell_08_-_empty": "Estado de la celda de papel higiénico 08 - vacío",
    "status_toilet_paper_cell_09_-_empty": "Estado de la celda de papel higiénico 09 - vacío",
    "status_toilet_paper_cell_10_-_empty": "Estado de la celda de papel higiénico 10 - vacío",
    "status_toilet_paper_cell_11_-_empty": "Estado de la celda de papel higiénico 11 - vacío",
    "status_toilet_paper_cell_12_-_empty": "Estado de la celda de papel higiénico 12 - vacío",
    "status_toilet_paper_cell_13_-_empty": "Estado de la celda de papel higiénico 13 - vacío",
    "status_toilet_paper_cell_14_-_empty": "Estado de la celda de papel higiénico 14 - vacío",
    "status_toilet_paper_cell_15_-_empty": "Estado de la celda de papel higiénico 15 - vacío",
    "status_toilet_paper_cell_16_-_empty": "Estado de la celda de papel higiénico 16 - vacío",
    "status_toilet_paper_cell_17_-_empty": "Estado de la celda de papel higiénico 17 - vacío",
    "status_toilet_paper_cell_18_-_empty": "Estado de la celda de papel higiénico 18 - vacío",
    "status_toilet_paper_cell_19_-_empty": "Estado de la celda de papel higiénico 19 - vacío",
    "status_toilet_paper_cell_20_-_empty": "Estado de la celda de papel higiénico 20 - vacío",
    "status_toilet_paper_cell_01_-_almost_empty": "Estado de la celda de papel higiénico 01 - casi vacío",
    "status_toilet_paper_cell_02_-_almost_empty": "Estado de la celda de papel higiénico 02 - casi vacío",
    "status_toilet_paper_cell_03_-_almost_empty": "Estado de la celda de papel higiénico 03 - casi vacío",
    "status_toilet_paper_cell_04_-_almost_empty": "Estado de la celda de papel higiénico 04 - casi vacío",
    "status_toilet_paper_cell_05_-_almost_empty": "Estado de la celda de papel higiénico 05 - casi vacío",
    "status_toilet_paper_cell_06_-_almost_empty": "Estado de la celda de papel higiénico 06 - casi vacío",
    "status_toilet_paper_cell_07_-_almost_empty": "Estado de la celda de papel higiénico 07 - casi vacío",
    "status_toilet_paper_cell_08_-_almost_empty": "Estado de la celda de papel higiénico 08 - casi vacío",
    "status_toilet_paper_cell_09_-_almost_empty": "Estado de la celda de papel higiénico 09 - casi vacío",
    "status_toilet_paper_cell_10_-_almost_empty": "Estado de la celda de papel higiénico 10 - casi vacío",
    "status_toilet_paper_cell_11_-_almost_empty": "Estado de la celda de papel higiénico 11 - casi vacío",
    "status_toilet_paper_cell_12_-_almost_empty": "Estado de la celda de papel higiénico 12 - casi vacío",
    "status_toilet_paper_cell_13_-_almost_empty": "Estado de la celda de papel higiénico 13 - casi vacío",
    "status_toilet_paper_cell_14_-_almost_empty": "Estado de la celda de papel higiénico 14 - casi vacío",
    "status_toilet_paper_cell_15_-_almost_empty": "Estado de la celda de papel higiénico 15 - casi vacío",
    "status_toilet_paper_cell_16_-_almost_empty": "Estado de la celda de papel higiénico 16 - casi vacío",
    "status_toilet_paper_cell_17_-_almost_empty": "Estado de la celda de papel higiénico 17 - casi vacío",
    "status_toilet_paper_cell_18_-_almost_empty": "Estado de la celda de papel higiénico 18 - casi vacío",
    "status_toilet_paper_cell_19_-_almost_empty": "Estado de la celda de papel higiénico 19 - casi vacío",
    "status_toilet_paper_cell_20_-_almost_empty": "Estado de la celda de papel higiénico 20 - casi vacío",
  },
  ar: {
    "cleaned_floor": "الارضية نظيفة",
    "cleaned_toilet": "تم تنظيف المراحيض",
    "cleaned_shower": "تم تنظيف الخزائن",
    "cleaned_sink": "تم تنظيف الحوض",
    "cleaned_mirror": "المرآة نظيفة",
    "cleaned_stainless_steel": "تم تنظيف النيروستا",
    "bad_smell": "رائحة كريهة",
    "dirty_floor": "ارضية متسخة",
    "dirty_lavatory_seat": "مقعد المرحاض متسخ",
    "dirty_shower": "الخزائن متسخة",
    "dirty_sink": "الحوض متسخ",
    "dirty_mirror": "المرآة متسخة",
    "dirty_stainless_steel": "النيروستا متسخة",
    "missing_paper_towel": "لا يوجد ورق لليدين",
    "missing_soap": "لا يوجد صابون",
    "missing_toilet_paper": "لا يوجد ورق تواليت",
    "problem_shower": "مشكلة في الخزانة",
    "problem_sink": "مشكلة في الحوض",
    "problem_mirror": "مشكلة في المرأة",
    "problem_stainless_steel": "مشكلة في النيروستا",
    "problem_blockage": "انسداد",
    "problem_door": "مشكلة في الباب",
    "problem_fluid_taps": "حنفيات مسربة",
    "problem_lightning": "مشكلة اضاءة",
    "problem_broken_toilet": "مقعد حمام مكسور",
    "fixed_shower": "تم ترتيب الخزائن",
    "fixed_sink": "تمت معالجة الحوض",
    "fixed_mirror": "تمت معالجة المرآة",
    "fixed_stainless_steel": "تمت معالجة النيروستا",
    "fixed_blockage": "تمت معالجة الانسداد",
    "fixed_door": "تمت معالجة الباب",
    "fixed_fluid_taps": "تمت معالجة التسرب",
    "fixed_lightning": "تمت معالجة الاضاءة",
    "fixed_broken_toilet": "تمت معالجة مقعد الحمام",
    "very_good": "جيد جدا",
    "excellent": "ممتاز",
    "good": "جيد",
    "like": "لايك",
    "needs_improvement": "يحتاج الى تحسين",
    "not_good": "غير جيد",
    "supervise": "المشرف",
    "maintenance": "صيانة",
    "cleaner": "عاملة النظافة",
    "tag_cleaner": "جهاز استشعار وصول عاملة النظافة",
    "zone_is_clean": "مشرف المنطقة نظيف",
    "zone_is_dirty": "مشرف المنطقة متسخ",
    "cleaner_arrived": "وصل عامل النظافة",
    "start_clean_zone": "بداية النظافة",
    "counter": "عدّاد",
    "counter_men": "عدد الرجال",
    "counter_woman": "عدد النساء",
    "counter_handycap": "عدد المعاقين",
    "full_garbage_can": "سلة نفايات ممتلئة",
    "maintenance_fixed": "تمت معالجة الصيانة",
    "maintenance_problem": "مشكلة صيانة",
    "problem_soap_dispenser": "مشكلة مرفق صابون",
    "problem_paper_dispenser": "مشكلة مرفق ورق",
    "end_clean_zone": "الانتهاء من النظافة",
    "status_toilet_paper": "جهاز استشعار ورق التواليت",
    "status_hend_paper": "جهاز استشعار ورق اليدين",
    "fixed": "سليم",
    "status_soap": "جهاز استشعار الصابون السائل",
    "status_garbage_can": "جهاز استشعار سلة النفايات",
    "general_cleaning": "النظافة العامة",
    "coffee_tea": "قهوة/شاي",
    "water_bar": "مرفق للماء",
    "disposable_dishes": "اواني للاستعمال مرة واحدة",
    "head_coverage": "غطاء لمرة واحدة",
    "something_else": "شيء اخر",
    "missing_clothing": "نقص في الملابس",
    "maintenance_link": "ممر صيانة",
    "problem_maintenance": "مشكلة صيانة",
    "supervise_link": "ممر مشرف",
    "cleaner_link": "ممر عاملة نظافة",
    "room_men+link": "ممر غرفه ايجابي للرجال",
    "room_men-link": "ممر غرفه سلبي للرجال",
    "room_women+link": "ممر غرفه ايجابي للنساء",
    "room_women-link": "ممر غرفه سلبي للنساء",
    "hebrew": "العبرية",
    "español": "الاسبانية",
    "français": "الفرنسية",
    "english": "الانجليزية",
    "русский": "الروسية",
    "italiano": "الايطالية",
    "arabic": "العربية",
    "አማርኛ": "الامهرية",
    "português": "البرتغالية",
    "partial_information": "معلومات جزئية",
    "professionalism": "المهنية",
    "the_attitude_of_the_representative": "موقف المندوب",
    "waiting_time_for_service": "وقت الانتظار للخدمة",
    "other_aspect_of_service": "جانب اخر للخدمة",
    "language_of_the_service": "لغة الخدمة",
    "procedure_for_making_an_appointment": "اتخاذ تحديد اجتماع",
    "physical_conditions": "الشروط الجسدية",
    "found_another": "في النهاية وجدت اخراً",
    "found_similar": "وجدت شيئا مشابهاً",
    "did_not_find": "لم اجد",
    "found": "مجدت المنتج",
    "need_improved": "يمكن التحسين",
    "liked": "احببت",
    "pleasant": "لطيفة",
    "eilat_resident": "انا مواطن في ايلات",
    "once_a_week": "مرة في الاسبوع",
    "once_a_month": "مرة في الشهر",
    "twice_a_year": "مرتين الى ثلاث في العام",
    "once_a_year": "مرة في العام",
    "friend": "صديق",
    "newspaper": "جريدة",
    "google": "جوجل",
    "zap": "زاب",
    "vacuum_cleaner": "مكنسة كهربائية",
    "gaming_consoles": "لوحات مفاتيح العاب",
    "laptop": "حاسوب متنقل",
    "cellular": "خليوي",
    "shop": "حوانيت",
    "restaurant_complex_cleaning": "نظافة مجمع المطعم",
    "dining_room": "غرفة الطعام",
    "food_variety": "تنوع الطعام",
    "quality_of_the_food": "جودة الطعام",
    "amount_of_the_food": "كمية الطعام",
    "quality_of_cooking": "جودة الطهي",
    "side_courses": "الاضافات",
    "meat": "لحوم",
    "desserts": "حلويات",
    "vegetarian": "نباتي",
    "salad_bar": "سلطات",
    "main_courses": "الوجبة الاساسية",
    "service": "الخدمة",
    "dining_room_cleanliness": "نظافة المجمع",
    "plates_and_cutlery_cleanliness": "نظافة اواني وادوات تناول الطعام",
    "product_inventory": "مخزون المنتجات",
    "furnishers": "الاثاث",
    "toilets": "الحمامات",
    "problem_coffee_machine": "مشكلة في ماكينة القهوة",
    "problem_water_bar": "مشكلة في بار الماء",
    "dirty_stairs": "درجات المدخل متسخة",
    "toiletries": "مستلزمات المرحاض",
    "fixed_soap_dispenser": "تمت معالجة مرفق الصابون",
    "fixed_paper_dispenser": "تمت معالجة مرفق الورق",
    "fixed_coffee_machine": "تمت معالجة ماكينة القهوة",
    "fixed_water_bar": "تمت معالجة بار المياه",
    "problem_urinals": "مرفق بول معطوب",
    "blockage_toilet": "وعاء ماء المرحاض معطوب",
    "appliance_problem": "جهاز كهربائي معطوب",
    "problem_broken_door": "باب مكسور",
    "problem_door_lock": "مشكلة في قفل الباب",
    "problem_broken_toilet_seat": "مقعد حمام مكسور",
    "problem_broken_niagara": "وعاء ماء الحمام مكسور",
    " broken_hand_paper_dispenser_hand_dryer": "مشكلة مجفف اليدين او الموزع",
    "rug": "السجادة",
    "tables": "الطاولات",
    "kitchen": "مطبخ",
    "air_condition": "التكييف",
    "dirty_urinals": "مرفق البول متسخ",
    "hand_dryer": "مجفف اليدين",
    "non_sticky_carpet": "سجادة غير لاصقة",
    "problem_bad_smell": "مشكلة رائحة كريهة",
    "problem_broken_toilet_handle": "مشكلة في مقبض الحمام",
    "problem_handle_door": "مشكلة في مقبض الباب",
    "call_cleaner": "طلب عامل نظافة",
    "wall_painting": "صباغة الجدران",
    "missing_paper": "لا يوجد ورق",
    "clean_surface": "نظافة الاسطح",
    "problem_right_elevator": "خلل في المصعد الأيمن",
    "problem_left_elevator": "خلل في المصعد الايسر",
    "missing_hand_sanitizer": "البخّاخ المطهر مفقود",
    "problem_sink_blockage": "خلل حوض مسدود",
    "electric_problem": "مشكلة كهرباء",
    "missing_coffe_tea": "لا يوجد قهوة/شاي",
    "problem_air_condition": "مشكلة تكييف",
    "dishwashing": "غسل الاواني",
    "kitchen_cleanliness": "نظافة المطبخ",
    "weekly_cleaning": "التنظيف الاسبوعي",
    "general_arrangement": "الترتيب العام",
    "meeting_room": "غرفة اجتماعات",
    "refrigerator": "برّاد",
    "kitchen_supplies": "تزويد المطبخ",
    "toilet_services": "تزوبد الحمام",
    "carousel_door": "الباب الدائري",
    "desk": "نافذة",
    "compressor": "مكبس",
    "compressor_rails": "مكبس القضبان",
    "compressor_drainage": "مكبس تصريف",
    "dust": "غبار",
    "door": "باب",
    "scotch": "سكوتش",
    "food": "طعام",
    "room_conditions": "الظروف في الغرفة",
    "kindness_sensitivity": "اللباقة والحياسية",
    "explain_process": "شرح العملية",
    "personal_attention": "المعاملة الشخصية",
    "service_problem": "مشكلة خدمة",
    "welcome_you": "استقبلك بالترحاب",
    "invoice_submitted": "قدم الفاتورة ملائمة للمنتج",
    "thank_you_bought": "شكرك انك اشتريت من yellow",
    "problem_seller": "مشكلة بائع",
    "lapel_pin": "يضع دبوس مستدير تطبيق",
    "proper_performance": "بمظهر سليم",
    "name_tag": "يضع دبوس الاسم",
    "operational": "عرض حملات الشهر",
    "app_revealed": "عرض عليك التطبيق",
    "register_load": "ازدحام عند الصندوق",
    "visibility_problem": "مشكلة المظهر",
    "clean_storefront": "ساحة مدخل الحانوت نظيفة",
    "clean_garbage": "سلال المهملات نظيفة وغير",
    "clean_tables_chairs": "الطاولات والكراسي نظيفة",
    "clean_shop_floor": "ارضية الحانوت نظيفة",
    "product_show": "معرض المنتجات ممتلئ",
    "refrigerators_show": "معرض البرادات ممتلئ ونظيف",
    "coffee_accessories": "مرفق مستلزمات القهوة نظيف ومزود",
    "cleanliness": "النظافة",
    "convenience_scheduling_appointments": "سهولة تحديد الادوار",
    "waiting_haircut": "الانتظار لقص الشعر",
    "courtesy_books": "أدب مصففي الشعر",
    "books_professionalism": "مهنية مصففي الشعر",
    "received_time": "استقبلوني في الوقت المحدد",
    "dnot_appointment": "لم احدد دورا",
    "an_appointment": "حددت دورا",
    "tasty": "شهي",
    "courtesy_service": "لباقة الخدمة",
    "overall_experience": "التجربة العامة",
    "not_clean": "ليس نظيفا",
    "diningroom_not_clean": "غرفة الطعام ليست نظيفة",
    "unclean_dishes": "الاواني ليست نظيفة",
    "so-so": "بين بين",
    "not_tasty": "ليس شهيا",
    "kind": "مؤدب",
    "not_kind": "ليس مؤدبا",
    "clean": "نظيفا",
    "hairdresser": "مصفف شعر",
    "wrong_price": "سعر غير صحيح",
    "produc_has_expired": "منتهية الصلاحية المنتج",
    "missing_item": "مادة مفقودة",
    "item_is_faulty_in_the_machine": "عنصر عالق في عطل الجهاز",
    "machine_cleaning": "آلة التنظيف",
    "improper_temperature": "درجة حرارة غير طبيعية",
    "green": "اخضر",
    "orange": "برتقالي",
    "red": "احمر",
    "yellow": "اصفر",
    "red not prob": "احمر",
    "professional": "المحترفين",
    "unprofessional": "غير مهني",
    "delicacies": "الكفاءة",
    "sandwiches": "السندويشات",
    "vegetables": "خضروات",
    "fruits": "فواكه",
    "salads": "سلطة",
    "milk": "حليب",
    "room_link": "غرفة عبور",
    "floor_link": "طابق عبور",
    "services": "تزويد",
    "check_list": "قائمة",
    "lighting_problem_lobby": "مشكلة الإضاءة في الردهة",
    "lighting_problem_garbage_room": "مشكلة الإضاءة في غرفة القمامة",
    "lighting_problem_stairwell": "مشكلة الإضاءة في غرفة الدرج",
    "problem_elevator_1": "خطأ في الرفع 1",
    "problem_elevator_2": "خطأ في المصعد 2",
    "problem_elevator_3": "خطأ في الرفع 3",
    "problem_elevator_4": "خطأ في الرفع 4",
    "problem_elevator_5": "خطأ في الرفع 5",
    "problem_elevator_6": "خطأ في المصعد 6",
    "problem_elevator_7": "خطأ في المصعد 7",
    "problem_elevator_8": "خطأ في المصعد 8",
    "problem_elevator_9": "خطأ في الرفع 9",
    "problem_elevator_10": "خطأ في الرفع 10",
    "stuck_item": "السلعة عالقة",
    "spoiling_product": "منتج مدلل",
    "machine_malfunction": "عطل الجهاز",
    "unreadable_product": "منتج غير قابل للقراءة",
    "i_am_satisfied": "انا راضي",
    "beverage": "المشروبات",
    "program_malfunction": "برنامج خطأ",
    "machine_1": "آلة 1.",
    "machine_2": "آلة 2",
    "machine_3": "آلة 3.",
    "machine_4": "آلة 4.",
    "program malfunction 1": "عطل البرنامج 1.",
    "program malfunction 2": "عطل البرنامج 2.",
    "program malfunction 3": "عطل البرنامج 3",
    "program malfunction 4": "عطل البرنامج 4.",
    "fish": "سمكه",
    "chicken": "دجاج",
    "full_dining_room": "غرفة الطعام الكامل",
    "missing_amount": "مفقود المبلغ",
    "missing_trays": "صواني مفقودة",
    "dirty_floor_g1": "نظافة الدفيئة 1",
    "dirty_floor_g2": "نظافة الدفيئة 2",
    "dirty_floor_g3": "نظافة الدفيئة 3",
    "dirty_floor_g4": "نظافة الدفيئة 4",
    "dirty_floor_g5": "نظافة الدفيئة 5",
    "dirty_floor_eo": "نظافة مكتب مساعد المدير العام",
    "dirty_floor_ceo": "نظافة مكتب المدير العام",
    "dirty_floor_storage": "النظافة في طابق المخزن",
    "dirty_floor_inflorescence": "نظافة المزهرية",
    "operating_instructions": "تعليمات التشغيل",
    "general": "عام",
    "connection_problem": "مشكلة في التوصيل",
    "lighting": "الاضاءة",
    "not _fixed": "غير صالح",
    "cleaning_needed": "التنظيف مطلوب",
    "missing_milk": "لا يوجد حليب",
    "no_sugar_coffee": "لا يوجد سكر/قهوة",
    "missing_tools": "لا يوجد اواني",
    "coffee_water_machine": "ماكينات القهوة/ماكينات المياه",
    "appliances": "المنتجات الكهربائية",
    "products": "منتجات",
    "broken_chair": "اثاث معطوب",
    "broken_cabinet": "خزانة مكسورة",
    "micro": "ميكرو",
    "toaster": "توستر",
    "clogged_sink_malfunction": "خلل حوض مسدود",
    "coffee_cooling_malfunction": "خلل في تبريد القهوة",
    "no_coffee_beans": "حب القهوة غير موجود",
    "machine_failure": "خلل في الماكينة",
    "that_dirty": "الشايش متسخ",
    "dirty_refrigerator": "البراد متسخ",
    "dirty_tables": "الطاولات متسخة",
    "plates": "الصحون",
    "silver_spoon": "ملعقة فضية",
    "cold_drinks_cups": "اكواب المشروبات البارده",
    "hot_drink_cups": "اكواب المشروبات الساخنة",
    "a_dripping_faucet": "حنفية تنقيط",
    "disposable dishes": "اواني للاستعمال مرة واحدة",
    "problem_ coffee_water_machine": "مشكلة ماكينات القهوة/ماكينات المياه",
    "problem_micro": "مشكلة ميكرو",
    "problem_toaster": "مشكلة توستر",
    "problem_machine_failure": "مشكلة خلل في الماكينة",
    "problem refrigerator": "مشكلة براد",
    "no_sugar/coffee": "لا يوجد سكر/قهوة",
    "missing_dishes": "لا يوجد اواني",
    "coffee/water_machine": "ماكينات القهوة/ماكينات المياه",
    "coffee_machine_door_open": "فتح آلة القهوة",
    "coffee_machine_door_close": "أغلقت آلة القهوة",
    "coffee_machine_bin_full": "آلة القهوة الكامل",
    "coffee_machine_bin_empty": "آلة القهوة فارغة",
    "coffee_machine_coffee_full": "القهوة القهوة الكاملة",
    "coffee_machine_coffee_empty": "القهوة والقهوة الفارغة",
    "coffee_machine_water_tank_empty": "آلة القهوة المياه فارغة",
    "coffee_machine_ water_tank_full": "آلة القهوة الكامل",
    "coffee_machine_milk_cleaning_not_performed": "آلة تنظيف آلة القهوة لا يؤديها",
    "coffee_machine _milk_cleaning_performed": "آلة تنظيف آلة القهوة",
    "coffee_machine_head_cleaning_not_performed": "آلة القهوة ورأس التنظيف لم تؤدي",
    "coffee_machine_head_ cleaning_performed": "يتم إجراء تنظيف آلة القهوة",
    "coffee_machine_descaling_not_performed": "آلة القهوة descaling لم تؤدي",
    "coffee_machine_descaling_performed": "آلة صنع القهوة descaling",
    "floor_one": "الطابق 1",
    "floor_two": "الطابق 2",
    "floor_three": "الطابق 3",
    "floor_four": "الطابق 4",
    "floor_five": "الطابق 5",
    "floor_six": "الطابق 6",
    "floor_seven": "الطابق 7",
    "floor_eight": "الطابق 8",
    "floor_nine": "الطابق 9",
    "floor_ten": "الطابق 10",
    "floor_eleven": "الطابق 11",
    "floor_twelve": "الطابق 12",
    "floor_thirteen": "الطابق 13",
    "floor_fourteen": "الطابق 14",
    "floor_fifteen": "الطابق 15",
    "floor_sixteen": "الطابق 16",
    "floor_seventeen": "الطابق 17",
    "floor_eighteen": "الطابق 18",
    "floor_nineteen": "الطابق 19",
    "floor_twenty": "الطابق 20",
    "floor_twenty_one": "الطابق 21",
    "floor_twenty_two": "الطابق 22",
    "floor_twenty_three": "الطابق 23",
    "floor_twenty_four": "الطابق 24",
    "floor_twenty_five": "الطابق 25",
    "status_toilet_paper_cell_01_-_empty": "حالة خزان ورق الحمام 01 - فارغ",
    "status_toilet_paper_cell_02_-_empty": "حالة خزان ورق الحمام 02 - فارغ",
    "status_toilet_paper_cell_03_-_empty": "حالة خزان ورق الحمام 03 - فارغ",
    "status_toilet_paper_cell_04_-_empty": "حالة خزان ورق الحمام 04 - فارغ",
    "status_toilet_paper_cell_05_-_empty": "حالة خزان ورق الحمام 05 - فارغ",
    "status_toilet_paper_cell_06_-_empty": "حالة خزان ورق الحمام 06 - فارغ",
    "status_toilet_paper_cell_07_-_empty": "حالة خزان ورق الحمام 07 - فارغ",
    "status_toilet_paper_cell_08_-_empty": "حالة خزان ورق الحمام 08 - فارغ",
    "status_toilet_paper_cell_09_-_empty": "حالة خزان ورق الحمام 09 - فارغ",
    "status_toilet_paper_cell_10_-_empty": "حالة خزان ورق الحمام 10 - فارغ",
    "status_toilet_paper_cell_11_-_empty": "حالة خزان ورق الحمام 11 - فارغ",
    "status_toilet_paper_cell_12_-_empty": "حالة خزان ورق الحمام 12 - فارغ",
    "status_toilet_paper_cell_13_-_empty": "حالة خزان ورق الحمام 13 - فارغ",
    "status_toilet_paper_cell_14_-_empty": "حالة خزان ورق الحمام 14 - فارغ",
    "status_toilet_paper_cell_15_-_empty": "حالة خزان ورق الحمام 15 - فارغ",
    "status_toilet_paper_cell_16_-_empty": "حالة خزان ورق الحمام 16 - فارغ",
    "status_toilet_paper_cell_17_-_empty": "حالة خزان ورق الحمام 17 - فارغ",
    "status_toilet_paper_cell_18_-_empty": "حالة خزان ورق الحمام 18 - فارغ",
    "status_toilet_paper_cell_19_-_empty": "حالة خزان ورق الحمام 19 - فارغ",
    "status_toilet_paper_cell_20_-_empty": "حالة خزان ورق الحمام 20 - فارغ",
    "status_toilet_paper_cell_01_-_almost_empty": "حالة خزان ورق الحمام 01 - تقريبا فارغ",
    "status_toilet_paper_cell_02_-_almost_empty": "حالة خزان ورق الحمام 02 - تقريبا فارغ",
    "status_toilet_paper_cell_03_-_almost_empty": "حالة خزان ورق الحمام 03 - تقريبا فارغ",
    "status_toilet_paper_cell_04_-_almost_empty": "حالة خزان ورق الحمام 04 - تقريبا فارغ",
    "status_toilet_paper_cell_05_-_almost_empty": "حالة خزان ورق الحمام 05 - تقريبا فارغ",
    "status_toilet_paper_cell_06_-_almost_empty": "حالة خزان ورق الحمام 06 - تقريبا فارغ",
    "status_toilet_paper_cell_07_-_almost_empty": "حالة خزان ورق الحمام 07 - تقريبا فارغ",
    "status_toilet_paper_cell_08_-_almost_empty": "حالة خزان ورق الحمام 08 - تقريبا فارغ",
    "status_toilet_paper_cell_09_-_almost_empty": "حالة خزان ورق الحمام 09 - تقريبا فارغ",
    "status_toilet_paper_cell_10_-_almost_empty": "حالة خزان ورق الحمام 10 - تقريبا فارغ",
    "status_toilet_paper_cell_11_-_almost_empty": "حالة خزان ورق الحمام 11 - تقريبا فارغ",
    "status_toilet_paper_cell_12_-_almost_empty": "حالة خزان ورق الحمام 12 - تقريبا فارغ",
    "status_toilet_paper_cell_13_-_almost_empty": "حالة خزان ورق الحمام 13 - تقريبا فارغ",
    "status_toilet_paper_cell_14_-_almost_empty": "حالة خزان ورق الحمام 14 - تقريبا فارغ",
    "status_toilet_paper_cell_15_-_almost_empty": "حالة خزان ورق الحمام 15 - تقريبا فارغ",
    "status_toilet_paper_cell_16_-_almost_empty": "حالة خزان ورق الحمام 16 - تقريبا فارغ",
    "status_toilet_paper_cell_17_-_almost_empty": "حالة خزان ورق الحمام 17 - تقريبا فارغ",
    "status_toilet_paper_cell_18_-_almost_empty": "حالة خزان ورق الحمام 18 - تقريبا فارغ",
    "status_toilet_paper_cell_19_-_almost_empty": "حالة خزان ورق الحمام 19 - تقريبا فارغ",
    "status_toilet_paper_cell_20_-_almost_empty": "حالة خزان ورق الحمام 20 - تقريبا فارغ",
  },
  pl: {
    "cleaned_floor": "Wyczyszczono podłogi",
    "cleaned_toilet": "Wyczyszczono toaletę",
    "cleaned_shower": "Wyczyszczono prysznic",
    "cleaned_sink": "Wyczyszczono umywalkę",
    "cleaned_mirror": "Wyczyszczono lustro",
    "cleaned_stainless_steel": "Wyczyszczono stal nierdzewną",
    "bad_smell": "Zły zapach",
    "dirty_floor": "Brudna podłoga",
    "dirty_lavatory_seat": "Brudny fotel",
    "dirty_shower": "Brudny prysznic",
    "dirty_sink": "Brudna umywalka",
    "dirty_mirror": "Brudne lustro",
    "dirty_stainless_steel": "Brudna stal nierdzewna",
    "missing_paper_towel": "Brak papieru toaletowego",
    "missing_soap": "Brak mydła",
    "missing_toilet_paper": "Brak papieru toaletowego",
    "problem_shower": "Problem z prysznicem",
    "problem_sink": "Problem z umywalką",
    "problem_mirror": "Problem z lustrem",
    "problem_stainless_steel": "Problem z stalą nierdzewną",
    "problem_blockage": "Problem z zatkaniem",
    "problem_door": "Problem z drzwiami",
    "problem_fluid_taps": "Problem z kranami",
    "problem_lightning": "Problem z oświetleniem",
    "problem_broken_toilet": "Zepsuta toaleta",
    "fixed_shower": "Naprawiono prysznic",
    "fixed_sink": "Naprawiono umywalkę",
    "fixed_mirror": "Naprawiono lustro",
    "fixed_stainless_steel": "Naprawiono stal nierdzewną",
    "fixed_blockage": "Naprawiono zatkanie",
    "fixed_door": "Naprawiono drzwi",
    "fixed_fluid_taps": "Naprawiono kran",
    "fixed_lightning": "Naprawiono oświetlenie",
    "fixed_broken_toilet": "Naprawiono toaletę",
    "very_good": "Bardzo dobra",
    "excellent": "Doskonała",
    "good": "Dobra",
    "like": "Lubię",
    "needs_improvement": "Wymaga poprawy",
    "not_good": "Nie dobra",
    "supervise": "Nadzoruj",
    "maintenance": "Konserwacja",
    "cleaner": "Czysty",
    "tag_cleaner": "Tag Czysty",
    "zone_is_clean": "Strefa jest czysta",
    "zone_is_dirty": "Strefa jest brudna",
    "cleaner_arrived": "Czysty przybył",
    "start_clean_zone": "Rozpocznij czyszczenie strefy",
    "counter": "Licznik",
    "counter_men": "Licznik mężczyzn",
    "counter_woman": "Licznik kobiet",
    "counter_handycap": "Licznik niepełnosprawnych",
    "full_garbage_can": "Pełna kosza na śmieci",
    "maintenance_fixed": "Konserwacja naprawiona",
    "maintenance_problem": "Problem konserwacji",
    "problem_soap_dispenser": "Problem z dystrybutorem mydła",
    "problem_paper_dispenser": "Problem z dystrybutorem papieru",
    "end_clean_zone": "Zakończ czyszczenie strefy",
    "status_toilet_paper": "Status papieru toaletowego",
    "status_hend_paper": "Status papieru ręcznego",
    "fixed": "Naprawiono",
    "status_soap": "Status mydła",
    "status_garbage_can": "Status kosza na śmieci",
    "general_cleaning": "Ogólne czyszczenie",
    "coffee_tea": "Kawa / Herbata",
    "water_bar": "Bar wody",
    "disposable_dishes": "Pojemniki jednorazowe",
    "head_coverage": "Pokrycie głowy",
    "something_else": "Coś innego",
    "missing_clothing": "Brak ubrań",
    "maintenance_link": "Konserwacja linku",
    "problem_maintenance": "Problem konserwacji linku",
    "supervise_link": "Nadzoruj link",
    "cleaner_link": "Czysty link",
    "room_men+link": "Pokój mężczyzn + link",
    "room_men-link": "Pokój mężczyzn - link",
    "room_women+link": "Pokój kobiet + link",
    "room_women-link": "Pokój kobiet - link",
    "hebrew": "Hebrajski",
    "español": "Hiszpański",
    "français": "Francuski",
    "english": "Angielski",
    "русский": "Rosyjski",
    "italiano": "Włoski",
    "አማርኛ": "Amharski",
    "português": "Portugalski",
    "partial_information": "Częściowe informacje",
    "professionalism": "Profesjonalizm",
    "the_attitude_of_the_representative": "Postawa przedstawiciela",
    "waiting_time_for_service": "Czas oczekiwania na usługę",
    "other_aspect_of_service": "Inny aspekt usługi",
    "language_of_the_service": "Język usługi",
    "procedure_for_making_an_appointment": "Procedura umawiania się",
    "physical_conditions": "Warunki fizyczne",
    "found_another": "Znalazłem innego",
    "found_similar": "Znalazłem podobnego",
    "did_not_find": "Nie znalazłem",
    "found": "Znalazłem",
    "need_improved": "Wymaga poprawy",
    "liked": "Lubię",
    "pleasant": "Przyjemny",
    "eilat_resident": "Rezydent Eilat",
    "once_a_week": "Raz w tygod",
    "once_a_month": "Raz w miesiącu",
    "twice_a_year": "Dwa razy w roku",
    "once_a_year": "Raz w roku",
    "friend": "Przyjaciel",
    "newspaper": "Gazeta",
    "google": "Google",
    "zap": "Zap",
    "vacuum_cleaner": "Odkurzacz",
    "gaming_consoles": "Konsola do gier",
    "laptop": "Laptop",
    "cellular": "Komórka",
    "shop": "Sklep",
    "restaurant_complex_cleaning": "Czyszczenie kompleksu restauracyjnego",
    "dining_room": "Jadalnia",
    "food_variety": "Różnorodność jedzenia",
    "quality_of_the_food": "Jakość jedzenia",
    "amount_of_the_food": "Ilość jedzenia",
    "quality_of_cooking": "Jakość gotowania",
    "side_courses": "Przystawki",
    "meat": "Mięso",
    "desserts": "Desery",
    "vegetarian": "Wegetariański",
    "salad_bar": "Bar sałatek",
    "main_courses": "Dania główne",
    "service": "Obsługa",
    "dining_room_cleanliness": "Czystość jadalni",
    "plates_and_cutlery_cleanliness": "Czystość talerzy i sztućców",
    "product_inventory": "Inwentarz produktów",
    "furnishers": "Meble",
    "toilets": "Toalety",
    "problem_coffee_machine": "Problem z maszyną do kawy",
    "problem_water_bar": "Problem z bar wody",
    "dirty_stairs": "Brudne schody",
    "toiletries": "Artykuły toaletowe",
    "fixed_soap_dispenser": "Naprawiono dystrybutor mydła",
    "fixed_paper_dispenser": "Naprawiono dystrybutor papieru",
    "fixed_coffee_machine": "Naprawiono maszynę do kawy",
    "fixed_water_bar": "Naprawiono bar wody",
    "problem_urinals": "Problem z urynami",
    "blockage_toilet": "Zablokowanie toalety",
    "appliance_problem": "Problem z urządzeniem",
    "problem_broken_door": "Problem z uszkodzonym drzwiami",
    "problem_door_lock": "Problem z zamkiem drzwi",
    "problem_broken_toilet_seat": "Problem z uszkodzonym siedzeniem na toalecie",
    "problem_broken_niagara": "Problem z uszkodzonym Niagara",
    " broken_hand_paper_dispenser_hand_dryer": "Uszkodzony dystrybutor papieru do rąk / suszarka do rąk",
    "rug": "Wykładzina",
    "tables": "Stoły",
    "kitchen": "Kuchnia",
    "air_condition": "Klimatyzacja",
    "dirty_urinals": "Brudne uryny",
    "hand_dryer": "Suszarka do rąk",
    "non_sticky_carpet": "Nie klejąca wykładzina",
    "problem_bad_smell": "Problem z złą zapachem",
    "problem_broken_toilet_handle": "Problem z uszkodzonym uchwytem do toalety",
    "problem_handle_door": "Problem z uchwytem drzwi",
    "call_cleaner": "Zadzwoń do sprzątacza",
    "wall_painting": "Malowanie ścian",
    "missing_paper": "Brak papieru",
    "clean_surface": "Czysta powierzchnia",
    "problem_right_elevator": "Problem z prawym windą",
    "problem_left_elevator": "Problem z lewym windą",
    "missing_hand_sanitizer": "Brak dezynfekcji rąk",
    "problem_sink_blockage": "Problem z zablokowanym zlewem",
    "electric_problem": "Problem z elektryką",
    "missing_coffe_tea": "Brak kawy / herbaty",
    "problem_air_condition": "Problem z klimatyzacją",
    "dishwashing": "Myjnia naczyń",
    "kitchen_cleanliness": "Czystość kuchni",
    "weekly_cleaning": "Czyszczenie tygodniowe",
    "general_arrangement": "Ogólna aranżacja",
    "meeting_room": "Sala konferencyjna",
    "refrigerator": "Lodówka",
    "kitchen_supplies": "Artykuły kuchenne",
    "toilet_services": "Usługi toaletowe",
    "carousel_door": "Karuzela drzwi",
    "desk": "Biurko",
    "compressor": "Kompresor",
    "compressor_rails": "Śruby kompresora",
    "compressor_drainage": "Odprowadzenie kompresora",
    "dust": "Pył",
    "door": "Drzwi",
    "scotch": "Scotch",
    "food": "Jedzenie",
    "room_conditions": "Warunki pokoju",
    "kindness_sensitivity": "Uprzejmość i wrażliwość",
    "explain_process": "Wyjaśnij proces",
    "personal_attention": "Osobista uwaga",
    "service_problem": "Problem z obsługą",
    "welcome_you": "Witamy Cię",
    "invoice_submitted": "Faktura wysłana",
    "thank_you_bought": "Dziękujemy za zakupy",
    "problem_seller": "Problem z sprzedawcą",
    "lapel_pin": "Krawat",
    "proper_performance": "Dobry występ",
    "name_tag": "Etykieta nazwiska",
    "operational": "Operacyjny",
    "app_revealed": "Aplikacja ujawniona",
    "register_load": "Zarejestruj obciążenie",
    "visibility_problem": "Problem z widocznością",
    "clean_storefront": "Czyste okno sklepowe",
    "clean_garbage": "Czyste śmieci",
    "clean_tables_chairs": "Czyste stoły i krzesła",
    "clean_shop_floor": "Czysty podłóg sklepu",
    "product_show": "Pokaz produktu",
    "refrigerators_show": "Pokaz lodówek",
    "coffee_accessories": "Akcesoria do kawy",
    "cleanliness": "Czystość",
    "convenience_scheduling_appointments": "Wygoda w planowaniu spotkań",
    "waiting_haircut": "Czekanie na fryzjer",
    "courtesy_books": "Uprzejmość książek",
    "books_professionalism": "Książki profesjonalizmu",
    "received_time": "Otrzymano czas",
    "dnot_appointment": "Nie ma spotkania",
    "an_appointment": "Spotkanie",
    "tasty": "Smaczne",
    "courtesy_service": "Uprzejmość obsługi",
    "overall_experience": "Ogólny doświadczenie",
    "not_clean": "Nie czyste",
    "diningroom_not_clean": "Jadalnia nie czysta",
    "unclean_dishes": "Nieczyste naczynia",
    "so-so": "Tak sobie",
    "not_tasty": "Nie smaczne",
    "kind": "Rodzaj",
    "not_kind": "Nie uprzejmy",
    "clean": "Czyste",
    "hairdresser": "Fryzjer",
    "wrong_price": "Zła cena",
    "produc_has_expired": "Produkt wygasł",
    "missing_item": "Brakujący przedmiot",
    "item_is_faulty_in_the_machine": "Przedmiot jest wadliwy w maszynie",
    "machine_cleaning": "Czyszczenie maszyny",
    "improper_temperature": "Nieprawidłowa temperatura",
    "green": "Zielony",
    "orange": "Pomarańczowy",
    "red": "Czerwony",
    "yellow": "Żółty",
    "red not prob": "Czerwony nie problem",
    "professional": "Profesjonalny",
    "unprofessional": "Nieprofesjonalny",
    "delicacies": "Delikatesy",
    "sandwiches": "Kanapki",
    "vegetables": "Warzywa",
    "fruits": "Owoce",
    "salads": "Sałatki",
    "milk": "Mleko",
    "room_link": "Pokój link",
    "floor_link": "Link do piętra",
    "services": "Usługi",
    "check_list": "Lista kontrolna",
    "lighting_problem_lobby": "Problem z oświetleniem lobby",
    "lighting_problem_garbage_room": "Problem z oświetleniem pomieszczenia śmieci",
    "lighting_problem_stairwell": "Problem z oświetleniem klatki schodowej",
    "problem_elevator_1": "Problem z windą 1",
    "problem_elevator_2": "Problem z windą 2",
    "problem_elevator_3": "Problem z windą 3",
    "problem_elevator_4": "Problem z windą 4",
    "problem_elevator_5": "Problem z windą 5",
    "problem_elevator_6": "Problem z windą 6",
    "problem_elevator_7": "Problem z windą 7",
    "problem_elevator_8": "Problem z windą 8",
    "problem_elevator_9": "Problem z windą 9",
    "problem_elevator_10": "Problem z windą 10",
    "stuck_item": "Zablokowany przedmiot",
    "spoiling_product": "Produkt zepsuty",
    "machine_malfunction": "Awaria maszyny",
    "unreadable_product": "Nieczytelny produkt",
    "i_am_satisfied": "Jestem zadowolony",
    "beverage": "Napoje",
    "program_malfunction": "Awaria programu",
    "machine_1": "Maszyna 1",
    "machine_2": "Maszyna 2",
    "machine_3": "Maszyna 3",
    "machine_4": "Maszyna 4",
    "program malfunction 1": "Awaria programu 1",
    "program malfunction 2": "Awaria programu 2",
    "program malfunction 3": "Awaria programu 3",
    "program malfunction 4": "Awaria programu 4",
    "fish": "Ryby",
    "chicken": "Kurczak",
    "full_dining_room": "Pełna jadalnia",
    "missing_amount": "Brakująca kwota",
    "missing_trays": "Brakujące talerze",
    "dirty_floor_g1": "Brudna podłoga g1",
    "dirty_floor_g2": "Brudna podłoga g2",
    "dirty_floor_g3": "Brudna podłoga g3",
    "dirty_floor_g4": "Brudna podłoga g4",
    "dirty_floor_g5": "Brudna podłoga g5",
    "dirty_floor_eo": "Brudna podłoga eo",
    "dirty_floor_ceo": "Brudna podłoga ceo",
    "dirty_floor_storage": "Brudna podłoga magazyn",
    "dirty_floor_inflorescence": "Brudna podłoga inflorescencja",
    "operating_instructions": "Instrukcje obsługi",
    "general": "Ogólne",
    "connection_problem": "Problem z połączeniem",
    "lighting": "Oświetlenie",
    "not _fixed": "Nie naprawiono",
    "cleaning_needed": "Potrzebne czyszczenie",
    "missing_milk": "Brakujące mleko",
    "no_sugar_coffee": "Brak cukru do kawy",
    "missing_tools": "Brakujące narzędzia",
    "coffee_water_machine": "Kawa i woda w maszynie",
    "appliances": "Urządzenia",
    "products": "Produkty",
    "broken_chair": "Zepsuty krzesło",
    "broken_cabinet": "Zepsuty szafek",
    "micro": "Mikro",
    "toaster": "Toster",
    "clogged_sink_malfunction": "Zatopiony zlew awaria",
    "coffee_cooling_malfunction": "Awaria chłodzenia kawy",
    "no_coffee_beans": "Brak ziaren kawy",
    "machine_failure": "Awaria maszyny",
    "that_dirty": "To brudne",
    "dirty_refrigerator": "Brudny lodówka",
    "dirty_tables": "Brudne stoły",
    "plates": "Talerze",
    "silver_spoon": "Srebrna łyżka",
    "cold_drinks_cups": "Zimne napoje kubki",
    "hot_drink_cups": "Ciepłe napoje kubki",
    "a_dripping_faucet": "Ciekający kran",
    "disposable dishes": "Jednorazowe talerze",
    "problem_ coffee_water_machine": "Problem z kawą i wodą w maszynie",
    "problem_micro": "Problem z mikro",
    "problem_toaster": "Problem z tosterem",
    "problem_machine_failure": "Problem z awarią maszyny",
    "problem refrigerator": "Problem z lodówką",
    "no_sugar/coffee": "Brak cukru/kawy",
    "missing_dishes": "Brakujące talerze",
    "coffee/water_machine": "Kawa/woda w maszynie",
    "coffee_machine_door_open": "Drzwi kawy otwarte",
    "coffee_machine_door_close": "Drzwi kawy zamknięte",
    "coffee_machine_bin_full": "Kosz kawy pełny",
    "coffee_machine_bin_empty": "Kosz kawy pusty",
    "coffee_machine_coffee_full": "Kawa w maszynie pełna",
    "coffee_machine_coffee_empty": "Kawa w maszynie pusta",
    "coffee_machine_water_tank_empty": "Zbiornik wody w maszynie pusty",
    "coffee_machine_ water_tank_full": "Zbiornik wody w maszynie pełny",
    "coffee_machine_milk_cleaning_not_performed": "Czyszczenie mleka w maszynie nie wykonane",
    "coffee_machine _milk_cleaning_performed": "Czyszczenie mleka w maszynie wykonane",
    "coffee_machine_head_cleaning_not_performed": "Czyszczenie głowy w maszynie nie wykonane",
    "coffee_machine_head_ cleaning_performed": "Czyszczenie głowy w maszynie wykonane",
    "coffee_machine_descaling_not_performed": "Odkamienianie w maszynie nie wykonane",
    "coffee_machine_descaling_performed": "Odkamienianie w maszynie wykonane",
    "floor_one": "Piętro 1",
    "floor_two": "Piętro 2",
    "floor_three": "Piętro 3",
    "floor_four": "Piętro 4",
    "floor_five": "Piętro 5",
    "floor_six": "Piętro 6",
    "floor_seven": "Piętro 7",
    "floor_eight": "Piętro 8",
    "floor_nine": "Piętro 9",
    "floor_ten": "Piętro 10",
    "floor_eleven": "Piętro 11",
    "floor_twelve": "Piętro 12",
    "floor_thirteen": "Piętro 13",
    "floor_fourteen": "Piętro 14",
    "floor_fifteen": "Piętro 15",
    "floor_sixteen": "Piętro 16",
    "floor_seventeen": "Piętro 17",
    "floor_eighteen": "Piętro 18",
    "floor_nineteen": "Piętro 19",
    "floor_twenty": "Piętro 20",
    "floor_twenty_one": "Piętro 21",
    "floor_twenty_two": "Piętro 22",
    "floor_twenty_three": "Piętro 23",
    "floor_twenty_four": "Piętro 24",
    "floor_twenty_five": "Piętro 25",
    "status_toilet_paper_cell_01_-_empty": "Status papieru toaletowego komórka 01 - pusty",
    "status_toilet_paper_cell_02_-_empty": "Status papieru toaletowego komórka 02 - pusty",
    "status_toilet_paper_cell_03_-_empty": "Status papieru toaletowego komórka 03 - pusty",
    "status_toilet_paper_cell_04_-_empty": "Status papieru toaletowego komórka 04 - pusty",
    "status_toilet_paper_cell_05_-_empty": "Status papieru toaletowego komórka 05 - pusty",
    "status_toilet_paper_cell_06_-_empty": "Status papieru toaletowego komórka 06 - pusty",
    "status_toilet_paper_cell_07_-_empty": "Status papieru toaletowego komórka 07 - pusty",
    "status_toilet_paper_cell_08_-_empty": "Status papieru toaletowego komórka 08 - pusty",
    "status_toilet_paper_cell_09_-_empty": "Status papieru toaletowego komórka 09 - pusty",
    "status_toilet_paper_cell_10_-_empty": "Status papieru toaletowego komórka 10 - pusty",
    "status_toilet_paper_cell_11_-_empty": "Status papieru toaletowego komórka 11 - pusty",
    "status_toilet_paper_cell_12_-_empty": "Status papieru toaletowego komórka 12 - pusty",
    "status_toilet_paper_cell_13_-_empty": "Status papieru toaletowego komórka 13 - pusty",
    "status_toilet_paper_cell_14_-_empty": "Status papieru toaletowego komórka 14 - pusty",
    "status_toilet_paper_cell_15_-_empty": "Status papieru toaletowego komórka 15 - pusty",
    "status_toilet_paper_cell_16_-_empty": "Status papieru toaletowego komórka 16 - pusty",
    "status_toilet_paper_cell_17_-_empty": "Status papieru toaletowego komórka 17 - pusty",
    "status_toilet_paper_cell_18_-_empty": "Status papieru toaletowego komórka 18 - pusty",
    "status_toilet_paper_cell_19_-_empty": "Status papieru toaletowego komórka 19 - pusty",
    "status_toilet_paper_cell_20_-_empty": "Status papieru toaletowego komórka 20 - pusty",
    "status_toilet_paper_cell_01_-_almost_empty": "Status papieru toaletowego komórka 01 - prawie pusty",
    "status_toilet_paper_cell_02_-_almost_empty": "Status papieru toaletowego komórka 02 - prawie pusty",
    "status_toilet_paper_cell_03_-_almost_empty": "Status papieru toaletowego komórka 03 - prawie pusty",
    "status_toilet_paper_cell_04_-_almost_empty": "Status papieru toaletowego komórka 04 - prawie pusty",
    "status_toilet_paper_cell_05_-_almost_empty": "Status papieru toaletowego komórka 05 - prawie pusty",
    "status_toilet_paper_cell_06_-_almost_empty": "Status papieru toaletowego komórka 06 - prawie pusty",
    "status_toilet_paper_cell_07_-_almost_empty": "Status papieru toaletowego komórka 07 - prawie pusty",
    "status_toilet_paper_cell_08_-_almost_empty": "Status papieru toaletowego komórka 08 - prawie pusty",
    "status_toilet_paper_cell_09_-_almost_empty": "Status papieru toaletowego komórka 09 - prawie pusty",
    "status_toilet_paper_cell_10_-_almost_empty": "Status papieru toaletowego komórka 10 - prawie pusty",
    "status_toilet_paper_cell_11_-_almost_empty": "Status papieru toaletowego komórka 11 - prawie pusty",
    "status_toilet_paper_cell_12_-_almost_empty": "Status papieru toaletowego komórka 12 - prawie pusty",
    "status_toilet_paper_cell_13_-_almost_empty": "Status papieru toaletowego komórka 13 - prawie pusty",
    "status_toilet_paper_cell_14_-_almost_empty": "Status papieru toaletowego komórka 14 - prawie pusty",
    "status_toilet_paper_cell_15_-_almost_empty": "Status papieru toaletowego komórka 15 - prawie pusty",
    "status_toilet_paper_cell_16_-_almost_empty": "Status papieru toaletowego komórka 16 - prawie pusty",
    "status_toilet_paper_cell_17_-_almost_empty": "Status papieru toaletowego komórka 17 - prawie pusty",
    "status_toilet_paper_cell_18_-_almost_empty": "Status papieru toaletowego komórka 18 - prawie pusty",
    "status_toilet_paper_cell_19_-_almost_empty": "Status papieru toaletowego komórka 19 - prawie pusty",
    "status_toilet_paper_cell_20_-_almost_empty": "Status papieru toaletowego komórka 20 - prawie pusty",
  },
  uk: {
    "cleaned_floor": "Підлогу прибрано",
    "cleaned_toilet": "Туалет прибрано",
    "cleaned_shower": "Душ прибрано",
    "cleaned_sink": "Раковину прибрано",
    "cleaned_mirror": "Дзеркало прибрано",
    "cleaned_stainless_steel": "Нержавіючу сталь очищено",
    "bad_smell": "Поганий запах",
    "dirty_floor": "Брудна підлога",
    "dirty_lavatory_seat": "Брудний унітаз",
    "dirty_shower": "Брудний душ",
    "dirty_sink": "Брудна раковина",
    "dirty_mirror": "Брудне дзеркало",
    "dirty_stainless_steel": "Брудна нержавіюча сталь",
    "missing_paper_towel": "Відсутній рушник",
    "missing_soap": "Відсутнє мило",
    "missing_toilet_paper": "Відсутній туалетний папір",
    "problem_shower": "Проблема з душем",
    "problem_sink": "Проблема з раковиною",
    "problem_mirror": "Проблема з дзеркалом",
    "problem_stainless_steel": "Проблема з нержавіючою сталлю",
    "problem_blockage": "Проблема з затором",
    "problem_door": "Проблема з дверима",
    "problem_fluid_taps": "Проблема з кранами",
    "problem_lightning": "Проблема з освітленням",
    "problem_broken_toilet": "Зламаний унітаз",
    "fixed_shower": "Душ відремонтовано",
    "fixed_sink": "Раковину відремонтовано",
    "fixed_mirror": "Дзеркало відремонтовано",
    "fixed_stainless_steel": "Нержавіючу сталь відремонтовано",
    "fixed_blockage": "Затор відремонтовано",
    "fixed_door": "Двері відремонтовано",
    "fixed_fluid_taps": "Крани відремонтовано",
    "fixed_lightning": "Освітлення відремонтовано",
    "fixed_broken_toilet": "Унітаз відремонтовано",
    "very_good": "Дуже добре",
    "excellent": "Відмінно",
    "good": "Добре",
    "like": "Подобається",
    "needs_improvement": "Потребує покращення",
    "not_good": "Не добре",
    "supervise": "Нагляд",
    "maintenance": "Обслуговування",
    "cleaner": "Прибиральник",
    "tag_cleaner": "Тег Сервіс",
    "zone_is_clean": "Зона чиста",
    "zone_is_dirty": "Зона брудна",
    "cleaner_arrived": "Прибиральник прибув",
    "start_clean_zone": "Почати прибирання зони",
    "counter": "Лічильник входів",
    "counter_men": "Лічильник входів / чоловіки",
    "counter_woman": "Лічильник входів / жіночий",
    "counter_handycap": "Лічильник входів / інвалідний",
    "full_garbage_can": "Повний смітник",
    "maintenance_fixed": "Обслуговування виконано",
    "maintenance_problem": "Проблема з обслуговуванням",
    "problem_soap_dispenser": "Проблема з диспенсером для мила",
    "problem_paper_dispenser": "Проблема з диспенсером для паперу",
    "end_clean_zone": "Завершити прибирання зони",
    "status_toilet_paper": "Стан туалетного паперу",
    "status_hend_paper": "Стан рушників",
    "fixed": "Виправлено",
    "status_soap": "Стан мила",
    "status_garbage_can": "Стан смітника",
    "general_cleaning": "Загальне прибирання",
    "coffee_tea": "Кава / Чай",
    "water_bar": "Водяний бар",
    "disposable_dishes": "Одноразова посуда",
    "head_coverage": "Покриття голови",
    "something_else": "Щось інше",
    "missing_clothing": "Відсутня одяг",
    "maintenance_link": "Посилання на обслуговування",
    "problem_maintenance": "Обслуговування",
    "supervise_link": "Посилання на нагляд",
    "cleaner_link": "Посилання на прибиральника",
    "room_men+link": "Кімната для чоловіків + посилання",
    "room_men-link": "Кімната для чоловіків - посилання",
    "room_women+link": "Кімната для жінок + посилання",
    "room_women-link": "Кімната для жінок - посилання",
    "hebrew": "Іврит",
    "español": "Іспанська",
    "français": "Французька",
    "english": "Англійська",
    "русский": "Російська",
    "italiano": "Італійська",
    "አማርኛ": "Амхарська",
    "português": "Португальська",
    "partial_information": "Часткова інформація",
    "professionalism": "Професіоналізм",
    "the_attitude_of_the_representative": "Ставлення представника",
    "waiting_time_for_service": "Час очікування на обслуговування",
    "other_aspect_of_service": "Інший аспект обслуговування",
    "language_of_the_service": "Мова обслуговування",
    "procedure_for_making_an_appointment": "Процедура запису на прийом",
    "physical_conditions": "Фізичні умови",
    "found_another": "Знайшов іншого",
    "found_similar": "Знайшов схожого",
    "did_not_find": "Не знайшов",
    "found": "Знайшов",
    "need_improved": "Потребує покращення",
    "liked": "Сподобалося",
    "pleasant": "Приємний",
    "eilat_resident": "Мешканець Ейлату",
    "once_a_week": "Раз на тиждень",
    "once_a_month": "Раз на місяць",
    "twice_a_year": "Два рази на рік",
    "once_a_year": "Раз на рік",
    "friend": "Друг",
    "newspaper": "Газета",
    "google": "Google",
    "zap": "Zap",
    "vacuum_cleaner": "Пилосос",
    "gaming_consoles": "Ігрові консолі",
    "laptop": "Ноутбук",
    "cellular": "Клітинний",
    "shop": "Магазин",
    "restaurant_complex_cleaning": "Прибирання ресторанного комплексу",
    "dining_room": "Столова",
    "food_variety": "Різноманіття їжі",
    "quality_of_the_food": "Якість їжі",
    "amount_of_the_food": "Кількість їжі",
    "quality_of_cooking": "Якість приготування",
    "side_courses": "Гарніри",
    "meat": "М'ясо",
    "desserts": "Десерти",
    "vegetarian": "Вегетаріанський",
    "salad_bar": "Бар салатів",
    "main_courses": "Основні страви",
    "service": "Обслуговування",
    "dining_room_cleanliness": "Чистота столової",
    "plates_and_cutlery_cleanliness": "Чистота тарілок і столових приборів",
    "product_inventory": "Інвентаризація продуктів",
    "furnishers": "Меблі",
    "toilets": "Туалети",
    "problem_coffee_machine": "Проблема з кавоваркою",
    "problem_water_bar": "Проблема з водяним баром",
    "dirty_stairs": "Брудні сходи",
    "toiletries": "Туалетні принади",
    "fixed_soap_dispenser": "Виправлено диспенсер мила",
    "fixed_paper_dispenser": "Виправлено диспенсер паперу",
    "fixed_coffee_machine": "Виправлено кавоварку",
    "fixed_water_bar": "Виправлено водяний бар",
    "problem_urinals": "Проблема з пісуарами",
    "blockage_toilet": "Забиті туалети",
    "appliance_problem": "Проблема з пристроями",
    "problem_broken_door": "Пошкоджені двері",
    "problem_door_lock": "Проблема з замком дверей",
    "problem_broken_toilet_seat": "Пошкоджений сидіння унітазу",
    "problem_broken_niagara": "Пошкоджена змивка",
    "broken_hand_paper_dispenser_hand_dryer": "Пошкоджений диспенсер для рук / сушарка для рук",
    "rug": "Килим",
    "tables": "Столи",
    "kitchen": "Кухня",
    "air_condition": "Кондиціонер",
    "dirty_urinals": "Брудні пісуари",
    "hand_dryer": "Сушарка для рук",
    "non_sticky_carpet": "Не липкий килим",
    "problem_bad_smell": "Поганий запах",
    "problem_broken_toilet_handle": "Пошкоджена ручка унітазу",
    "problem_handle_door": "Пошкоджена ручка",
    "call_cleaner": "Викликати прибиральника",
    "wall_painting": "Фарбування стін",
    "missing_paper": "Відсутній папір",
    "clean_surface": "Чиста поверхня",
    "problem_right_elevator": "Проблема з правим ліфтом",
    "problem_left_elevator": "Проблема з лівим ліфтом",
    "missing_hand_sanitizer": "Відсутній антисептик для рук",
    "problem_sink_blockage": "Забитий раковина",
    "electric_problem": "Проблема з електрикою",
    "missing_coffe_tea": "Відсутні кава / чай",
    "problem_air_condition": "Проблема з кондиціонером",
    "dishwashing": "Миття посуду",
    "kitchen_cleanliness": "Чистота кухні",
    "weekly_cleaning": "Тижневе прибирання",
    "general_arrangement": "Загальна організація",
    "meeting_room": "Кімната для зустрічей",
    "refrigerator": "Холодильник",
    "kitchen_supplies": "Кухонне приладдя",
    "toilet_services": "Сантехнічні послуги",
    "carousel_door": "Двері каруселі",
    "desk": "Стіл",
    "compressor": "Компресор",
    "compressor_rails": "Рейки компресора",
    "compressor_drainage": "Відведення компресора",
    "dust": "Пил",
    "door": "Двері",
    "scotch": "Скотч",
    "food": "Їжа",
    "room_conditions": "Умови номера",
    "kindness_sensitivity": "Люб'язність і чутливість",
    "explain_process": "Поясніть процес",
    "personal_attention": "Особиста увага",
    "service_problem": "Проблема з обслуговуванням",
    "welcome_you": "Ласкаво просимо вас",
    "invoice_submitted": "Рахунок надісланий",
    "thank_you_bought": "Дякуємо, що придбали",
    "problem_seller": "Проблема з продавцем",
    "lapel_pin": "Значок на запічці",
    "proper_performance": "Правильна робота",
    "name_tag": "Табличка з ім'ям",
    "operational": "Операційний",
    "app_revealed": "Програма розкрита",
    "register_load": "Зареєструйте навантаження",
    "visibility_problem": "Проблема з видимістю",
    "clean_storefront": "Чиста вітрина",
    "clean_garbage": "Чисте сміття",
    "clean_tables_chairs": "Чисті столи та стільці",
    "clean_shop_floor": "Чистий підлога магазину",
    "product_show": "Товар",
    "refrigerators_show": "Холодильники",
    "coffee_accessories": "Аксесуари для кави",
    "cleanliness": "Чистота",
    "convenience_scheduling_appointments": "Зручність у плануванні зустрічей",
    "waiting_haircut": "Чекання на стрижку",
    "courtesy_books": "Книги про ввічливість",
    "books_professionalism": "Книги про професіоналізм",
    "received_time": "Отриманий час",
    "dnot_appointment": "Немає зустрічі",
    "an_appointment": "Зустріч",
    "tasty": "Смачно",
    "courtesy_service": "Ввічливість обслуговування",
    "overall_experience": "Загальний досвід",
    "not_clean": "Не чисто",
    "diningroom_not_clean": "Брудна їдальня",
    "unclean_dishes": "Брудні посуд",
    "so-so": "Так собі",
    "not_tasty": "Не смачно",
    "kind": "Люб'язний",
    "not_kind": "Неввічливий",
    "clean": "Чисто",
    "hairdresser": "Перукар",
    "wrong_price": "Неправильна ціна",
    "produc_has_expired": "Товар прострочений",
    "missing_item": "Відсутня позиція",
    "item_is_faulty_in_the_machine": "Позиція пошкоджена в пристрої",
    "machine_cleaning": "Чищення пристрою",
    "improper_temperature": "Неправильна температура",
    "green": "Зелений",
    "orange": "Помаранчевий",
    "red": "Червоний",
    "yellow": "Жовтий",
    "red not prob": "Червоний не проблема",
    "professional": "Професійний",
    "unprofessional": "Непрофесійний",
    "delicacies": "Делікатеси",
    "sandwiches": "Сендвічі",
    "vegetables": "Овочі",
    "fruits": "Фрукти",
    "salads": "Салати",
    "milk": "Молоко",
    "room_link": "Посилання на кімнату",
    "floor_link": "Посилання на поверх",
    "services": "Послуги",
    "check_list": "Перевірочний список",
    "lighting_problem_lobby": "Проблема з освітленням у фойє",
    "lighting_problem_garbage_room": "Проблема з освітленням у сміттєзбірнику",
    "lighting_problem_stairwell": "Проблема з освітленням у сходовій клітці",
    "problem_elevator_1": "Проблема з ліфтом 1",
    "problem_elevator_2": "Проблема з ліфтом 2",
    "problem_elevator_3": "Проблема з ліфтом 3",
    "problem_elevator_4": "Проблема з ліфтом 4",
    "problem_elevator_5": "Проблема з ліфтом 5",
    "problem_elevator_6": "Проблема з ліфтом 6",
    "problem_elevator_7": "Проблема з ліфтом 7",
    "problem_elevator_8": "Проблема з ліфтом 8",
    "problem_elevator_9": "Проблема з ліфтом 9",
    "problem_elevator_10": "Проблема з ліфтом 10",
    "stuck_item": "Застрягнутий елемент",
    "spoiling_product": "Продукт псується",
    "machine_malfunction": "Поломка пристрою",
    "unreadable_product": "Нечитаний продукт",
    "i_am_satisfied": "Я задоволений",
    "beverage": "Напій",
    "program_malfunction": "Поломка програми",
    "machine_1": "Пристрій 1",
    "machine_2": "Пристрій 2",
    "machine_3": "Пристрій 3",
    "machine_4": "Пристрій 4",
    "program malfunction 1": "Поломка програми 1",
    "program malfunction 2": "Поломка програми 2",
    "program malfunction 3": "Поломка програми 3",
    "program malfunction 4": "Поломка програми 4",
    "fish": "Риба",
    "chicken": "Курка",
    "full_dining_room": "Повна їдальня",
    "missing_amount": "Відсутня сума",
    "missing_trays": "Відсутні підноси",
    "dirty_floor_g1": "Брудна підлога g1",
    "dirty_floor_g2": "Брудна підлога g2",
    "dirty_floor_g3": "Брудна підлога g3",
    "dirty_floor_g4": "Брудна підлога g4",
    "dirty_floor_g5": "Брудна підлога g5",
    "dirty_floor_eo": "Брудна підлога eo",
    "dirty_floor_ceo": "Брудна підлога ceo",
    "dirty_floor_storage": "Брудна підлога на складі",
    "dirty_floor_inflorescence": "Брудна підлога навколо квітів",
    "operating_instructions": "Інструкції з експлуатації",
    "general": "Загальне",
    "connection_problem": "Проблема з підключенням",
    "lighting": "Освітлення",
    "not _fixed": "Не виправлено",
    "cleaning_needed": "Потрібне прибирання",
    "missing_milk": "Відсутнє молоко",
    "no_sugar_coffee": "Немає цукру/кави",
    "missing_tools": "Відсутній посуд",
    "coffee_water_machine": "Кавова/водяна машина",
    "appliances": "Пристрої",
    "products": "Продукти",
    "broken_chair": "Пошкоджене крісло",
    "broken_cabinet": "Пошкоджений шафа",
    "micro": "Мікрохвильова піч",
    "toaster": "Тостер",
    "clogged_sink_malfunction": "Забитий умивальник",
    "coffee_cooling_malfunction": "Непрацюючий охолоджуючий кавовий апарат",
    "no_coffee_beans": "Немає кавових зерен",
    "machine_failure": "Поломка машини",
    "that_dirty": "Це брудне",
    "dirty_refrigerator": "Брудний холодильник",
    "dirty_tables": "Брудні столи",
    "plates": "Тарілки",
    "silver_spoon": "Срібна ложка",
    "cold_drinks_cups": "Келихи для холодних напоїв",
    "hot_drink_cups": "Келихи для гарячих напоїв",
    "a_dripping_faucet": "Крапаючий кран",
    "disposable dishes": "Одноразові посудини",
    "problem_ coffee_water_machine": "Проблема з кавовою/водяною машиною",
    "problem_micro": "Проблема з мікрохвильовою піччю",
    "problem_toaster": "Проблема з тостером",
    "problem_machine_failure": "Поломка машини",
    "problem refrigerator": "Проблема з холодильником",
    "no_sugar/coffee": "Немає цукру/кави",
    "missing_dishes": "Відсутні тарілки",
    "coffee/water_machine": "Кавова/водяна машина",
    "coffee_machine_door_open": "Відкриті двері кавоварки",
    "coffee_machine_door_close": "Закриті двері кавоварки",
    "coffee_machine_bin_full": "Бункер для відходів від кави заповнений",
    "coffee_machine_bin_empty": "Бункер для відходів від кави порожній",
    "coffee_machine_coffee_full": "Бункер для кавових зерен заповнений",
    "coffee_machine_coffee_empty": "Бункер для кавових зерен порожній",
    "coffee_machine_water_tank_empty": "Бак для води в кавоварці порожній",
    "coffee_machine_water_tank_full": "Бак для води в кавоварці заповнений",
    "coffee_machine_milk_cleaning_not_performed": "Прибирання молочного насоса в машині не проведено",
    "coffee_machine _milk_cleaning_performed": "Прибирання молочного насоса в машині проведено",
    "coffee_machine_head_cleaning_not_performed": "Прибирання головки машини не проведено",
    "coffee_machine_head_ cleaning_performed": "Прибирання головки машини проведено",
    "coffee_machine_descaling_not_performed": "Дескальція не проведена",
    "coffee_machine_descaling_performed": "Дескальція проведена",
    "floor_one": "Поверх 1",
    "floor_two": "Поверх 2",
    "floor_three": "Поверх 3",
    "floor_four": "Поверх 4",
    "floor_five": "Поверх 5",
    "floor_six": "Поверх 6",
    "floor_seven": "Поверх 7",
    "floor_eight": "Поверх 8",
    "floor_nine": "Поверх 9",
    "floor_ten": "Поверх 10",
    "floor_eleven": "Поверх 11",
    "floor_twelve": "Поверх 12",
    "floor_thirteen": "Поверх 13",
    "floor_fourteen": "Поверх 14",
    "floor_fifteen": "Поверх 15",
    "floor_sixteen": "Поверх 16",
    "floor_seventeen": "Поверх 17",
    "floor_eighteen": "Поверх 18",
    "floor_nineteen": "Поверх 19",
    "floor_twenty": "Поверх 20",
    "floor_twenty_one": "Поверх 21",
    "floor_twenty_two": "Поверх 22",
    "floor_twenty_three": "Поверх 23",
    "floor_twenty_four": "Поверх 24",
    "floor_twenty_five": "Поверх 25",
    "status_toilet_paper_cell_01_-_empty": "Статус туалетного паперу в кабіні 01 - порожній",
    "status_toilet_paper_cell_02_-_empty": "Статус туалетного паперу в кабіні 02 - порожній",
    "status_toilet_paper_cell_03_-_empty": "Статус туалетного паперу в кабіні 03 - порожній",
    "status_toilet_paper_cell_04_-_empty": "Статус туалетного паперу в кабіні 04 - порожній",
    "status_toilet_paper_cell_05_-_empty": "Статус туалетного паперу в кабіні 05 - порожній",
    "status_toilet_paper_cell_06_-_empty": "Статус туалетного паперу в кабіні 06 - порожній",
    "status_toilet_paper_cell_07_-_empty": "Статус туалетного паперу в кабіні 07 - порожній",
    "status_toilet_paper_cell_08_-_empty": "Статус туалетного паперу в кабіні 08 - порожній",
    "status_toilet_paper_cell_09_-_empty": "Статус туалетного паперу в кабіні 09 - порожній",
    "status_toilet_paper_cell_10_-_empty": "Статус туалетного паперу в кабіні 10 - порожній",
    "status_toilet_paper_cell_11_-_empty": "Статус туалетного паперу в кабіні 11 - порожній",
    "status_toilet_paper_cell_12_-_empty": "Статус туалетного паперу в кабіні 12 - порожній",
    "status_toilet_paper_cell_13_-_empty": "Статус туалетного паперу в кабіні 13 - порожній",
    "status_toilet_paper_cell_14_-_empty": "Статус туалетного паперу в кабіні 14 - порожній",
    "status_toilet_paper_cell_15_-_empty": "Статус туалетного паперу в кабіні 15 - порожній",
    "status_toilet_paper_cell_16_-_empty": "Статус туалетного паперу в кабіні 16 - порожній",
    "status_toilet_paper_cell_17_-_empty": "Статус туалетного паперу в кабіні 17 - порожній",
    "status_toilet_paper_cell_18_-_empty": "Статус туалетного паперу в кабіні 18 - порожній",
    "status_toilet_paper_cell_19_-_empty": "Статус туалетного паперу в кабіні 19 - порожній",
    "status_toilet_paper_cell_20_-_empty": "Статус туалетного паперу в кабіні 20 - порожній",
    "status_toilet_paper_cell_01_-_almost_empty": "Статус туалетного паперу в кабіні 01 - майже порожній",
    "status_toilet_paper_cell_02_-_almost_empty": "Статус туалетного паперу в кабіні 02 - майже порожній",
    "status_toilet_paper_cell_03_-_almost_empty": "Статус туалетного паперу в кабіні 03 - майже порожній",
    "status_toilet_paper_cell_04_-_almost_empty": "Статус туалетного паперу в кабіні 04 - майже порожній",
    "status_toilet_paper_cell_05_-_almost_empty": "Статус туалетного паперу в кабіні 05 - майже порожній",
    "status_toilet_paper_cell_06_-_almost_empty": "Статус туалетного паперу в кабіні 06 - майже порожній",
    "status_toilet_paper_cell_07_-_almost_empty": "Статус туалетного паперу в кабіні 07 - майже порожній",
    "status_toilet_paper_cell_08_-_almost_empty": "Статус туалетного паперу в кабіні 08 - майже порожній",
    "status_toilet_paper_cell_09_-_almost_empty": "Статус туалетного паперу в кабіні 09 - майже порожній",
    "status_toilet_paper_cell_10_-_almost_empty": "Статус туалетного паперу в кабіні 10 - майже порожній",
    "status_toilet_paper_cell_11_-_almost_empty": "Статус туалетного паперу в кабіні 11 - майже порожній",
    "status_toilet_paper_cell_12_-_almost_empty": "Статус туалетного паперу в кабіні 12 - майже порожній",
    "status_toilet_paper_cell_13_-_almost_empty": "Статус туалетного паперу в кабіні 13 - майже порожній",
    "status_toilet_paper_cell_14_-_almost_empty": "Статус туалетного паперу в кабіні 14 - майже порожній",
    "status_toilet_paper_cell_15_-_almost_empty": "Статус туалетного паперу в кабіні 15 - майже порожній",
    "status_toilet_paper_cell_16_-_almost_empty": "Статус туалетного паперу в кабіні 16 - майже порожній",
    "status_toilet_paper_cell_17_-_almost_empty": "Статус туалетного паперу в кабіні 17 - майже порожній",
    "status_toilet_paper_cell_18_-_almost_empty": "Статус туалетного паперу в кабіні 18 - майже порожній",
    "status_toilet_paper_cell_19_-_almost_empty": "Статус туалетного паперу в кабіні 19 - майже порожній",
    "status_toilet_paper_cell_20_-_almost_empty": "Статус туалетного паперу в кабіні 20 - майже порожній",
  },
  ro: {
    "cleaned_floor": "pardoseala curata",
    "cleaned_toilet": "toaleta curata",
    "cleaned_shower": "dus curat",
    "cleaned_sink": "chiuveta curata",
    "cleaned_mirror": "oglinda curata",
    "cleaned_stainless_steel": "otel inoxidabil curat",
    "bad_smell": "miros neplacut",
    "dirty_floor": "pardoseala murdara",
    "dirty_lavatory_seat": "scaun de toaleta murdar",
    "dirty_shower": "dus murdar",
    "dirty_sink": "chiuveta murdara",
    "dirty_mirror": "oglinda murdara",
    "dirty_stainless_steel": "otel inoxidabil murdar",
    "missing_paper_towel": "lipsa prosop de hartie",
    "missing_soap": "lipsa sapun",
    "missing_toilet_paper": "lipsa hartie igienica",
    "problem_shower": "problema dus",
    "problem_sink": "problema chiuveta",
    "problem_mirror": "problema oglinda",
    "problem_stainless_steel": "problema otel inoxidabil",
    "problem_blockage": "problema blocare",
    "problem_door": "problema usa",
    "problem_fluid_taps": "problema robineti",
    "problem_lightning": "problema iluminare",
    "problem_broken_toilet": "toaleta stricata",
    "fixed_shower": "dus reparat",
    "fixed_sink": "chiuveta reparata",
    "fixed_mirror": "oglinda reparata",
    "fixed_stainless_steel": "otel inoxidabil reparat",
    "fixed_blockage": "blocare reparata",
    "fixed_door": "usa reparata",
    "fixed_fluid_taps": "robineti reparati",
    "fixed_lightning": "iluminare reparata",
    "fixed_broken_toilet": "toaleta reparata",
    "very_good": "foarte bine",
    "excellent": "excelent",
    "good": "bine",
    "like": "imi place",
    "needs_improvement": "necesita imbunatatiri", 
    "not_good": "nu este bine",
    "supervise": "supraveghere",
    "maintenance": "intretinere",
    "cleaner": "curatenie",
    "tag_cleaner": "eticheta curatenie",
    "zone_is_clean": "zona este curata",
    "zone_is_dirty": "zona este murdara",
    "cleaner_arrived": "curatenie sosita",
    "start_clean_zone": "incepe curatenie zona",
    "counter": "ghiseu",
    "counter_men": "ghiseu barbati",
    "counter_woman": "ghiseu femei",
    "counter_handycap": "ghiseu handicapati",
    "full_garbage_can": "cos de gunoi plin",
    "maintenance_fixed": "intretinere reparata",
    "maintenance_problem": "problema intretinere",
    "problem_soap_dispenser": "problema dozator sapun",
    "problem_paper_dispenser": "problema dozator hartie",
    "end_clean_zone": "sfarsit curatenie zona",
    "status_toilet_paper": "status hartie igienica",
    "status_hend_paper": "status hartie maini",
    "fixed": "reparat",
    "status_soap": "status sapun",
    "status_garbage_can": "status cos de gunoi",
    "general_cleaning": "curatenie generala",
    "coffee_tea": "cafea/ceai",
    "water_bar": "bar apa",
    "disposable_dishes": "vase de unica folosinta",
    "head_coverage": "acoperire cap",
    "something_else": "altceva",
    "missing_clothing": "lipsa imbracaminte",
    "maintenance_link": "link intretinere",
    "problem_maintenance": "problema intretinere",
    "supervise_link": "link supraveghere",
    "cleaner_link": "link curatenie",
    "room_men+link": "camera barbati+link",
    "room_men-link": "camera barbati-link",
    "room_women+link": "camera femei+link",
    "room_women-link": "camera femei-link",
    "hebrew": "ebraica",
    "español": "spaniola",
    "français": "franceza",
    "english": "engleza",
    "русский": "rusa",
    "italiano": "italiana",
    "አማርኛ": "amharica",
    "português": "portugheza",
    "arabic": "araba",
    "partial_information": "informatii partiale",
    "professionalism": "profesionalism",
    "the_attitude_of_the_representative": "atitudinea reprezentantului",
    "waiting_time_for_service": "timpul de asteptare pentru serviciu",
    "other_aspect_of_service": "alt aspect al serviciului",
    "language_of_the_service": "limba serviciului",
    "procedure_for_making_an_appointment": "procedura de programare",
    "physical_conditions": "conditii fizice",
    "found_another": "gasit altul",
    "found_similar": "gasit similar",
    "did_not_find": "nu am gasit",
    "found": "gasit",
    "need_improved": "necesita imbunatatiri",
    "liked": "apreciat",
    "pleasant": "placut",
    "eilat_resident": "rezident eilat",
    "once_a_week": "o data pe saptamana",
    "once_a_month": "o data pe luna",
    "twice_a_year": "de doua ori pe an",
    "once_a_year": "o data pe an",
    "friend": "prieten",
    "newspaper": "ziar",
    "google": "google",
    "zap": "zap",
    "vacuum_cleaner": "aspirator",
    "gaming_consoles": "console de jocuri",
    "laptop": "laptop",
    "cellular": "celular",
    "shop": "magazin",
    "restaurant_complex_cleaning": "curatenie complex restaurant",
    "dining_room": "sala de mese",
    "food_variety": "varietatea mancarii",
    "quality_of_the_food": "calitatea mancarii",
    "amount_of_the_food": "cantitatea mancarii",
    "quality_of_cooking": "calitatea gatitului",
    "side_courses": "garnituri",
    "meat": "carne",
    "desserts": "deserturi",
    "vegetarian": "vegetarian",
    "salad_bar": "bar salate",
    "main_courses": "feluri principale",
    "service": "serviciu",
    "dining_room_cleanliness": "curatenie sala de mese",
    "plates_and_cutlery_cleanliness": "curatenie farfurii si tacamuri",
    "product_inventory": "inventar produse",
    "furnishers": "mobilier",
    "toilets": "toaleta",
    "problem_coffee_machine": "problema aparat de cafea",
    "problem_water_bar": "problema bar apa",
    "dirty_stairs": "scari murdare",
    "toiletries": "produse de toaleta",
    "fixed_soap_dispenser": "dozator sapun reparat",
    "fixed_paper_dispenser": "dozator hartie reparat",
    "fixed_coffee_machine": "aparat de cafea reparat",
    "fixed_water_bar": "bar apa reparat",
    "problem_urinals": "problema urinoare",
    "blockage_toilet": "blocaj toaleta",
    "appliance_problem": "problema aparat",
    "problem_broken_door": "problema usa stricata",
    "problem_door_lock": "problema incuietoare usa",
    "problem_broken_toilet_seat": "problema scaun toaleta stricat",
    "problem_broken_niagara": "problema niagara stricata",
    " broken_hand_paper_dispenser_hand_dryer": "dozator hartie maini stricat",
    "rug": "covor",
    "tables": "mese",
    "kitchen": "bucatarie",
    "air_condition": "aer conditionat",
    "dirty_urinals": "urinoare murdare",
    "hand_dryer": "uscator maini",
    "non_sticky_carpet": "covor non-lipicios",
    "problem_bad_smell": "miros neplacut",
    "problem_broken_toilet_handle": "problema maner toaleta stricat",
    "problem_handle_door": "problema maner usa",
    "call_cleaner": "apel curatenie",
    "wall_painting": "pictura perete",
    "missing_paper": "lipsa hartie",
    "clean_surface": "suprafata curata",
    "problem_right_elevator": "problema lift dreapta",
    "problem_left_elevator": "problema lift stanga",
    "missing_hand_sanitizer": "lipsa dezinfectant maini",
    "problem_sink_blockage": "problema blocare chiuveta",
    "electric_problem": "problema electrica",
    "missing_coffe_tea": "lipsa cafea/ceai",
    "problem_air_condition": "problema aer conditionat",
    "dishwashing": "spalat vase",
    "kitchen_cleanliness": "curatenie bucatarie",
    "weekly_cleaning": "curatenie saptamanala",
    "general_arrangement": "aranjament general",
    "meeting_room": "sala de intalniri",
    "refrigerator": "frigider",
    "kitchen_supplies": "provizii bucatarie",
    "toilet_services": "servicii toaleta",
    "carousel_door": "usa carusel",
    "desk": "birou",
    "compressor": "compresor",
    "compressor_rails": "sinuri compresor",
    "compressor_drainage": "drenaj compresor",
    "dust": "praf",
    "door": "usa",
    "scotch": "scotch",
    "food": "mancare",
    "room_conditions": "conditii camera",
    "kindness_sensitivity": "bunatate sensibilitate",
    "explain_process": "explica procesul",
    "personal_attention": "atentie personala",
    "service_problem": "problema serviciu",
    "welcome_you": "va ureaza bun venit",
    "invoice_submitted": "factura depusa",
    "thank_you_bought": "va multumim ca ati cumparat",
    "problem_seller": "problema vanzator",
    "lapel_pin": "ac de rever",
    "proper_performance": "performanta corecta",
    "name_tag": "eticheta nume",
    "operational": "operational",
    "app_revealed": "aplicatie dezvaluita",
    "register_load": "incarcare inregistrare",
    "visibility_problem": "problema vizibilitate",
    "clean_storefront": "fata de magazin curata",
    "clean_garbage": "gunoi curat",
    "clean_tables_chairs": "mese scaune curate",
    "clean_shop_floor": "podea magazin curata",
    "product_show": "produs expus",
    "refrigerators_show": "frigidere expuse",
    "coffee_accessories": "accesorii cafea",
    "cleanliness": "curatenie",
    "convenience_scheduling_appointments": "programare convenabila a programarilor",
    "waiting_haircut": "asteptare tuns",
    "courtesy_books": "carti de curtoazie",
    "books_professionalism": "profesionalism carti",
    "received_time": "timp primit",
    "dnot_appointment": "nu programare",
    "an_appointment": "o programare",
    "tasty": "gustos",
    "courtesy_service": "serviciu de curtoazie", 
    "overall_experience": "experienta generala",
    "not_clean": "nu este curat",
    "diningroom_not_clean": "sala de mese nu este curata",
    "unclean_dishes": "vase nespalate",
    "so-so": "asa si asa",
    "not_tasty": "nu este gustos",
    "kind": "amabil",
    "not_kind": "nu este amabil",
    "clean": "curat",
    "hairdresser": "coafor",
    "wrong_price": "pret gresit",
    "produc_has_expired": "produsul a expirat",
    "missing_item": "element lipsa",
    "item_is_faulty_in_the_machine": "elementul este defect in masina",
    "machine_cleaning": "curatare masina",
    "improper_temperature": "temperatura improprie",
    "green": "verde",
    "orange": "portocaliu",
    "red": "rosu",
    "yellow": "galben",
    "red not prob": "rosu nu este problema",
    "professional": "profesional",
    "unprofessional": "neprofesional",
    "delicacies": "delicatese",
    "sandwiches": "sandvisuri",
    "vegetables": "legume",
    "fruits": "fructe",
    "salads": "salate",
    "milk": "lapte",
    "room_link": "link camera",
    "floor_link": "link etaj",
    "services": "servicii",
    "check_list": "lista de verificare",
    "lighting_problem_lobby": "problema iluminare lobby",
    "lighting_problem_garbage_room": "problema iluminare camera gunoi",
    "lighting_problem_stairwell": "problema iluminare scara",
    "problem_elevator_1": "problema lift 1",
    "problem_elevator_2": "problema lift 2",
    "problem_elevator_3": "problema lift 3",
    "problem_elevator_4": "problema lift 4",
    "problem_elevator_5": "problema lift 5",
    "problem_elevator_6": "problema lift 6",
    "problem_elevator_7": "problema lift 7",
    "problem_elevator_8": "problema lift 8",
    "problem_elevator_9": "problema lift 9",
    "problem_elevator_10": "problema lift 10",
    "stuck_item": "element blocat",
    "spoiling_product": "produs stricat",
    "machine_malfunction": "defectiune masina",
    "unreadable_product": "produs ilegibil",
    "i_am_satisfied": "sunt multumit",
    "beverage": "bautura",
    "program_malfunction": "defectiune program",
    "machine_1": "masina 1",
    "machine_2": "masina 2",
    "machine_3": "masina 3",
    "machine_4": "masina 4",
    "program malfunction 1": "defectiune program 1",
    "program malfunction 2": "defectiune program 2",
    "program malfunction 3": "defectiune program 3",
    "program malfunction 4": "defectiune program 4",
    "fish": "peste",
    "chicken": "pui",
    "full_dining_room": "sala de mese plina",
    "missing_amount": "cantitate lipsa",
    "missing_trays": "tavi lipsa",
    "dirty_floor_g1": "pardoseala murdara g1",
    "dirty_floor_g2": "pardoseala murdara g2",
    "dirty_floor_g3": "pardoseala murdara g3",
    "dirty_floor_g4": "pardoseala murdara g4",
    "dirty_floor_g5": "pardoseala murdara g5",
    "dirty_floor_eo": "pardoseala murdara eo",
    "dirty_floor_ceo": "pardoseala murdara ceo",
    "dirty_floor_storage": "pardoseala murdara depozit",
    "dirty_floor_inflorescence": "pardoseala murdara inflorescenta",
    "operating_instructions": "instructiuni de functionare",
    "general": "general",
    "connection_problem": "problema de conectare",
    "lighting": "iluminare",
    "not_fixed": "nu este reparat",
    "cleaning_needed": "curatenie necesara",
    "missing_milk": "lipsa lapte",
    "no_sugar_coffee": "fara zahar/cafea",
    "missing_tools": "unelte lipsa",
    "coffee_water_machine": "aparat de cafea/apa",
    "appliances": "electrocasnice",
    "products": "produse",
    "broken_chair": "scaun stricat",
    "broken_cabinet": "cabinet stricat",
    "micro": "microunde",
    "toaster": "prajitor",
    "clogged_sink_malfunction": "chiuveta infundata",
    "coffee_cooling_malfunction": "racire cafea",
    "no_coffee_beans": "fara boabe de cafea",
    "machine_failure": "defectiune masina",
    "that_dirty": "acel murdar",
    "dirty_refrigerator": "frigider murdar",
    "dirty_tables": "mese murdare",
    "plates": "farfurii",
    "silver_spoon": "lingura de argint",
    "cold_drinks_cups": "pahare bauturi reci",
    "hot_drink_cups": "pahare bauturi calde",
    "a_dripping_faucet": "robinet picurand",
    "disposable dishes": "vase de unica folosinta",
    "problem_ coffee_water_machine": "problema aparat de cafea/apa",
    "problem_micro": "problema microunde",
    "problem_toaster": "problema prajitor",
    "problem_machine_failure": "problema defectiune masina",
    "problem refrigerator": "problema frigider",
    "no_sugar/coffee": "fara zahar/cafea",
    "missing_dishes": "vase lipsa",
    "coffee/water_machine": "aparat de cafea/apa",
    "coffee_machine_door_open": "usa aparat de cafea deschisa",
    "coffee_machine_door_close": "usa aparat de cafea inchisa",
    "coffee_machine_bin_full": "cos aparat de cafea plin",
    "coffee_machine_bin_empty": "cos aparat de cafea gol",
    "coffee_machine_coffee_full": "cafea aparat de cafea plina",
    "coffee_machine_coffee_empty": "cafea aparat de cafea goala",
    "coffee_machine_water_tank_empty": "rezervor apa aparat de cafea gol",
    "coffee_machine_ water_tank_full": "rezervor apa aparat de cafea plin",
    "coffee_machine_milk_cleaning_not_performed": "curatare lapte aparat de cafea neefectuata",
    "coffee_machine _milk_cleaning_performed": "curatare lapte aparat de cafea efectuata",
    "coffee_machine_head_cleaning_not_performed": "curatare cap aparat de cafea neefectuata",
    "coffee_machine_head_ cleaning_performed": "curatare cap aparat de cafea efectuata",
    "coffee_machine_descaling_not_performed": "descalcire aparat de cafea neefectuata",
    "coffee_machine_descaling_performed": "descalcire aparat de cafea efectuata",
    "floor_one": "etaj 1",
    "floor_two": "etaj 2",
    "floor_three": "etaj 3",
    "floor_four": "etaj 4",
    "floor_five": "etaj 5",
    "floor_six": "etaj 6",
    "floor_seven": "etaj 7",
    "floor_eight": "etaj 8",
    "floor_nine": "etaj 9",
    "floor_ten": "etaj 10",
    "floor_eleven": "etaj 11",
    "floor_twelve": "etaj 12",
    "floor_thirteen": "etaj 13",
    "floor_fourteen": "etaj 14",
    "floor_fifteen": "etaj 15",
    "floor_sixteen": "etaj 16",
    "floor_seventeen": "etaj 17",
    "floor_eighteen": "etaj 18",
    "floor_nineteen": "etaj 19",
    "floor_twenty": "etaj 20",
    "floor_twenty_one": "etaj 21",
    "floor_twenty_two": "etaj 22",
    "floor_twenty_three": "etaj 23",
    "floor_twenty_four": "etaj 24",
    "floor_twenty_five": "etaj 25",
    "status_toilet_paper_cell_01_-_empty": "status hartie igienica celula 01 - goala",
    "status_toilet_paper_cell_02_-_empty": "status hartie igienica celula 02 - goala",
    "status_toilet_paper_cell_03_-_empty": "status hartie igienica celula 03 - goala",
    "status_toilet_paper_cell_04_-_empty": "status hartie igienica celula 04 - goala",
    "status_toilet_paper_cell_05_-_empty": "status hartie igienica celula 05 - goala",
    "status_toilet_paper_cell_06_-_empty": "status hartie igienica celula 06 - goala",
    "status_toilet_paper_cell_07_-_empty": "status hartie igienica celula 07 - goala",
    "status_toilet_paper_cell_08_-_empty": "status hartie igienica celula 08 - goala",
    "status_toilet_paper_cell_09_-_empty": "status hartie igienica celula 09 - goala",
    "status_toilet_paper_cell_10_-_empty": "status hartie igienica celula 10 - goala",
    "status_toilet_paper_cell_11_-_empty": "status hartie igienica celula 11 - goala",
    "status_toilet_paper_cell_12_-_empty": "status hartie igienica celula 12 - goala",
    "status_toilet_paper_cell_13_-_empty": "status hartie igienica celula 13 - goala",
    "status_toilet_paper_cell_14_-_empty": "status hartie igienica celula 14 - goala",
    "status_toilet_paper_cell_15_-_empty": "status hartie igienica celula 15 - goala",
    "status_toilet_paper_cell_16_-_empty": "status hartie igienica celula 16 - goala",
    "status_toilet_paper_cell_17_-_empty": "status hartie igienica celula 17 - goala",
    "status_toilet_paper_cell_18_-_empty": "status hartie igienica celula 18 - goala",
    "status_toilet_paper_cell_19_-_empty": "status hartie igienica celula 19 - goala",
    "status_toilet_paper_cell_20_-_empty": "status hartie igienica celula 20 - goala",
    "status_toilet_paper_cell_01_-_almost_empty": "status hartie igienica celula 01 - aproape goala",
    "status_toilet_paper_cell_02_-_almost_empty": "status hartie igienica celula 02 - aproape goala",
    "status_toilet_paper_cell_03_-_almost_empty": "status hartie igienica celula 03 - aproape goala",
    "status_toilet_paper_cell_04_-_almost_empty": "status hartie igienica celula 04 - aproape goala",
    "status_toilet_paper_cell_05_-_almost_empty": "status hartie igienica celula 05 - aproape goala",
    "status_toilet_paper_cell_06_-_almost_empty": "status hartie igienica celula 06 - aproape goala",
    "status_toilet_paper_cell_07_-_almost_empty": "status hartie igienica celula 07 - aproape goala",
    "status_toilet_paper_cell_08_-_almost_empty": "status hartie igienica celula 08 - aproape goala",
    "status_toilet_paper_cell_09_-_almost_empty": "status hartie igienica celula 09 - aproape goala",
    "status_toilet_paper_cell_10_-_almost_empty": "status hartie igienica celula 10 - aproape goala",
    "status_toilet_paper_cell_11_-_almost_empty": "status hartie igienica celula 11 - aproape goala",
    "status_toilet_paper_cell_12_-_almost_empty": "status hartie igienica celula 12 - aproape goala",
    "status_toilet_paper_cell_13_-_almost_empty": "status hartie igienica celula 13 - aproape goala",
    "status_toilet_paper_cell_14_-_almost_empty": "status hartie igienica celula 14 - aproape goala",
    "status_toilet_paper_cell_15_-_almost_empty": "status hartie igienica celula 15 - aproape goala",
    "status_toilet_paper_cell_16_-_almost_empty": "status hartie igienica celula 16 - aproape goala",
    "status_toilet_paper_cell_17_-_almost_empty": "status hartie igienica celula 17 - aproape goala",
    "status_toilet_paper_cell_18_-_almost_empty": "status hartie igienica celula 18 - aproape goala",
    "status_toilet_paper_cell_19_-_almost_empty": "status hartie igienica celula 19 - aproape goala",
    "status_toilet_paper_cell_20_-_almost_empty": "status hartie igienica celula 20 - aproape goala",
  },
}

export const en = {
  ...serviks.en,
  "notified": "Notified",
  "supervisor_arrival": "Supervisor arrival",
  "arrival": "Arrival",
  "filter": "Filter",
  "logo": "Logo",
  "counter": "Counter",
  "sms": "Sms",
  "shifts": "Shifts",
  "call": "Call",
  "duration": "Duration",
  "time_start": "Time start",
  "settings": "Settings",
  "working_area": "Working area",
  "complaints_NC": "complaints / NC",
  "SLA_NC": "SLA / NC",
  "arrivals_NC": "Arrivals / NC",
  "staff": "Staff",
  "cleaning_complaints": "Cleaning complaints",
  "export": "Export",
  "maintenance_complaints": "Maintenance complaints",
  "maintenanceArrivals": "Maintenance arrivals",
  "maintenance_arrivals": "Maintenance arrivals",
  "last": "Last",
  "survey_name": "Survey name",
  "survey": "Survey",
  "pad_survey": "Pad survey",
  "pad_kitchen": "Pad kitchen",
  "number_of_assigned_devices": "# assigned devices",
  "number_of_pages": "# of pages",
  "date_created": "Date created",
  "updated_by": "Updated by",
  "date_updated": "Date updated",
  "search": "Search",
  "choose_type": "Choose type",
  "permitted_sites": "Permitted sites",
  "permitted_franchise": "Permitted franchise",
  "permitted_companies": "Permitted companies",
  "panel_permitted": "Panel permitted",
  "end_time": "End time",
  "last_update": "Last update",
  "add_device": "Add device",
  "detailed": "Detailed",
  "analysis": "Analysis",
  "suggestion": "Suggestion",
  "country": "Country",
  "management": "Management",
  "device": "Device",
  "mapping": "Mapping",
  "please_enter": "Please enter",
  "new_device": "New device",
  "assign_device": "Assign device",
  "installSpot": "Installation spot",
  "device_type": "Device type",
  "deviceCode": "Device code",
  "device_code": "Device code",
  "room": "Room",
  "edit_room": "Edit room",
  "edit_franchise": "Edit franchise",
  "new_franchise": "New franchise",
  "desc": "Description",
  "address": "Address",
  "level": "Level",
  "no_companies_to_show": "No companies to show",
  "company_type": "Company type",
  "region": "Region",
  "new_company": "New company",
  "edit_company": "Edit company",
  "edit_user": "Edit user",
  "C.arrivals": "Cleaner arrived",
  "C.complaints": "Cleaning complaint",
  "M.complaints": "Maintenance complaints",
  "M.Arrivals": "Maintenance arrivals",
  "analyses": "Analyses",
  "clean_log": "Clean log",
  "maintenance_log": "Maintenance log",
  "like": "Like",
  "new_user": "New user",
  "shift_parameters": "Shift parameter",
  "add_shift": "New shift",
  "edit_shift": "Edit shift",
  "username": "Username",
  "first_name": "First name",
  "firstName": "First name",
  "description": "Description",
  "last_name": "Last name",
  "lastName": "Last name",
  "phone": "Phone",
  "building_area": "Building area",
  "users": "Users",
  "add_user": "Add user",
  "sites": "Sites",
  "new_site": "New site",
  "edit_site": "Edit site",
  "add_company": "Add company",
  "franchise": "Franchise",
  "floor": "Floor",
  "building": "Building",
  "gender": "Gender",
  "display_name": "Name",
  "zone": "Zone",
  "shift_number": "Shift#",
  "shift_name": "Shift name",
  "end": "End",
  "min_sla": "Min SLA",
  "max_sla": "Max SLA",
  "days": "Days",
  "complaints_percent": "% Complaints",
  "sla_percent": "% SLA",
  "arrivals_percent": "% Arrivals",
  "save_arrivals": "Save",
  "company_name": "Company name",
  "site_name": "Site name",
  "site_desc": "Site description",
  "service_provider_name": "Service provider",
  "service_provider_type": "Service provider type",
  "service_provider_desc": "Service provider description",
  "user": "User",
  "map": "Map",
  "delete": "Delete",
  "new_room": "New room",
  "issue_times": "Issue times",
  "solved": "Solved",
  "visits": "Visits",
  "complaints": "Complaints",
  "cleaner_arrivals": "Cleaner arrivals",
  "response_time_SLA": "Response time",
  "score": "Score",
  "avg_score": "Avg score",
  "score_goal": "Score goal",
  "top_problem_issues": "Top issues",
  "top_problem_rooms": "Top rooms",
  "top_problem_hours": "Top hours",
  "is_time_save": "Time saved",
  "est_ROI": "Est ROI",
  "complaints_vs_visits": "Complaints vs Visits",
  "view_by_years": "View years",
  "view_by_months": "View months",
  "view_by_weeks": "View weeks",
  "view_by_days": "View days",
  "minutes": "Min",
  "company": "Company",
  "site": "Site",
  "alerts": "Devices info",
  "performance": "Performance",
  "devices": "Devices",
  "surveys": "Surveys",
  "real_time": "Real time",
  "real-time": "Real time",
  "admin": "Admin",
  "all": "All",
  "overview": "Overview",
  "analytics": "Analytics",
  "last_time_of_arrival": "Last time of arrival",
  "arrival_and_status": "Arrival & Status",
  "preview_arrivals": "Preview arrivals",
  "men toilet": "Men toilet",
  "women toilet": "Women toilet",
  "shared toilet": "Shared toilet",
  "handicapped toilet": "Handicapped toilet",
  monthMap: {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  },
  weekMap: {
    1: 'Sunday',
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday',
  },
  "dayOfWeek": "Days",
  "service_log": "Service log",
  "rooms": "Rooms",
  "displayName": "Room name",
  "SLA": "SLA",
  "supervisors": "Supervisors",
  "supervisor": "Supervisor",
  "performance_analytics": "Performance analytics",
  "arrivals": "Arrivals",
  "inspections": "Inspections",
  "avgResponseTime": "Avg response time",
  "missedSla": "Missed Sla",
  "cleaner_arrived": "Cleaner arrived",
  "load_more": "Load more",
  "login": "Login",
  "email": "Email",
  "emails": "Emails",
  "password": "Password",
  "min": "Min",
  "Logout": "Logout",
  "custom_time": "Choose dates",
  "last_day": "Last day",
  "last_week": "Last week",
  "last_month": "Last month",
  "last_quarter": "Last quarter",
  "last_6_months": "Last 6 months",
  "last_year": "Last Year",
  "data_by_hour": "Data by hour",
  "general": "General",
  "cleaning": "Cleaning",
  "maintenance": "Maintenance",
  "start": "Start",
  "start_time": "Start time",
  "management_co": "Management company",
  "cleaning_co": "Cleaning company",
  "companies": "Companies",
  "name": "Name",
  "site_count": "site #",
  "associated_companies": "Associated end clients",
  "OK": "OK",
  "cancel": "Cancel",
  "client": "Client",
  "dates": "Dates",
  "shift": "Shift",
  "issue": "Issue",
  "time": "Time",
  "top complaint of the week": "Top complaint of the week",
  "top room of the week": "Top room of the week",
  "top hour of the week": "Top hour of the week",
  "top complaint of the month": "Top complaint of the month",
  "top room of the month": "Top room of the month",
  "top hour of the month": "Top hour of the month",
  "franchises": "Franchises",
  "add_franchise": "Add franchise",
  "room_names": "Room name",
  "type": "Type",
  "please_select_company_site": "Please select a company & site",
  "number": "Number",
  "create_building_map": "Create building map",
  "hey quickest way to add floors and rooms to a buildings via this quick wizard": "Hey quickest way to add floors and rooms to a buildings via this quick wizard",
  "avg number of toilets per floor": "Avg number of toilets per floor",
  "avg.number of toilet cells per room": "Avg.number of toilet cells per room",
  "number of floors": "Number of floors",
  "avg.number of disabled toilets": "Avg.number of disabled toilets",
  "avg.number of soap dispensers": "Avg.number of soap dispensers",
  "reset_filter": "Reset filter",
  "add": "Add",
  "new_survey": "New survey",
  "api_configuration": "API configuration",
  "please_edit_the_api_information": "Please edit the API information",
  "method": "Method",
  "authorization": "Authorization",
  "api_url": "API_url",
  "token": "Token",
  "api_request_info": "API request info",
  "roomName": "Room name",
  "servikName": "Complaint name",
  "siteName": "Site name",
  "stage": "Stage",
  "number_of_buttons": "Number of buttons",
  "button_shape": "Button shape",
  "delay_time_stage": "Delay time stage",
  "go_to_stage": "Go to stage",
  "color": "Color",
  "add_stage": "Add stage",
  "save": "Save",
  "number_of_issues": "number of issues",
  "online": "Online",
  "offline": "Offline",
  "room_name": "Room name",
  "installation_spot": "Spot",
  "device_id": "Device id",
  "date": "Date",
  "status": "Status",
  "device_status": "Device status",
  "none": "none",
  "no_surveys_created": "No surveys created",
  "dashboard": "Dashboard",
  "franchise_name": "Franchise name",
  "franchise_country": "Franchise country",
  "choose_franchise": "Choose franchise",
  "choose_country": "Choose country",
  "select_template": "Select template",
  "default_survey": "Default survey",
  "cleaning_survey": "Cleaning survey",
  "kitchen_survey": "Kitchen survey",
  "template": "Template",
  "area_name": "Area name",
  "rooms_names": "Rooms names",
  "rooms_number": "Rooms #",
  "assign_number": "Assign #",
  "active_notification_task": "Active notification task",
  "amount_limit": "Counter interval",
  "after_max": "Counter after Max",
  "new_area": "New area",
  "tablet": "Tablet",
  "counter women": "Counter women",
  "counter men": "Counter man",
  "counter handicap": "Counter handicap",
  "counter man/woman": "Counter man / woman",
  "counter man / woman": "Counter man / woman",
  "sensor liquid": "Sensor liquid",
  "double toilet paper": "Double toilet paper",
  "toilet paper": "Toilet paper",
  "tag": "Tag",
  "qr tag": "QR Tag",
  "additional_info": "Additional info",
  "servikCategory": "Call type",
  "dishwashing": "Dishwashing",
  "cafeteria": "Cafeteria",
  "clean room": "Clean room",
  "office": "Office",
  "meeting room": "Meeting room",
  "cubic": "Cubic",
  "women": "Women",
  "men": "Men",
  "handicap": "Handicap",
  "Combined": "Combined",
  "kitchen": "Kitchen",
  "east": "East",
  "west": "West",
  "north": "North",
  "south": "South",
  "right": "Right",
  "left": "Left",
  "center": "Center",
  "up": "Up",
  "down": "Down",
  "combined": "Combined",
  "lobby": "Lobby",
  "entrance": "Entrance",
  "reception": "Reception",
  "area": "Area",
  "mezzanine": "Mezzanine",
  "gallery": "Gallery",
  "ground": "Ground",
  "elevator": "Elevator",
  "is_calculated": "Calculated room",
  "calculated_room": "Calculated room",
  "splitted_survey": "Splitted survey",
  "redirect_to_url": "Redirect to url",
  "survey_site": "Survey site",
  "clicks": "Clicks",
  "add_stage_number_to_signal": "Attach stage number",
  "including_pad9": "Including pad9",
  "default_name": "Name in english",
  "exposures": "Exposures",
  "expose_results": "Expose results",
  "events_expose": "Event & expose",
  "expose_score": "Expose score",
  "shift_events_expose": "Shift events & expose",
  "type_of_site": "Type of site",
  "morning_shift": "Morning shift",
  "afternoon_shift": "Afternoon shift",
  "night_shift": "Night shift",
  "update": "Update",
  "restart": "Restart",
  "send_data": "Send data",
  "send_count": "Send count",
  "blink": "Blink",
  "maintenance_btn": "Maintenance button",
  "battery": "Battery",
  "version": "Version",
  "actions": "Actions",
  "drains_battery": "Drains battery",
  "latest_errors": "Latest errors",
  "no_errors": "No errors",
  "offline_calls": "Offline calls",
  "offline_duration_calls": "Offline duration calls",
  "counterCalls": "Counter calls",
  "active": "Active",
  "active_calculation": "Active calculation",
  "pad": "Pad",
  "pad_counter": "Pad counter",
  "counter_status": "Counter status",
  "choose_state": "Choose state",
  "choose_city": "Choose city",
  "start_hour": "Start hour",
  "end_hour": "End Hour",
  "analytics_log": "Analytics log",
  "or": "or",
  "no_data_available": "No data available",
  "new_task": "New task",
  "category": "Category",
  "title": "Title",
  "task_type": "Task type",
  "task_category": "Task category",
  "call_to_action": "Call to action",
  "schedule": "Schedule",
  "insert_text": "Insert text",
  "select": "Select",
  "schedule_dates": "Schedule dates",
  "language": "Language",
  "calendar": "Calendar",
  "operation": "Operation",
  "daily": "Daily",
  "weekly": "Weekly",
  "monthly": "Monthly",
  "quarterly": "Quarterly",
  "yearly": "Yearly",
  "sunday": "Sunday",
  "monday": "Monday",
  "tuesday": "Tuesday",
  "wednesday": "Wednesday",
  "thursday": "Thursday",
  "friday": "Friday",
  "saturday": "Saturday",
  "january": "January",
  "february": "February",
  "march": "March",
  "april": "April",
  "may": "May",
  "june": "June",
  "july": "July",
  "august": "August",
  "september": "September",
  "october": "October",
  "november": "November",
  "december": "December",
  "1st week": "1st Week",
  "2nd week": "2nd Week",
  "3rd week": "3rd Week",
  "4th week": "4th Week",
  "5th week": "5th Week",
  "1st quarter": "1st Quarter",
  "2nd quarter": "2nd Quarter",
  "3rd quarter": "3rd Quarter",
  "4th quarter": "4th Quarter",
  "edit_task": "Edit task",
  "created_at": "Created at",
  "hour": "Hour",
  "week_days": "Week days",
  "month_weeks": "Month weeks",
  "month_days": "Month days",
  "no_tasks_found": "No tasks found",
  "invalid_language": "Invalid language",
  "no_active_tasks_found": "No active tasks found",
  "edit": "Edit",
  "send_date": "Send date",
  "invalid_date": "Invalid date",
  "cells_num": "Cells #",
  "sinks_num": "Sinks #",
  "urinals_num": "Urinals #",
  "toilet_paper_size": "Toilet paper size",
  "email_configuration": "Email configuration",
  "please_edit_the_email_information": "Please edit the email information",
  "email_sender_info": "Email sender info",
  "email_subject_info": "Email subject info",
  "email_message_info": "Email message info",
  "companyName": "Company name",
  "email_language": "Email language",
  "complaint_language": "Complaint language",
  "email_message_format": "Email message format",
  "line": "Line",
  "list": "List",
  "roomType": "Room type",
  "By logging in, you agree to Buzzztech's": "By logging in, you agree to Buzzztech's",
  "Terms and Conditions": "Terms and Conditions",
  "and": "And",
  "Privacy Policy": "Privacy Policy",
  "All actions within this panel are recorded for quality control and improvement": "All actions within this panel are recorded for quality control and improvement",
  "You must agree to the terms and conditions": "You must agree to the terms and conditions",
  "months": "Months",
  "ref_id": "Reference id",
  "room_name_en": "Room name in english",
  "site_state": "Site state",
  "site_city": "Site city",
  "city": "City",
  "state": "State",
  "site_country": "Site country",
  "first_date": "First date",
  "pinned": "Pinned",
  "no.": "No.",
  "must_be_numbers": "Must be numbers",
  "must_be_3_digits": "Must be 3 digits",
  "must_have_english_title": "Must have english title",
  "total_sites": "Total",
  "site_information": "Site information",
  "holiday_offdays": "Holidays & Days-Off",
  "add_offday": "Day-Off",
  "add_holiday": "Holiday",
  "name_under_chars": "Name under 25 characters",
  "days_comma_separated": "Valid days, separated by a comma",
  "year": "Year",
  "month": "Month",
  "day": "Day",
  "holiday_name": "Holiday name",
  "offday_name": "Day-off name",
  "israel": "Israel",
  "USA": "USA",
  "poland": "Poland",
  "mexico": "Mexico",
  "cyprus": "Cyprus",
  "brazil": "Brazil",
  "tablet_survey": "Tablet survey",
  "no_shift_survey": "No Shift survey",
  "number_of_rows": "Number of rows",
  "rating_shape": "Rating shape",
  "content_survey": "Content survey",
  "rating_survey": "Rating survey",
  "device-alerts": "Problem",
  "device-active": "Active",
  "device-search": "Search",
  "mac_id": "MAC address",
  "qr_tag": "QR tag",
  "sensor": "Sensor",
  "image_survey": "Image survey",
  "open_text": "Open text",
  "open_text_survey": "Open text survey",
  "embedded_link": "Embedded link",
  "device-counter": "Counter",
  "count": "Count",
  "last_date": "Last date",
  "last_arrival":"Last cleaner arrival",
  "cleaners": "Cleaners",
  "viewer": "Viewer",
  "android": "Android",
  "ios": "iOS",
  "no_users_found": "No users found",
  "no_cleaners_found": "No cleaners found",
  "new_cleaner": "New cleaner",
  "edit_cleaner": "Edit cleaner",
  "please_select": "Please select",
  "working_areas": "Working areas",
  "isWorkingInSite": "Working in site",
  "you_are_not_allowed_to_edit": "You are not allowed to edit",
  "you_are_not_allowed_to_remove": "You are not allowed to remove",
  "task": "Task",
  "portuguese": "Portuguese",
  "russian": "Russian",
  "spanish": "Spanish",
  "polish": "Polish",
  "installer": "Installer",
  "once": "Once",
  "time_saved": "Time Saved",
  "yes": "Yes",
  "no": "No",
  "workingAreaNames": "Working area names",
  "shift_rooms":"Rooms",
  "new_shift":"New shift",
  "add_shift_inactive": "Add reflective shifts",
  "remove_auto_shift_inactive": "Remove automated reflective shifts",
  "additional_options": "Additional options",
  "add_default_shifts": "Add default shifts",
  "no_user_found": "No user found",
  "no_site_found": "No site found",
  "service_provider": "Service provider",
  "qr task": "QR task",
  "qr_task": "QR task",
  "all_day": "All day",
  "auto_start": "Auto start",
  "english_title": "English title",
  "connectivity": "Connectivity",
  "power": "Power",
  "Your work has been saved": "Your work has been saved",
  "schedule_call_time": "Schedule call time",
  "schedule_call_gap": "Schedule call gap",
  "gapInMinutes": "Gap in minutes",
  "no_manual_calls_available": "No manual calls available",
  "manual_calls": "Manual calls",
  "cleandexCalls": "Cleandex calls",
  "manualCalls": "Manual calls",
  "inactive": "Inactive",
  "on hold": "On hold",
  "sequential": "Sequential",
  "ratio": "Ratio",
  "must_choose": "Must choose",
  "surveyApp": "Survey app",
  "installation": "Installation",
  "manager": "Manager",
}

export const he = {
  ...serviks.he,
  "notified": "התראה",
  "supervisor_arrival": "הגעת מפקח",
  "arrival": "הגעה",
  "filter": "סנן",
  "logo": "לוגו",
  "counter": "מונה",
  "sms": "סמס",
  "shifts": "משמרות",
  "call": "קריאה",
  "duration": "זמן מפתיחה",
  "time_start": "זמן התחלה",
  "settings": "הגדרות",
  "working_area": "איזור עבודה",
  "complaints_NC": "קריאות ללא מונה",
  "SLA_NC": "SLA ללא מונה",
  "arrivals_NC": "הגעות ללא מונה",
  "staff": "צוות",
  "cleaning_complaints": "קריאות ניקיון",
  "export": "ייצוא",
  "maintenance_complaints": "קריאות אחזקה",
  "maintenanceArrivals": "תיקוני אחזקה",
  "maintenance_arrivals": "הגעות אחזקה",
  "last": "אחרון",
  "survey_name": "סקר",
  "survey": "שם הסקר",
  "pad_survey": "סקר לפאד",
  "pad_kitchen": "סקר פאד מטבח",
  "number_of_assigned_devices": "מספר מכשירים",
  "number_of_pages": "מספר דפים",
  "date_created": "תאריך יצירה",
  "updated_by": "עודכן ע״י",
  "date_updated": "תאריך עידכון",
  "search": "חיפוש",
  "choose_type": "בחר סוג",
  "permitted_sites": "הרשאות לאתרים",
  "permitted_franchise": "הרשאה לזכיינים",
  "permitted_companies": "הרשאה לחברות",
  "panel_permitted": "הרשאה לפאנל",
  "end_time": "זמן סיום",
  "last_update": "עדכון אחרון",
  "add_device": "הוסף מכשיר",
  "detailed": "פירוט",
  "analysis": "ניתוח",
  "suggestion": "הצעות",
  "country": "מדינה",
  "management": "ניהול",
  "device": "מכשירים",
  "mapping": "מיפוי",
  "please_enter": "הכנס/י בבקשה",
  "new_device": "מכשיר חדש",
  "assign_device": "חבר מכשיר",
  "installSpot": "מקום התקנה",
  "device_type": "סוג מכשיר",
  "deviceCode": "קוד מכשיר",
  "device_code": "קוד מכשיר",
  "room": "חדר",
  "edit_room": "ערוך חדר",
  "edit_franchise": "ערוך זכיין",
  "new_franchise": "הוסף זכיין",
  "desc": "תיאור",
  "address": "כתובת",
  "level": "רמה",
  "no_companies_to_show": "אין חברות לתצוגה",
  "company_type": "סוג החברה",
  "region": "איזור",
  "new_company": "חברה חדשה",
  "edit_company": "ערוך חברה",
  "edit_user": "ערוך משתמש",
  "C.arrivals": "ביקורי מנקים",
  "C.complaints": "קריאות ניקיון",
  "M.complaints": "קריאות תחזוקה",
  "M.Arrivals": "ביקורי אחזקה",
  "analyses": "ניתוח ללא חדרים",
  "clean_log": "יומן נקיונות",
  "maintenance_log": "יומן תחזוקה",
  "like": "לייק",
  "new_user": "משתמש חדש",
  "shift_parameters": "מאפייני משמרת",
  "add_shift": "משמרת חדשה",
  "edit_shift": "ערוך משמרת",
  "username": "שם משתמש",
  "first_name": "שם פרטי",
  "firstName": "שם פרטי",
  "description": "תיאור",
  "last_name": "שם משפחה",
  "lastName": "שם משפחה",
  "phone": "טלפון",
  "building_area": "איזור בניין",
  "users": "משתמשים",
  "add_user": "הוסף משתמש",
  "sites": "אתרים",
  "new_site": "אתר חדש",
  "edit_site": "ערוך אתר",
  "add_company": "הוסף חברה",
  "franchise": "זכיינות",
  "floor": "קומה",
  "building": "בניין",
  "gender": "מגדר",
  "display_name": "שם",
  "zone": "אזור",
  "shift_number": "מספר משמרת",
  "shift_name": "שם משמרת",
  "end": "סוף",
  "min_sla": "מינ׳ זמן תגובה",
  "max_sla": "מקס׳ זמן תגובה",
  "days": "ימים",
  "complaints_percent": "קריאות %",
  "sla_percent": "זמן תגובה %",
  "arrivals_percent": "% הגעות מנקה",
  "save_arrivals": "חיסכון",
  "company_name": "שם החברה",
  "site_name": "שם האתר",
  "site_desc": "תיאור האתר",
  "service_provider_name": "ספק שירות",
  "service_provider_type": "סוג ספק שירות",
  "service_provider_desc": "תיאור ספק שירות",
  "user": "משתמש",
  "map": "מפה",
  "delete": "מחיקה",
  "new_room": "חדר חדש",
  "issue_times": "קריאות וסגירות",
  "solved": "נפתר",
  "visits": "ביקורים",
  "complaints": "קריאות",
  "cleaner_arrivals": "הגעות מנקה",
  "response_time_SLA": "זמן תגובה",
  "score": "ציון",
  "avg_score": "ציון ממוצע",
  "score_goal": "ציון רצוי",
  "top_problem_issues": "קריאות עיקריות",
  "top_problem_rooms": "חדרים עיקריים",
  "top_problem_hours": "שעות עיקריות",
  "is_time_save": "זמן שנחסך",
  "est_ROI": "החזרת השקעה משוערת",
  "complaints_vs_visits": "קריאות מול ביקורים",
  "view_by_years": "צפה  בשנים",
  "view_by_months": "צפה בחודשים",
  "view_by_weeks": "צפה בשבועות",
  "view_by_days": "צפה בימים",
  "minutes": "דקות",
  "company": "חברה",
  "site": "אתר",
  "alerts": "מידע ממכשירים",
  "performance": "ביצועים",
  "devices": "מכשירים",
  "surveys": "סקרים",
  "real_time": "זמן אמת",
  "real-time": "זמן אמת",
  "admin": "מנהל",
  "all": "הכל",
  "overview": "סקירה כללית",
  "analytics": "ניתוח",
  "dayOfWeek": "ימים",
  "service_log": "פירוט החדר",
  "rooms": "חדרים",
  "displayName": "שם החדר",
  "SLA": "SLA",
  "supervisors": "מפקחים",
  "supervisor": "מפקח",
  "performance_analytics": "סקירת החדר",
  "arrivals": "מנקה",
  "inspections": "ביקורות",
  "avgResponseTime": "זמן תגובה ממוצע",
  "missedSla": "איחורים",
  "cleaner_arrived": "מנקה הגיע/ה",
  "load_more": "טען עוד",
  "login": "התחבר/י",
  "email": "דואל",
  "emails": "כתובות דואל",
  "password": "סיסמא",
  "min": "דק׳",
  "Logout": "התנתק",
  "custom_time": "הגדר תאריך",
  "last_day": "יום אחרון",
  "last_week": "שבוע אחרון",
  "last_month": "חודש אחרון",
  "last_quarter": "רבעון אחרון",
  "last_6_months": "חצי שנה אחרונה,",
  "last_year": "שנה אחרונה",
  "data_by_hour": "נתונים לפי שעה",
  "general": "כללי",
  "cleaning": "ניקיון",
  "maintenance": "תחזוקה",
  "start": "פתיחה",
  "start_time": "זמן התחלה",
  "management_co": "חב ניהול,",
  "cleaning_co": "חב ניקיון,",
  "companies": "חברות,",
  "name": "שם",
  "site_count": "אתרים #",
  "associated_companies": "חברות מקושרות",
  "OK": "אשר",
  "cancel": "בטל",
  "client": "לקוח",
  "dates": "תאריכים",
  "shift": "משמרת",
  "issue": "בעיה",
  "time": "זמן",
  "top complaint of the week": "הקריאה התדירה ביותר השבוע",
  "top room of the week": "החדר הבעייתי ביותר השבוע",
  "top hour of the week": "השעה הבעייתית ביותר השבוע",
  "top complaint of the month": "הקריאה התדירה ביותר החודש",
  "top room of the month": "החדר הבעייתי ביותר החודש",
  "top hour of the month": "השעה הבעייתית ביותר החודש",
  "franchises": "זכייניות",
  "add_franchise": "הוסף זכיינית",
  "room_names": "שם החדר",
  "type": "סוג",
  "please_select_company_site": "אנא בחר חברה ואתר",
  "number": "מספר",
  "create_building_map": "צור מיפוי לבניין",
  "hey quickest way to add floors and rooms to a buildings via this quick wizard": "היי, הדרך המהירה ביותר לייצר חדרים, קומות ובניינים היא בעזרת האשף המהיר הבא",
  "avg number of toilets per floor": "ממוצע חדרי שירותים בקומה",
  "avg.number of toilet cells per room": "ממוצע תאי שירותים בחדר",
  "number of floors": "מספר הקומות",
  "avg.number of disabled toilets": "ממוצע שירותי לנכים בקומה",
  "avg.number of soap dispensers": "ממוצע מתקני סבון בחדר",
  "reset_filter": "נקה סינון",
  "add": "הוסף",
  "new_survey": "סקר חדש",
  "api_configuration": "הגדרות API",
  "please_edit_the_api_information": "ערוך פרטי API",
  "method": "סוג בקשה",
  "authorization": "הרשאה",
  "api_url": "כתובת בקשה",
  "token": "מפתח",
  "api_request_info": "מידע לשליחה",
  "roomName": "שם חדר",
  "servikName": "שם קריאה",
  "siteName": "שם אתר",
  "stage": "עמוד",
  "number_of_buttons": "מספר כפתורים",
  "button_shape": "צורת כפתור",
  "delay_time_stage": "זמן המתנה",
  "go_to_stage": "עבור לעמוד",
  "color": "צבע",
  "add_stage": "הוסף עמוד",
  "save": "שמור סקר",
  "number_of_issues": "מספר קריאות",
  "online": "פעיל",
  "offline": "לא פעיל",
  "room_name": "שם חדר",
  "installation_spot": "מקום",
  "device_id": "מזהה מכשיר",
  "date": "תאריך",
  "status": "סטאטוס",
  "device_status": "סטאטוס מכשיר",
  "none": "אין",
  "no_surveys_created": "אין סקרים",
  "dashboard": "לוח בקרה",
  "franchise_name": "שם זכיין",
  "franchise_country": "מדינת זכיין",
  "choose_franchise": "בחר זכיין",
  "choose_country": "בחר מדינה",
  "select_template": "בחר תבנית",
  "default_survey": "סקר ברירת מחדל",
  "cleaning_survey": "סקר נקיון",
  "kitchen_survey": "סקר מטבח",
  "template": "תבנית",
  "area_name": "שם חלוקה",
  "rooms_names": "שמות חדרים",
  "rooms_number": "חדרים #",
  "assign_number": "אחראי #",
  "active_notification_task": "התראות פעילות",
  "amount_limit": "גבול ספירה",
  "after_max": "גבול התראה",
  "new_area": "איזור עבודה חדש",
  "tablet": "טאבלט",
  "counter women": "קאונטר נשים",
  "counter men": "קאונטר גברים",
  "counter handicap": "קאונטר נכים",
  "counter man/woman": "קאונטר משולב",
  "counter man / woman": "קאונטר משולב",
  "sensor liquid": "חיישן נוזלים",
  "double toilet paper": "חיישן נייר כפול",
  "toilet paper": "חיישן נייר",
  "'counter'": "קאונטר",
  "tag": "טאג",
  "qr tag": "קוד Qr",
  "additional_info": "מידע נוסף לשליחה",
  "servikCategory": "סוג קריאה",
  "dishwashing": "מדיח כלים",
  "cafeteria": "קפיטריה",
  "clean room": "חדר נקי",
  "office": "משרד",
  "meeting room": "חדר ישיבות",
  "cubic": "קיוביק",
  "women": "נשים",
  "men": "גברים",
  "handicap": "נכים",
  "Combined": "משולב",
  "kitchen": "מטבח",
  "east": "מזרח",
  "west": "מערב",
  "north": "צפון",
  "south": "דרום",
  "right": "ימין",
  "left": "שמאל",
  "center": "מרכז",
  "up": "למעלה",
  "down": "למטה",
  "combined": "משולב",
  "lobby": "לובי",
  "entrance": "כניסה",
  "reception": "קבלה",
  "area": "אזור",
  "mezzanine": "מזאני",
  "gallery": "גלריה",
  "ground": "קרקע",
  "elevator": "מעליות",
  "is_calculated": "חישוב חדר",
  "calculated_room": "חישוב חדר",
  "splitted_survey": "סקר מפוצל",
  "redirect_to_url": "הפנה לכתובת",
  "survey_site": "אתר סקר",
  "clicks": "לחיצות",
  "add_stage_number_to_signal": "צרף מספר סקר",
  "including_pad9": "כולל פאד",
  "default_name": "שם באנגלית",
  "exposures": "חשיפות",
  "expose_results": "מספר חשיפות",
  "events_expose": "אירועים וחשיפות",
  "expose_score": "ציון חשיפות",
  "shift_events_expose": "משמרות חשיפות ואירועים",
  "type_of_site": "סוג האתר",
  "sector": "מגזָר",
  "last_time_of_arrival": "זמן הגעה אחרון",
  "arrival_and_status": "הגעה & מצב",
  "preview_arrivals": "תצוגה מקדימה של כניסות",
  "men toilet": "שירותי גברים",
  "women toilet": "שירותי נשים",
  "shared toilet": "שירותים משולבים",
  "handicapped toilet": "שירותי נכים",
  "morning_shift": "משמרת בוקר",
  "afternoon_shift": "משמרת אחר הצהריים",
  "night_shift": "משמרת לילה",
  "update": "עדכון",
  "restart": "הפעל מחדש",
  "send_data": "שלח נתונים",
  "send_count": "שלח קאונט",
  "blink": "להבהב",
  "maintenance_btn": "כפתור תחזוקה",
  "battery": "סוללולה",
  "version": "גרסה",
  "actions": "פעולות",
  "drains_battery": "מרוקן את הסוללה",
  "latest_errors": "שגיאות אחרונות",
  "no_errors": "אין שגיאות",
  "offline_calls": "התראות אופליין",
  "offline_duration_calls": "משך התראות אופליין",
  "counterCalls": "התראות קאונטר",
  "active": "פעיל",
  "active_calculation": "חישוב פעיל",
  "pad": "פאד",
  "pad_counter": "פאד קאונטר",
  "counter_status": "סטאטוס קאונטר",
  "choose_state": "בחר מחוז",
  "choose_city": "בחר עיר",
  "start_hour": "שעת התחלה",
  "end_hour": "שעת סיום",
  "analytics_log": "לוג ניתוח",
  "or": "או",
  "no_data_available": "אין נתונים",
  "new_task": "משימה חדשה",
  "category": "קטגוריה",
  "title": "כותרת",
  "task_type": "סוג משימה",
  "task_category": "קטגוריה משימה",
  "call_to_action": "קרא לפעולה",
  "schedule": "לוח זמנים",
  "insert_text": "הכנס טקסט",
  "select": "בחר",
  "schedule_dates": "תאריכי לוח זמנים",
  "language": "שפה",
  "calendar": "לוח שנה",
  "operation": "פעולה",
  "daily": "יומי",
  "weekly": "שבועי",
  "monthly": "חודשי",
  "quarterly": "רבעוני",
  "yearly": "שנתי",
  "sunday": "ראשון",
  "monday": "שני",
  "tuesday": "שלישי",
  "wednesday": "רביעי",
  "thursday": "חמישי",
  "friday": "שישי",
  "saturday": "שבת",
  "january": "ינואר",
  "february": "פברואר",
  "march": "מרץ",
  "april": "אפריל",
  "may": "מאי",
  "june": "יוני",
  "july": "יולי",
  "august": "אוגוסט",
  "september": "ספטמבר",
  "october": "אוקטובר",
  "november": "נובמבר",
  "december": "דצמבר",
  "1st week": "שבוע 1",
  "2nd week": "שבוע 2",
  "3rd week": "שבוע 3",
  "4th week": "שבוע 4",
  "5th week": "שבוע 5",
  "1st quarter": "רבעון 1",
  "2nd quarter": "רבעון 2",
  "3rd quarter": "רבעון 3",
  "4th quarter": "רבעון 4",
  "edit_task": "ערוך משימה",
  "created_at": "נוצר ב",
  "hour": "שעה",
  "week_days": "ימי השבוע",
  "month_weeks": "שבועות בחודש",
  "month_days": "ימים בחודש",
  "no_tasks_found": "לא נמצאו משימות",
  "invalid_language": "שפה לא חוקית",
  "no_active_tasks_found": "לא נמצאו משימות פעילות",
  "edit": "ערוך",
  "send_date": "תאריך שליחה",
  "invalid_date": "תאריך לא חוקי",
  "cells_num": "# תאים",
  "sinks_num": "# כיורים",
  "urinals_num": "# משתנות",
  "toilet_paper_size": "גודל ניירות כלים",
  "email_configuration": "הגדרות דואר אלקטרוני",
  "please_edit_the_email_information": "נא לערוך את פרטי הדואר האלקטרוני",
  "email_sender_info": "פרטי שולח הדואר האלקטרוני",
  "email_subject_info": "פרטי נושא הדואר האלקטרוני",
  "email_message_info": "פרטי הודעת הדואר האלקטרוני",
  "companyName": "שם החברה",
  "email_language": "שפת הדואר האלקטרוני",
  "complaint_language": "שפת התלונה",
  "email_message_format": "פורמט הודעת הדואר האלקטרוני",
  "line": "שורה",
  "list": "רשימה",
  "roomType": "סוג חדר",
  "By logging in, you agree to Buzzztech's": "בהתחברות, אתה מסכים לתנאי שימוש של באזזטק",
  "Terms and Conditions": "תנאי שימוש",
  "and": "ו",
  "Privacy Policy": "מדיניות הפרטיות",
  "All actions within this panel are recorded for quality control and improvement": "כל הפעולות בלוח זה נקלטות לשם בקרה ושיפור איכות",
  "You must agree to the terms and conditions": "עליך להסכים לתנאי השימוש",
  "months": "חודשים",
  "ref_id": "מזהה פניה",
  "room_name_en": "שם חדר באנגלית",
  "site_state": "איזור",
  "state":"איזור",
  "site_city": "עיר",
  "city":"עיר",
  "site_country": "מדינה",
  "first_date": "תאריך ראשון",
  "pinned": "נעוץ",
  "no.": "מס.",
  "must_be_numbers": "חייב להיות מספרים",
  "must_be_3_digits": "חייב להיות 3 ספרות",
  "must_have_english_title": "חייב להיות כותרת באנגלית",
  "total_sites": "סה\"כ",
  "site_information": "פרטי אתר",
  "holiday_offdays": "חגים & חופשות",
  "add_offday": "חופשה",
  "add_holiday": "חג",
  "name_under_chars": "שם תקין עד 25 תווים",
  "days_comma_separated": "תאריכים תקינים, מופרדים בפסיק",
  "year": "שנה",
  "month": "חודש",
  "day": "יום",
  "holiday_name": "שם חג",
  "offday_name": "שם חופשה",
  "israel": "ישראל",
  "USA": "ארצות הברית",
  "poland": "פולין",
  "mexico": "מקסיקו",
  "cyprus": "קפריסין",
  "brazil": "ברזיל",
  "Purim Eve": "ערב פורים",
  "Aliyah Day": "יום העלייה",
  "Passover Eve": "ערב פסח",
  "Passover (Day 1)": "פסח",
  "Passover (Day 2)": "א חוה\"מ",
  "Passover (Day 3)": "ב חוה\"מ",
  "Passover (Day 4)": "ג חוה\"מ",
  "Passover (Day 5)": "ד חוה\"מ",
  "Passover (Day 6)": "שביעי של פסח",
  "Passover (Day 7)": "איסרו חג",
  "Yom HaShoah": "יום השואה",
  "Yom HaZikaron": "יום הזיכרון",
  "Yom HaAtzmaut": "יום העצמאות",
  "Jerusalem Day": "יום ירושלים",
  "Shavuot Eve": "ערב שבועות",
  "Shavuot": "שבועות",
  "Tisha B'Av Eve": "ערב תשעה באב",
  "Tisha B'Av": "תשעה באב",
  "Rosh Hashana Eve": "ערב ראש השנה",
  "Rosh Hashana": "ראש השנה",
  "Rosh Hashana (Day 2)": "ראש השנה",
  "Yom Kippur Eve": "ערב יום כיפור",
  "Yom Kippur": "יום כיפור",
  "Sukkot Eve": "ערב סוכות",
  "Sukkot (Day 1)": "סוכות",
  "Sukkot (Day 2)": "א חוה\"מ",
  "Sukkot (Day 3)": "ב חוה\"מ",
  "Sukkot (Day 4)": "ג חוה\"מ",
  "Sukkot (Day 5)": "ד חוה\"מ",
  "Sukkot (Day 6)": "ה חוה\"מ",
  "Sukkot (Day 7) / Hoshanah Rabah": "הושענא רבה",
  "Shemini Atzeret / Simchat Torah": "שמחת תורה",
  "Aliyah Day School Observance": "יום העלייה בבתי הספר",
  "Hanukkah (Day 1)": "חנוכה",
  "Hanukkah (Day 2)": "חנוכה",
  "Hanukkah (Day 3)": "חנוכה",
  "Hanukkah (Day 4)": "חנוכה",
  "Hanukkah (Day 5)": "חנוכה",
  "Hanukkah (Day 6) / Rosh Chodesh Tevet": "חנוכה",
  "Hanukkah (Day 7)": "חנוכה",
  "Hanukkah (Day 8)": "חנוכה",
  "tablet_survey": "סקר טאבלט",
  "no_shift_survey": "סקר ללא משמרת",
  "number_of_rows": "מספר שורות",
  "rating_shape": "צורת הדירוג",
  "content_survey": "סקר תוכן",
  "rating_survey": "סקר דירוג",
  "device-alerts": "בעיות",
  "device-active": "פעילים",
  "device-search": "חיפוש",
  "mac_id": "כתובת MAC",
  "qr_tag": "תג QR",
  "sensor": "חיישן",
  "image_survey": "סקר תמונה",
  "open_text": "טקסט פתוח",
  "open_text_survey": "סקר טקסט פתוח",
  "embedded_link": "קישור מוטמע",
  "device-counter": "מונים",
  "count": "מספר פעמים",
  "last_date": "תאריך אחרון",
  "last_arrival":"הגעת מנקה אחרונה",
  "cleaners": "מנקים",
  "viewer": "צופה",
  "android": "אנדרואיד",
  "ios": "אייפון",
  "no_users_found": "לא נמצאו משתמשים",
  "no_cleaners_found": "לא נמצאו מנקים",
  "new_cleaner": "מנקה חדש",
  "edit_cleaner": "ערוך מנקה",
  "please_select": "אנא בחר",
  "working_areas": "אזורי עבודה",
  "isWorkingInSite": "עובד באתר",
  "you_are_not_allowed_to_edit": "אין לך הרשאה לערוך",
  "you_are_not_allowed_to_remove": "אין לך הרשאה למחוק",
  "task": "משימה",
  "portuguese": "פורטוגזית",
  "russian": "רוסית",
  "spanish": "ספרדית",
  "polish": "פולנית",
  "installer": "מתקין",
  "once": "פעם אחת",
  "time_saved": "זמן שנחסך",
  "yes": "כן",
  "no": "לא",
  "workingAreaNames": "שמות אזורי עבודה",
  "shift_rooms":"חדרים",
  "new_shift":"משמרת חדשה",
  "add_shift_inactive": "הוספת משמרות שיקוף",
  "remove_auto_shift_inactive": "הסרת משמרות שיקוף אוטומטיות",
  "additional_options": "אפשרויות נוספות",
  "add_default_shifts": "הוספת משמרות קבועות מראש",
  "no_user_found": "לא נמצא משתמש",
  "no_site_found": "לא נמצא אתר",
  "service_provider": "ספק שירות",
  "qr task": "משימת QR",
  "qr_task": "משימות QR",
  "all_day": "כל היום",
  "auto_start": "התחלה אוטומטית",
  "english_title": "כותרת באנגלית",
  "connectivity": "קישוריות",
  "power": "כוח",
  "Your work has been saved": "העבודה שלך נשמרה",
  "schedule_call_time": "זמן קריאה",
  "schedule_call_gap": "פער זמן קריאה",
  "gapInMinutes": "פער בדקות",
  "no_manual_calls_available": "אין קריאות ידניות זמינות",
  "manual_calls": "קריאות ידניות",
  "cleandex_calls": "קריאות קלינדקס",
  "cleandexCalls": "קריאות קלינדקס",
  "manualCalls": "קריאות ידניות",
  "inactive": "לא פעיל",
  "on hold": "בהקפאה",
  "sequential": "רצף",
  "ratio": "יחס",
  "must_choose": "חייב לבחור",
  "surveyApp": "אפליקציית סקר",
  "installation": "התקנה",
  "manager": "מנהל",
  "buzzz": "באזזז",
  monthMap: {
    1: 'ינ׳',
    2: 'פב׳',
    3: 'מרץ',
    4: 'אפר׳',
    5: 'מאי',
    6: 'יוני',
    7: 'יולי',
    8: 'אוג׳',
    9: 'ספט׳',
    10: 'אוק׳',
    11: 'נוב׳',
    12: 'דצמ׳',
  },
  weekMap: {
    1: 'ראשון',
    2: 'שני',
    3: 'שלישי',
    4: 'רביעי',
    5: 'חמישי',
    6: 'שישי',
    7: 'שבת',
  },
}

export const pr = {
  ...serviks.pr,
  monthMap: {
    1: 'Jan',
    2: 'fevereiro',
    3: 'estragar',
    4: 'abril',
    5: 'Maio',
    6: 'junho',
    7: 'julho',
    8: 'agosto',
    9: 'setembro',
    10: 'outubro',
    11: 'novembro',
    12: 'dezembro',
  },
  weekMap: {
    1: 'domingo',
    2: 'Segunda-feira',
    3: 'terça-feira',
    4: 'quarta-feira',
    5: 'quinta-feira',
    6: 'sexta-feira',
    7: 'sábado',
  },
  "notified": "notificado",
  "supervisor_arrival": "chegada supervisor",
  "arrival": "chegada",
  "filter": "filtro",
  "logo": "logotipo",
  "counter": "contador",
  "sms": "SMS",
  "shifts": "mudanças",
  "call": "chamar",
  "duration": "duração",
  "time_start": "hora de início",
  "settings": "definições",
  "working_area": "área de trabalho",
  "complaints_NC": "queixas / NC",
  "SLA_NC": "SLA / NC",
  "arrivals_NC": "chegadas / NC",
  "staff": "pessoal",
  "cleaning_complaints": "As queixas mais limpas",
  "export": "exportar",
  "maintenance_complaints": "Reclamações de manutenção",
  "maintenanceArrivals": "manutenção Arrivals",
  "maintenance_arrivals": "manutenção Arrivals",
  "last": "último",
  "survey_name": "nome de pesquisa",
  "survey": "enquete",
  "pad_survey": "levantamento pad",
  "pad_kitchen": "cozinha pad",
  "number_of_assigned_devices": "# dispositivos atribuídos",
  "number_of_pages": "# De páginas",
  "date_created": "Data Criada",
  "updated_by": "atualizado por",
  "date_updated": "data de atualização",
  "search": "procurar",
  "choose_type": "escolher o tipo de",
  "permitted_sites": "sites permitidos",
  "permitted_franchise": "franquia permitida",
  "permitted_companies": "empresas autorizadas",
  "panel_permitted": "painel permitido",
  "end_time": "Fim do tempo",
  "last_update": "última atualização",
  "add_device": "Adicionar Dispositivo",
  "detailed": "detalhado",
  "analysis": "análise",
  "suggestion": "sugestão",
  "country": "país",
  "management": "gestão",
  "device": "dispositivo",
  "mapping": "mapeamento",
  "please_enter": "Por favor, insira",
  "new_device": "Novo dispositivo",
  "assign_device": "dispositivo atribuir",
  "installSpot": "local de instalação",
  "device_type": "tipo de dispositivo",
  "deviceCode": "código do dispositivo",
  "device_code": "código do dispositivo",
  "room": "quarto",
  "edit_room": "sala de edição",
  "edit_franchise": "editar franquia",
  "new_franchise": "nova franquia",
  "desc": "Descrição",
  "address": "Morada",
  "level": "nível",
  "no_companies_to_show": "Nenhuma empresa para mostrar",
  "company_type": "Tipo de companhia",
  "region": "região",
  "new_company": "nova empresa",
  "edit_company": "editar empresa",
  "edit_user": "Editar usuário",
  "C.arrivals": "limpador chegou",
  "C.complaints": "queixa de limpeza",
  "M.complaints": "manutenção chegou",
  "M.Arrivals": "queixa manutenção",
  "analyses": "análises",
  "clean_log": "log limpo",
  "maintenance_log": "registo de manutenção",
  "like": "gostar",
  "new_user": "novo usuário",
  "shift_parameters": "parâmetro turno",
  "add_shift": "mudança add",
  "edit_shift": "editar turno",
  "username": "nome de usuário",
  "first_name": "primeiro nome",
  "firstName": "primeiro nome",
  "description": "Descrição",
  "last_name": "sobrenome",
  "lastName": "sobrenome",
  "phone": "telefone",
  "building_area": "área de construção",
  "users": "Comercial",
  "add_user": "adicionar usuário",
  "sites": "locais",
  "new_site": "novo site",
  "edit_site": "editar local",
  "add_company": "empresa add",
  "franchise": "franquia",
  "floor": "Piso",
  "building": "prédio",
  "gender": "Gênero sexual",
  "display_name": "nome",
  "zone": "Zona",
  "shift_number": "mudança#",
  "shift_name": "nome mudança",
  "end": "fim",
  "min_sla": "min SLA",
  "max_sla": "Max SLA",
  "days": "Dias",
  "complaints_percent": "% de reclamações",
  "sla_percent": "% SLA",
  "arrivals_percent": "% Chegadas",
  "save_arrivals": "Salve ",
  "company_name": "Nome da empresa",
  "site_name": "nome do site",
  "site_desc": "Descrição do Site",
  "service_provider_name": "provedor de serviço",
  "service_provider_type": "Tipo de provedor de serviços",
  "service_provider_desc": "A inscrição provedor de serviços",
  "user": "do utilizador",
  "map": "mapa",
  "delete": "excluir",
  "new_room": "nova sala",
  "issue_times": "Número de emissão",
  "solved": "resolvido",
  "visits": "visitas",
  "complaints": "reclamações",
  "cleaner_arrivals": "Chegadas mais limpas",
  "response_time_SLA": "tempo de resposta",
  "score": "pontuação",
  "avg_score": "Avg Score",
  "score_goal": "Meta pontuação",
  "top_problem_issues": "Questões principais",
  "top_problem_rooms": "Topo Quartos",
  "top_problem_hours": "Principais Horas",
  "is_time_save": "O tempo economizado",
  "est_ROI": "Est ROI",
  "complaints_vs_visits": "Queixas x visitas",
  "view_by_years": "Ver Anos",
  "view_by_months": "Veja Meses",
  "view_by_weeks": "view Weeks",
  "view_by_days": "Veja Dias",
  "minutes": "min",
  "company": "Companhia",
  "site": "Local",
  "alerts": "Informações dos dispositivos",
  "performance": "Desempenho",
  "devices": "Dispositivos",
  "surveys": "pesquisas",
  "real_time": "Tempo real",
  "real-time": "Tempo real",
  "admin": "administrador",
  "all": "Todo",
  "overview": "Visão geral",
  "analytics": "analítica",
  "dayOfWeek": "Dias",
  "service_log": "log serviço",
  "rooms": "quartos",
  "displayName": "Nome da sala",
  "SLA": "SLA",
  "supervisors": "supervisores",
  "supervisor": "Supervisor",
  "performance_analytics": "desempenho Analytics",
  "arrivals": "Chegadas",
  "inspections": "inspeções",
  "avgResponseTime": "AVG tempo de resposta",
  "missedSla": "perdeu Sla",
  "cleaner_arrived": "Cleaner Chegou",
  "load_more": "Carregue mais",
  "login": "Conecte-se",
  "email": "O email",
  "emails": "emails",
  "password": "Senha",
  "min": "min",
  "Logout": "Sair",
  "custom_time": "Escolha datas",
  "last_day": "Último dia",
  "last_week": "Semana passada",
  "last_month": "Mês passado",
  "last_quarter": "Ultimo quarto",
  "last_6_months": "Últimos 6 meses",
  "last_year": "Ano passado",
  "data_by_hour": "Dados por hora",
  "general": "Em geral",
  "cleaning": "Limpeza",
  "maintenance": "Manutenção",
  "start": "Começar",
  "start_time": "hora de início",
  "management_co": "gestão co",
  "cleaning_co": "limpeza co",
  "companies": "empresas",
  "name": "nome",
  "site_count": "local #",
  "associated_companies": "clientes finais associados",
  "OK": "OK",
  "cancel": "cancelar",
  "client": "cliente",
  "dates": "datas",
  "shift": "mudança",
  "issue": "emitir",
  "time": "Tempo",
  "top complaint of the week": "queixa top da semana",
  "top room of the week": "sala de top da semana",
  "top hour of the week": "top hora da semana",
  "top complaint of the month": "queixa topo do mês",
  "top room of the month": "sala de topo do mês",
  "top hour of the month": "top hora do mês",
  "franchises": "franquias",
  "add_franchise": "franchise add",
  "room_names": "nome da sala",
  "type": "modelo",
  "please_select_company_site": "por favor selecione uma empresa e local",
  "number": "número",
  "create_building_map": "criar mapa construção",
  "hey quickest way to add floors and rooms to a buildings via this quick wizard": "hey maneira mais rápida de adicionar pisos e quartos para uma edifícios através deste assistente rápida",
  "avg number of toilets per floor": "Avg number de banheiros por andar",
  "avg.number of toilet cells per room": "Avg.number de células de higiene por quarto",
  "number of floors": "Número de pisos",
  "avg.number of disabled toilets": "Avg.number de banheiros com deficiência",
  "avg.number of soap dispensers": "Avg.number de distribuidores de sabão",
  "reset_filter": "Filtro de reset",
  "add": "adicionar",
  "new_survey": "nova pesquisa",
  "api_configuration": "Configuração API",
  "please_edit_the_api_information": "Edite as informações da API",
  "method": "método",
  "authorization": "autorização",
  "api_url": "api_url",
  "token": "símbolo",
  "api_request_info": "Informações API Pedido",
  "roomName": "Nome da sala",
  "servikName": "Nome da queixa",
  "siteName": "Nome do site",
  "stage": "Etapa",
  "number_of_buttons": "Número de botões",
  "button_shape": "forma de botão",
  "delay_time_stage": "Delay Time Stage",
  "go_to_stage": "Go To Stage",
  "color": "Cor",
  "add_stage": "Adicionar Stage",
  "save": "Salve ",
  "number_of_issues": "número de questões",
  "online": "conectados",
  "offline": "desligada",
  "room_name": "nome da sala",
  "installation_spot": "local",
  "device_id": "Identificador do dispositivo",
  "date": "encontro",
  "status": "status",
  "device_status": "status do dispositivo",
  "none": "Nenhum",
  "no_surveys_created": "Não há inquéritos criados",
  "dashboard": "Painel",
  "franchise_name": "Nome franquia",
  "franchise_country": "franchise País",
  "choose_franchise": "Escolha Franchise",
  "choose_country": "Escolha o país",
  "select_template": "Selecionar modelo",
  "default_survey": "padrão Levantamento",
  "cleaning_survey": "Levantamento de limpeza",
  "kitchen_survey": "Levantamento cozinha",
  "template": "Modelo",
  "area_name": "Nome area",
  "rooms_names": "Quartos Names",
  "rooms_number": "Quartos #",
  "assign_number": "Atribuir #",
  "active_notification_task": "Ativo Notificação Task",
  "amount_limit": "contador Interval",
  "after_max": "Contador após Max",
  "new_area": "nova área",
  "tablet": "Tábua",
  "counter women": "contra Mulheres",
  "counter men": "contador Man",
  "counter handicap": "contador Handicap",
  "counter man/woman": "Contador Homem / Mulher",
  "counter man / woman": "Contador Homem / Mulher",
  "sensor liquid": "sensor de líquido",
  "double toilet paper": "Duplo Toilet Paper",
  "toilet paper": "Papel higiênico",
  "tag": "Marcação",
  "qr tag": "QR Tag",
  "additional_info": "informação adicional",
  "servikCategory": "tipo de chamada",
  "dishwashing": "Lavar pratos",
  "cafeteria": "Cantina",
  "clean room": "Quarto limpo",
  "office": "Escritório",
  "meeting room": "Sala de reuniões",
  "cubic": "Cúbico",
  "women": "Mulheres",
  "men": "Homens",
  "handicap": "desvantagem",
  "Combined": "Combinado",
  "kitchen": "Cozinha",
  "east": "leste",
  "west": "Oeste",
  "north": "Norte",
  "south": "Sul",
  "right": "Direita",
  "left": "Deixou",
  "center": "Centro",
  "up": "Acima",
  "down": "Abaixo",
  "combined": "Combinado",
  "lobby": "Salão",
  "entrance": "Entrada",
  "reception": "Recepção",
  "area": "Área",
  "mezzanine": "Mezanino",
  "gallery": "Galeria",
  "ground": "Terra",
  "elevator": "Elevador",
  "is_calculated": "Quarto calculado",
  "calculated_room": "Quarto Calculado",
  "splitted_survey": "pesquisa dividida",
  "redirect_to_url": "redirecionar para url",
  "survey_site": "Site de pesquisa",
  "clicks": "cliques",
  "add_stage_number_to_signal": "anexar o número do estágio",
  "including_pad9": "incluindo pad9",
  "default_name": "Nome em ingles",
  "exposures": "exposições",
  "expose_results": "expor resultados",
  "events_expose": "Eventos Exponha",
  "expose_score": "expor a pontuação",
  "shift_events_expose": "shift events & exponha",
  "type_of_site": "tipo de site",
  "sector": "setor",
  "last_time_of_arrival": "última hora de chegada",
  "arrival_and_status": "chegada & Status",
  "preview_arrivals": "visualizar chegadas",
  "men toilet": "banheiro masculino",
  "women toilet": "banheiro feminino",
  "shared toilet": "banheiro masculino",
  "handicapped toilet": "banheiro para deficientes",
  "morning_shift": "turno da manhã",
  "afternoon_shift": "turno da tarde",
  "night_shift": "turno da noite",
  "update": "atualizar",
  "restart": "reiniciar",
  "send_data": "enviar dados",
  "send_count": "enviar contador",
  "blink": "piscar",
  "maintenance_btn": "botão de manutenção",
  "battery": "bateria",
  "version": "versão",
  "actions": "ações",
  "drains_battery": "drena a bateria",
  "latest_errors": "últimos erros",
  "no_errors": "nenhum erro",
  "offline_calls": "Alertas off-line",
  "offline_duration_calls": "Duração dos alertas offline",
  "counterCalls": "Contador de chamadas",
  "active": "Ativo",
  "active_calculation": "Calculo ativo",
  "pad": "pad",
  "pad_counter": "contador pad",
  "counter_status": "status do contador",
  "choose_state": "Escolha o estado",
  "choose_city": "Escolha a cidade",
  "start_hour": "Hora de início",
  "end_hour": "Hora de término",
  "analytics_log": "log de análise",
  "or": "ou",
  "no_data_available": "Nenhum dado disponível",
  "new_task": "Nova tarefa",
  "category": "Categoria",
  "title": "Título",
  "task_type": "Tipo de tarefa",
  "task_category": "Categoria de tarefa",
  "call_to_action": "Chamar para ação",
  "schedule": "Agenda",
  "daily": "Diariamente",
  "insert_text": "Inserir texto",
  "select": "Selecione",
  "schedule_dates": "Datas de agendamento",
  "language": "Língua",
  "calendar": "Calendário",
  "operation": "Operação",
  "weekly": "Semanal",
  "monthly": "Mensal",
  "quarterly": "Trimestral",
  "yearly": "Anual",
  "sunday": "Domingo",
  "monday": "Segunda-feira",
  "tuesday": "Terça-feira",
  "wednesday": "Quarta-feira",
  "thursday": "Quinta-feira",
  "friday": "Sexta-feira",
  "saturday": "Sábado",
  "january": "Janeiro",
  "february": "Fevereiro",
  "march": "Março",
  "april": "Abril",
  "may": "Maio",
  "june": "Junho",
  "july": "Julho",
  "august": "Agosto",
  "september": "Setembro",
  "october": "Outubro",
  "november": "Novembro",
  "december": "Dezembro",
  "1st week": "1ª semana",
  "2nd week": "2ª semana",
  "3rd week": "3ª semana",
  "4th week": "4ª semana",
  "5th week": "5ª semana",
  "1st quarter": "1º trimestre",
  "2nd quarter": "2º trimestre",
  "3rd quarter": "3º trimestre",
  "4th quarter": "4º trimestre",
  "edit_task": "Editar tarefa",
  "created_at": "Criado em",
  "hour": "Hora",
  "week_days": "Dias da semana",
  "month_weeks": "Semanas do mês",
  "month_days": "Dias do mês",
  "no_tasks_found": "Nenhuma tarefa encontrada",
  "invalid_language": "Língua inválida",
  "no_active_tasks_found": "Nenhuma tarefa ativa encontrada",
  "edit": "Editar",
  "send_date": "Data de envio",
  "invalid_date": "Data inválida",
  "cells_num": "# de células",
  "sinks_num": "# de pias",
  "urinals_num": "# de urinóis",
  "toilet_paper_size": "Tamanho do papel higiênico",
  "email_configuration": "Configuração de e-mail",
  "please_edit_the_email_information": "Por favor, edite as informações de e-mail",
  "email_sender_info": "Informações do remetente do e-mail",
  "email_subject_info": "Informações do assunto do e-mail",
  "email_message_info": "Informações da mensagem do e-mail",
  "companyName": "Nome da empresa",
  "email_language": "Língua do e-mail",
  "complaint_language": "Língua da reclamação",
  "email_message_format": "Formato da mensagem do e-mail",
  "line": "Linha",
  "list": "Lista",
  "roomType": "Tipo de quarto",
  "By logging in, you agree to Buzzztech's": "Ao fazer login, você concorda com os Termos e Condições da Buzzztech",
  "Terms and Conditions": "Termos e Condições",
  "and": "e",
  "Privacy Policy": "Política de Privacidade",
  "All actions within this panel are recorded for quality control and improvement": "Todas as ações dentro deste painel são gravadas para controle de qualidade e melhoria",
  "You must agree to the terms and conditions": "Você deve concordar com os termos e condições",
  "months": "Meses",
  "ref_id": "ID de referência",
  "room_name_en": "Nome do quarto em inglês",
  "site_state": "Região do site",
  "site_city": "Cidade Local",
  "site_country": "País do Local",
  "first_date": "Primeira Data",
  "pinned": "Fixado",
  "no.": "Nº",
  "must_be_numbers": "Deve ser números",
  "must_be_3_digits": "Deve ser 3 dígitos",
  "must_have_english_title": "Deve ter título em inglês",
  "total_sites": "Total",
  "site_information": "Informações do Local",
  "holiday_offdays": "Feriados e folgas",
  "add_offday": "Folga",
  "add_holiday": "Feriado",
  "name_under_chars": "nome com menos de 25 caracteres",
  "days_comma_separated": "dias válidos, separados por vírgula",
  "year": "ano",
  "month": "mês",
  "day": "dia",
  "holiday_name": "nome do feriado",
  "offday_name": "nome do dia de folga",
  "israel": "Israel",
  "USA": "EUA",
  "poland": "Polônia",
  "mexico": "México",
  "cyprus": "Chipre",
  "brazil": "Brasil",
  "tablet_survey": "Pesquisa de tablet",
  "no_shift_survey": "Pesquisa de turno",
  "number_of_rows": "Número de linhas",
  "rating_shape": "Forma de classificação",
  "content_survey": "Pesquisa de conteúdo",
  "rating_survey": "Pesquisa de classificação",
  "device-alerts": "Problema",
  "device-active": "Ativo",
  "device-search": "Procurar",
  "mac_id": "Endereço MAC",
  "qr_tag": "Etiqueta QR",
  "sensor": "Sensor",
  "image_survey": "Pesquisa de imagem",
  "open_text": "Texto aberto",
  "open_text_survey": "Pesquisa de texto aberto",
  "embedded_link": "Link incorporado",
  "device-counter": "Contadores",
  "count": "Contar",
  "last_date": "Última data",
  "last_arrival":"Última chegada do aspirador",
  "cleaners": "Aspiradores",
  "viewer": "Visualizador",
  "android": "Android",
  "ios": "iOS",
  "no_users_found": "Nenhum usuário encontrado",
  "no_cleaners_found": "Nenhum aspirador encontrado",
  "new_cleaner": "Novo aspirador",
  "edit_cleaner": "Editar aspirador",
  "please_select": "Por favor selecione",
  "working_areas": "Áreas de trabalho",
  "isWorkingInSite": "Trabalhando no local",
  "you_are_not_allowed_to_edit": "Você não tem permissão para editar",
  "you_are_not_allowed_to_remove": "Você não tem permissão para remover",
  "task": "Tarefa",
  "portuguese": "Português",
  "russian": "Russo",
  "spanish": "Espanhol",
  "polish": "Polonês",
  "installer": "Instalador",
  "once": "Uma vez",
  "time_saved": "Tempo salvo",
  "yes": "Sim",
  "no": "Não",
  "workingAreaNames": "Nomes das áreas de trabalho",
  "shift_rooms":"Quartas",
  "new_shift":"Novo turno",
  "add_shift_inactive": "Adicione turnos reflexivas",
  "remove_auto_shift_inactive": "Remova turnos reflexivas automatizadas",
  "additional_options": "Opções adicionais",
  "add_default_shifts": "Adicionar turnos padrão",
  "no_user_found": "Nenhum usuário encontrado",
  "no_site_found": "Nenhum site encontrado",
  "service_provider": "Provedor de serviços",
  "qr task": "Tarefa QR",
  "qr_task": "Tarefas QR",
  "all_day": "O dia todo",
  "auto_start": "Início automático",
  "english_title": "Título em inglês",
  "connectivity": "Conectividade",
  "power": "Poder",
  "Your work has been saved": "Seu trabalho foi salvo",
  "schedule_call_time": "Tempo de chamada",
  "schedule_call_gap": "Intervalo de chamada",
  "gapInMinutes": "Intervalo em minutos",
  "no_manual_calls_available": "Nenhuma chamada manual disponível",
  "manual_calls": "Chamadas manuais",
  "cleandex_calls": "Chamadas Cleandex",
  "cleandexCalls": "Chamadas Cleandex",
  "manualCalls": "Chamadas manuais",
  "inactive": "Inativo",
  "on hold": "Em espera",
  "sequential": "Sequencial",
  "ratio": "Proporção",
  "must_choose": "Deve escolher",
  "surveyApp": "Aplicativo de pesquisa",
  "installation": "Instalação",
}

export const ru = {
  ...serviks.ru,
  monthMap: {
    1: 'январь',
    2: 'февраль',
    3: 'март',
    4: 'апрель',
    5: 'Май',
    6: 'июнь',
    7: 'июль',
    8: 'август',
    9: 'сентябрь',
    10: 'октябрь',
    11: 'ноябрь',
    12: 'декабрь',
  },
  weekMap: {
    1: 'воскресенье',
    2: 'понедельник',
    3: 'вторник',
    4: 'среда',
    5: 'Четверг',
    6: 'Пятница',
    7: 'Суббота',
  },
  "notified": "уведомление",
  "supervisor_arrival": "прибытие инспектора",
  "arrival": "пребытие",
  "filter": "фильтр",
  "logo": "логотип",
  "counter": "счетчик",
  "sms": "смс",
  "shifts": "смены",
  "call": "вызов",
  "duration": "продолжительность",
  "time_start": "время начала",
  "settings": "настройки",
  "working_area": "рабочая область",
  "complaints_NC": "жалобы без счетчика",
  "SLA_NC": "SLA без счетчика",
  "arrivals_NC": "прибытия без счетчика",
  "staff": "сотрудники",
  "cleaning_complaints": "Очистители Жалобы",
  "export": "экспорт",
  "maintenance_complaints": "Техподдержка Жалобы",
  "maintenanceArrivals": "Техническое обслуживание прибытия",
  "maintenance_arrivals": "Техническое обслуживание прибытия",
  "last": "последний",
  "survey_name": "название опроса",
  "survey": "опрос",
  "pad_survey": "опрос подушечка",
  "pad_kitchen": "кухня подушечка",
  "number_of_assigned_devices": "# назначенные устройства",
  "number_of_pages": "# Страниц",
  "date_created": "Дата создания",
  "updated_by": "обновляется",
  "date_updated": "дата обновления",
  "search": "поиск",
  "choose_type": "выберите тип",
  "permitted_sites": "разрешенные сайты",
  "permitted_franchise": "разрешается франшиза",
  "permitted_companies": "допускаемые компании",
  "panel_permitted": "панель разрешено",
  "end_time": "время окончания",
  "last_update": "последнее обновление",
  "add_device": "добавить устройство",
  "detailed": "детальнее",
  "analysis": "анализ",
  "suggestion": "предложение",
  "country": "страна",
  "management": "управление",
  "device": "устройство",
  "mapping": "отображение",
  "please_enter": "пожалуйста, введите",
  "new_device": "новое устройство",
  "assign_device": "правопреемником устройство",
  "installSpot": "установка место",
  "device_type": "тип устройства",
  "deviceCode": "код прибора",
  "device_code": "код прибора",
  "room": "комната",
  "edit_room": "редактировать номер",
  "edit_franchise": "редактировать франшизы",
  "new_franchise": "новая франшиза",
  "desc": "описание",
  "address": "адрес",
  "level": "уровень",
  "no_companies_to_show": "Нет компании для показа",
  "company_type": "тип компании",
  "region": "область, край",
  "new_company": "новая компания",
  "edit_company": "редактировать компании",
  "edit_user": "редактировать пользователь",
  "C.arrivals": "очиститель прибыли",
  "C.complaints": "чистка жалобы",
  "M.complaints": "обслуживание прибыли",
  "M.Arrivals": "содержание жалобы",
  "analyses": "анализы",
  "clean_log": "чистый журнал",
  "maintenance_log": "журнал технического обслуживания",
  "like": "подобно",
  "new_user": "новый пользователь",
  "shift_parameters": "параметр сдвига",
  "add_shift": "добавить сдвиг",
  "edit_shift": "редактировать сдвиг",
  "username": "имя пользователя",
  "first_name": "имя",
  "firstName": "имя",
  "description": "описание",
  "last_name": "фамилия",
  "lastName": "фамилия",
  "phone": "Телефон",
  "building_area": "Площадь застройки",
  "users": "пользователи",
  "add_user": "Добавить пользователя",
  "sites": "места",
  "new_site": "новый сайт",
  "edit_site": "редактировать сайт",
  "add_company": "добавить компанию",
  "franchise": "франшиза",
  "floor": "Этаж",
  "building": "строительство",
  "gender": "Пол",
  "display_name": "имя",
  "zone": "зона",
  "shift_number": "сдвиг#",
  "shift_name": "имя сдвига",
  "end": "конец",
  "min_sla": "Минимальная ОАС",
  "max_sla": "Макс ОАС",
  "days": "дней",
  "complaints_percent": "% Жалобы",
  "sla_percent": "% ОАС",
  "arrivals_percent": "% Прибытие",
  "save_arrivals": "спасти",
  "company_name": "название компании",
  "site_name": "Название сайта",
  "site_desc": "описание сайта",
  "service_provider_name": "поставщик услуг",
  "service_provider_type": "тип услуги поставщика",
  "service_provider_desc": "Описание поставщика услуг",
  "user": "Пользователь",
  "map": "карта",
  "delete": "Удалить",
  "new_room": "новый номер",
  "issue_times": "Времена выпуска",
  "solved": "решаемая",
  "visits": "просмотров",
  "complaints": "жалобы",
  "cleaner_arrivals": "Очистители прибытий",
  "response_time_SLA": "Время отклика",
  "score": "счет",
  "avg_score": "Средние баллы",
  "score_goal": "Оценка цели",
  "top_problem_issues": "Популярные вопросы",
  "top_problem_rooms": "Лучшие Комнаты",
  "top_problem_hours": "Лучшие часы",
  "is_time_save": "сэкономленное время",
  "est_ROI": "Est ROI",
  "complaints_vs_visits": "Жалобы и посещение",
  "view_by_years": "Просмотр года",
  "view_by_months": "Просмотр месяца",
  "view_by_weeks": "Просмотр недели",
  "view_by_days": "Посмотреть дни",
  "minutes": "мин",
  "company": "Компания",
  "site": "Сайт",
  "alerts": "Информация об устройствах",
  "performance": "Спектакль",
  "devices": "приборы",
  "surveys": "Обзоры",
  "real_time": "реальное время",
  "real-time": "реальное время",
  "admin": "Администратор",
  "all": "Все",
  "overview": "обзор",
  "analytics": "аналитика",
  "dayOfWeek": "дней",
  "service_log": "Служебный журнал",
  "rooms": "Номера",
  "displayName": "Название комнаты",
  "SLA": "ОАС",
  "supervisors": "контролеры",
  "supervisor": "руководитель",
  "performance_analytics": "Производительность Аналитика",
  "arrivals": "Прибытие",
  "inspections": "инспекции",
  "avgResponseTime": "время отклика",
  "missedSla": "Пропущенный Сла",
  "cleaner_arrived": "Очиститель Прибыл",
  "load_more": "Загрузи больше",
  "login": "Авторизоваться",
  "email": "Электронное письмо",
  "emails": "Письма",
  "password": "Пароль",
  "min": "мин",
  "Logout": "Выйти",
  "custom_time": "Выберите даты",
  "last_day": "Последний день",
  "last_week": "На прошлой неделе",
  "last_month": "Прошлый месяц",
  "last_quarter": "Последняя четверть",
  "last_6_months": "Последние 6 месяцев",
  "last_year": "Прошедший год",
  "data_by_hour": "Данные по часу",
  "general": "Общий",
  "cleaning": "очищающий",
  "maintenance": "Обслуживание",
  "start": "Начинать",
  "start_time": "начальное время",
  "management_co": "управление совместно.",
  "cleaning_co": "очистки сотрудничества.",
  "companies": "компании",
  "name": "имя",
  "site_count": "сайт #",
  "associated_companies": "связанные с конечными клиентами",
  "OK": "Ok",
  "cancel": "Отмена",
  "client": "клиент",
  "dates": "даты",
  "shift": "сдвиг",
  "issue": "проблема",
  "time": "время",
  "top complaint of the week": "Верхняя жалоба недели",
  "top room of the week": "Верхняя комната недели",
  "top hour of the week": "топ час недели",
  "top complaint of the month": "Верхняя жалоба месяца",
  "top room of the month": "Верхняя комната месяца",
  "top hour of the month": "топ час месяца",
  "franchises": "франшиз",
  "add_franchise": "добавить франшизу",
  "room_names": "название комнаты",
  "type": "тип",
  "please_select_company_site": "пожалуйста, выберите компанию & сайт",
  "number": "номер",
  "create_building_map": "создать здание карты",
  "hey quickest way to add floors and rooms to a buildings via this quick wizard": "эй быстрый способ добавить этажи и комнаты до здания с помощью этого быстрого мастера",
  "avg number of toilets per floor": "туалетов на этаже",
  "avg.number of toilet cells per room": "Avg.number туалетных клеток в комнате",
  "number of floors": "Количество этажей",
  "avg.number of disabled toilets": "Avg.number туалетов для инвалидов",
  "avg.number of soap dispensers": "Avg.number из мыла",
  "reset_filter": "сброс фильтра",
  "add": "Добавить",
  "new_survey": "повторная съемка",
  "api_configuration": "Конфигурация API",
  "please_edit_the_api_information": "Измените API Информация",
  "method": "метод",
  "authorization": "авторизация",
  "api_url": "api_url",
  "token": "знак",
  "api_request_info": "API Запросить информацию",
  "roomName": "Название комнаты",
  "servikName": "Имя жалобы",
  "siteName": "Название сайта",
  "stage": "Этап",
  "number_of_buttons": "Количество кнопок",
  "button_shape": "Кнопка Shape",
  "delay_time_stage": "Время задержки Этап",
  "go_to_stage": "Перейти к Этап",
  "color": "Цвет",
  "add_stage": "Добавить этап",
  "save": "Сохранять",
  "number_of_issues": "ряд вопросов",
  "online": "онлайн",
  "offline": "не в сети",
  "room_name": "название комнаты",
  "installation_spot": "место",
  "device_id": "идентификатор устройства",
  "date": "Дата",
  "status": "положение дел",
  "device_status": "состояние устройства",
  "none": "никто",
  "no_surveys_created": "Нет опросов Дата создания",
  "dashboard": "Приборная панель",
  "franchise_name": "Франшиза Имя",
  "franchise_country": "Франшиза Страна",
  "choose_franchise": "Выберите Франчайзинг",
  "choose_country": "Выберите страну",
  "select_template": "Выбор шаблона",
  "default_survey": "По умолчанию Обзор",
  "cleaning_survey": "Обзор Очистка",
  "kitchen_survey": "Кухня Обзор",
  "template": "Шаблон",
  "area_name": "Имя Регион",
  "rooms_names": "Номера Имена",
  "rooms_number": "Номера #",
  "assign_number": "Назначать #",
  "active_notification_task": "Активный Уведомление Task",
  "amount_limit": "счетчик интервалов",
  "after_max": "Счетчик после Max",
  "new_area": "новая область",
  "tablet": "таблетка",
  "counter women": "Счетчик Женщины",
  "counter men": "Счетчик Человек",
  "counter handicap": "Счетчик фора",
  "counter man/woman": "Счетчик Мужчина / женщина",
  "counter man / woman": "Счетчик Мужчина / женщина",
  "sensor liquid": "Датчик Жидкая",
  "double toilet paper": "Двойная туалетная бумага",
  "toilet paper": "Туалетная бумага",
  "tag": "Тег",
  "qr tag": "QR тег",
  "additional_info": "Дополнительная информация",
  "servikCategory": "тип вызова",
  "dishwashing": "Посудомоечные",
  "cafeteria": "кафетерий",
  "clean room": "Чистая комната",
  "office": "Офис",
  "meeting room": "Комната для переговоров",
  "cubic": "кубический",
  "women": "Женщины",
  "men": "Мужчины",
  "handicap": "гандикап",
  "Combined": "комбинированный",
  "kitchen": "Кухня",
  "east": "Восток",
  "west": "Запад",
  "north": "север",
  "south": "юг",
  "right": "Правильно",
  "left": "Оставили",
  "center": "Центр",
  "up": "Вверх",
  "down": "Вниз",
  "combined": "комбинированный",
  "lobby": "Лобби",
  "entrance": "Вход",
  "reception": "прием",
  "area": "Площадь",
  "mezzanine": "мезонин",
  "gallery": "Галерея",
  "ground": "Земля",
  "elevator": "Лифт",
  "is_calculated": "Рассчитано номер",
  "calculated_room": "Расчетный номер",
  "splitted_survey": "разделенный опрос",
  "redirect_to_url": "перенаправить на url",
  "survey_site": "Сайт исследования",
  "clicks": "щелчки",
  "add_stage_number_to_signal": "прикрепить номер этапа",
  "including_pad9": "в том числе pad9",
  "default_name": "Имя на английском",
  "exposures": "разоблачения",
  "expose_results": "разоблачить результаты",
  "events_expose": "события выставляют",
  "expose_score": "выставить счет",
  "shift_events_expose": "cмежные события и разоблачить",
  "type_of_site": "тип сайта",
  "sector": "сектор",
  "last_time_of_arrival": "последнее время прибытия",
  "arrival_and_status": "прибытие & статус",
  "preview_arrivals": "предварительный просмотр прибытия",
  "men toilet": "мужской туалет",
  "women toilet": "женский туалет",
  "shared toilet": "общий туалет",
  "handicapped toilet": "туалет для инвалидов",
  "morning_shift": "утренняя смена",
  "afternoon_shift": "вторую смену",
  "night_shift": "ночная смена",
  "update": "обновить",
  "restart": "перезапуск",
  "send_data": "отправить данные",
  "send_count": "отправить счетчик",
  "blink": "подсветить",
  "maintenance_btn": "кнопка обслуживания",
  "battery": "батарея",
  "version": "версия",
  "actions": "действия",
  "drains_battery": "разряжает батарею",
  "latest_errors": "последние ошибки",
  "no_errors": "нет ошибок",
  "offline_calls": "Офлайн-оповещения",
  "offline_duration_calls": "Продолжительность автономных оповещений",
  "counterCalls": "Счетчик оповещений",
  "active": "Активный",
  "active_calculation": "Активный расчет",
  "pad": "ПАД",
  "pad_counter": "Счетчик ПАД",
  "counter_status": "Статус счетчика",
  "choose_state": "Выберите состояние",
  "choose_city": "Выберите город",
  "start_hour": "Начало часа",
  "end_hour": "Конец часа",
  "analytics_log": "Аналитика",
  "or": "или же",
  "no_data_available": "Нет данных",
  "no_tasks": "Нет задач",
  "category": "Категория",
  "title": "Заголовок",
  "task_type": "Тип задачи",
  "task_category": "Категория задачи",
  "call_to_action": "Звонок к действию",
  "schedule": "Расписание",
  "insert_text": "Вставить текст",
  "select": "Выбрать",
  "schedule_dates": "Даты расписания",
  "language": "Язык",
  "calendar": "Календарь",
  "operation": "Операция",
  "daily": "Ежедневно",
  "weekly": "Еженедельно",
  "monthly": "Ежемесячно",
  "quarterly": "Квартально",
  "yearly": "Ежегодно",
  "sunday": "Воскресенье",
  "monday": "Понедельник",
  "tuesday": "Вторник",
  "wednesday": "Среда",
  "thursday": "Четверг",
  "friday": "Пятница",
  "saturday": "Суббота",
  "january": "Январь",
  "february": "Февраль",
  "march": "Март",
  "april": "Апрель",
  "may": "Май",
  "june": "Июнь",
  "july": "Июль",
  "august": "Август",
  "september": "Сентябрь",
  "october": "Октябрь",
  "november": "Ноябрь",
  "december": "Декабрь",
  "1st week": "1 неделя",
  "2nd week": "2 неделя",
  "3rd week": "3 неделя",
  "4th week": "4 неделя",
  "5th week": "5 неделя",
  "1st quarter": "1 квартал",
  "2nd quarter": "2 квартал",
  "3rd quarter": "3 квартал",
  "4th quarter": "4 квартал",
  "edit_task": "Редактировать задачу",
  "created_at": "Создано",
  "hour": "Час",
  "week_days": "Дни недели",
  "month_weeks": "Недели месяца",
  "month_days": "Дни месяца",
  "no_tasks_found": "Задачи не найдены",
  "invalid_lanauge": "Неверный язык",
  "no_active_tasks_found": "Активные задачи не найдены",
  "edit": "Редактировать",
  "send_date": "Дата отправки",
  "invalid_date": "Неверная дата",
  "cells_num": "# ячеек",
  "sinks_num": "# раковин",
  "urinals_num": "# унитазов",
  "toilet_paper_size": "Размер туалетной бумаги",
  "email_configuration": "Настройки электронной почты",
  "please_edit_the_email_information": "Пожалуйста, отредактируйте информацию электронной почты",
  "email_sender_info": "Информация о отправителе электронной почты",
  "email_subject_info": "Информация о теме электронной почты",
  "email_message_info": "Информация о сообщении электронной почты",
  "companyName": "Название компании",
  "email_language": "Язык электронной почты",
  "complaint_language": "Язык жалобы",
  "email_message_format": "Формат сообщения электронной почты",
  "line": "Линия",
  "list": "Список",
  "roomType": "Тип комнаты",
  "By logging in, you agree to Buzzztech's": "Войдя в систему, вы соглашаетесь с условиями использования Buzzztech",
  "Terms and Conditions": "Условия использования",
  "and": "и",
  "Privacy Policy": "Политика конфиденциальности",
  "All actions within this panel are recorded for quality control and improvement": "Все действия в этой панели записываются для контроля качества и улучшения",
  "You must agree to the terms and conditions": "Вы должны согласиться с условиями использования",
  "months": "Месяцы",
  "ref_id": "Идентификатор",
  "room_name_en": "Название комнаты (англ.)",
  "site_state": "Государство сайта",
  "site_city": "Cайт город",
  "site_country": "Cтрана сайта",
  "first_date": "Первое свидание",
  "pinned": "Закреплено",
  "no.": "№",
  "must_be_numbers": "Должны быть цифры",
  "must_be_3_digits": "Должно быть 3 цифры",
  "must_have_english_title": "Должно быть английское название",
  "total_sites": "Общий",
  "site_information": "информация сайта",
  "holiday_offdays": "Праздники и выходной",
  "add_offday": "выходной",
  "add_holiday": "Holiday",
  "name_under_chars": "имя до 25 символов",
  "days_comma_separated": "действительные дни, разделенные запятой",
  "year": "год",
  "month": "месяц",
  "day": "день",
  "holiday_name": "название праздника",
  "offday_name": "название выходного дня",
  "israel": "Израиль",
  "USA": "США",
  "poland": "Польша",
  "mexico": "Мексика",
  "cyprus": "Кипр",
  "brazil": "Бразилия",
  "tablet_survey": "Планшетный опрос",
  "no_shift_survey": "Нет сменного опроса",
  "number_of_rows": "Количество строк",
  "rating_shape": "Форма рейтинга",
  "content_survey": "Опрос содержания",
  "rating_survey": "Опрос рейтинга",
  "device-alerts": "Проблема",
  "device-active": "Активный",
  "device-search": "Поиск",
  "mac_id": "MAC-адрес",
  "qr_tag": "QR-тег",
  "sensor": "Датчик",
  "image_survey": "Опрос изображения",
  "open_text": "Открытый текст",
  "open_text_survey": "Открытый текст",
  "embedded_link": "Встроенная ссылка",
  "device-counter": "Счетчики",
  "count": "Считать",
  "last_date": "Последнее свидание",
  "last_arrival":"Последний приезд уборщицы",
  "cleaners": "Уборщицы",
  "viewer": "Зритель",
  "android": "Андроид",
  "ios": "iOS",
  "no_users_found": "Пользователи не найдены",
  "no_cleaners_found": "Уборщицы не найдены",
  "new_cleaner": "Новая уборщица",
  "edit_cleaner": "Редактировать уборщицу",
  "please_select": "Пожалуйста, выберите",
  "working_areas": "Рабочие области",
  "isWorkingInSite": "Работает на сайте",
  "you_are_not_allowed_to_edit": "Вы не можете редактировать",
  "you_are_not_allowed_to_remove": "Вы не можете удалить",
  "task": "Задача",
  "portuguese": "португальский",
  "russian": "русский",
  "spanish": "испанский",
  "polish": "польский",
  "installer": "Установщик",
  "once": "Один раз",
  "time_saved": "Время сохранено",
  "yes": "да",
  "no": "нет",
  "workingAreaNames": "Рабочие области",
  "shift_rooms":"Номера",
  "new_shift":"Новая смена",
  "add_shift_inactive": "Добавьте отражающие сдвиги",
  "remove_auto_shift_inactive": "Удалить автоматические рефлексивные смены",
  "additional_options": "Дополнительные опции",
  "add_default_shifts": "Добавить смены по умолчанию",
  "no_user_found": "Пользователь не найден",
  "no_site_found": "Сайт не найден",
  "service_provider": "Поставщик услуг",
  "qr task": "QR задача",
  "qr_task": "QR задача",
  "all_day": "Весь день",
  "auto_start": "Автозапуск",
  "english_title": "Английское название",
  "connectivity": "Связь",
  "power": "Мощность",
  "Your work has been saved": "Ваша работа была сохранена",
  "schedule_call_time": "Время звонка",
  "schedule_call_gap": "Интервал звонка",
  "gapInMinutes": "Интервал в минутах",
  "no_manual_calls_available": "Нет доступных ручных вызовов",
  "manual_calls": "Ручные вызовы",
  "cleandex_calls": "Cleandex звонки",
  "cleandexCalls": "Cleandex звонки",
  "manualCalls": "Ручные вызовы",
  "inactive": "Неактивный",
  "on hold": "На удержании",
  "sequential": "Последовательный",
  "ratio": "Соотношение",
  "must_choose": "Должен выбрать",
  "surveyApp": "Приложение для опросов",
  "installation": "Установка",
  "manager": "Менеджер",
}

export const es = {
  ...serviks.es,
  monthMap: {
    1: 'Enero',
    2: 'Febrero',
    3: 'Marzo',
    4: 'Abril',
    5: 'Mayo',
    6: 'Junio',
    7: 'Julio',
    8: 'Agosto',
    9: 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre',
  },
  weekMap: {
    1: 'Domingo',
    2: 'Lunes',
    3: 'Martes',
    4: 'Miércoles',
    5: 'Jueves',
    6: 'Viernes',
    7: 'Sábado',
  },
  "notified": "notificado",
  "supervisor_arrival": "llegada del supervisor",
  "arrival": "llegada",
  "filter": "filtro",
  "logo": "logo",
  "counter": "contador",
  "sms": "SMS",
  "shifts": "turno",
  "call": "llamada",
  "duration": "duración",
  "time_start": "hora de inicio",
  "settings": "Ajustes",
  "working_area": "área de trabajo",
  "complaints_NC": "quejas fuera del contador",
  "SLA_NC": "SLA fuera del contador",
  "arrivals_NC": "llegadas fuera del contador",
  "staff": "personal",
  "cleaning_complaints": "Quejas sobre la persona de limpieza",
  "export": "exportar",
  "maintenance_complaints": "quejas de mantenimiento",
  "maintenanceArrivals": "Reparaciones de mantenimiento",
  "maintenance_arrivals": "Llegadas de mantenimiento",
  "last": "último",
  "survey_name": "nombre de la inspección",
  "survey": "Inspección",
  "pad_survey": "Inspección para pad",
  "pad_kitchen": "cocina  para pad",
  "number_of_assigned_devices": "número de dispositivos asignados",
  "number_of_pages": "número de páginas",
  "date_created": "fecha de creación",
  "updated_by": "actualizado por",
  "date_updated": "fecha de actualización",
  "search": "buscar / búsqueda",
  "choose_type": "elegir tipo",
  "permitted_sites": "lugares permitidos",
  "permitted_franchise": "franquicias permitidas",
  "permitted_companies": "empresas autorizadas",
  "panel_permitted": "panel autorizado",
  "end_time": "hora de finalización",
  "last_update": "última actualización",
  "add_device": "agregar dispositivo",
  "detailed": "detallado",
  "analysis": "análisis",
  "suggestion": "sugerencia",
  "country": "país",
  "management": "gestión",
  "device": "dispositivo",
  "mapping": "mapeo",
  "please_enter": "por favor, introduzca",
  "new_device": "nuevo dispositivo",
  "assign_device": "asignar dispositivo",
  "installSpot": "lugar de instalación",
  "device_type": "tipo de dispositivo",
  "deviceCode": "código de dispositivo",
  "device_code": "código de dispositivo",
  "room": "sala",
  "edit_room": "editar sala",
  "edit_franchise": "editar franquicia",
  "new_franchise": "nueva franquicia",
  "desc": "descripción",
  "address": "dirección",
  "level": "nivel",
  "no_companies_to_show": "no hay empresas para mostrar",
  "company_type": "tipo de empresa",
  "region": "región",
  "new_company": "nueva empresa",
  "edit_company": "editar empresa",
  "edit_user": "editar usuario",
  "C.arrivals": "llegó persona de limpieza",
  "C.complaints": "queja de limpieza",
  "M.complaints": "llegó la persona de mantenimiento",
  "M.Arrivals": "quejas de mantenimiento",
  "analyses": "análisis",
  "clean_log": "limpiar registro",
  "maintenance_log": "registro de mantenimiento",
  "like": "me gusta",
  "new_user": "nuevo usuario",
  "shift_parameters": "parámetro de turno",
  "add_shift": "agregar turno",
  "edit_shift": "editar turno",
  "username": "nombre de usuario",
  "first_name": "primer nombre",
  "firstName": "primer nombre",
  "description": "descripción",
  "last_name": "apellido",
  "lastName": "apellido",
  "phone": "teléfono",
  "building_area": "área de edificio",
  "users": "usuario",
  "add_user": "agregar usuario",
  "sites": "lugares",
  "new_site": "nuevo lugar",
  "edit_site": "editar lugar",
  "add_company": "agregar empresa",
  "franchise": "franquicia",
  "floor": "Piso",
  "building": "edificio",
  "gender": "género",
  "display_name": "nombre",
  "zone": "Zona",
  "shift_number": "turno número",
  "shift_name": "nombre del turno",
  "end": "fin",
  "min_sla": "Tiempo de respuesta mínimo",
  "max_sla": "Tiempo de respuesta máximo",
  "days": "días",
  "complaints_percent": "% quejas",
  "sla_percent": "% Tiempo de respuesta",
  "arrivals_percent": "% llegadas",
  "save_arrivals": "guardar",
  "company_name": "nombre de la empresa",
  "site_name": "nombre del lugar",
  "site_desc": "descripción del lugar",
  "service_provider_name": "proveedor de servicio",
  "service_provider_type": "tipo de profedor de servicio",
  "service_provider_desc": "descripción de proveedor de servicio",
  "user": "usuario",
  "map": "mapa",
  "delete": "eliminar",
  "new_room": "nueva sala",
  "issue_times": "Tiempos de emisión",
  "solved": "resuelto",
  "visits": "visitas",
  "complaints": "quejas",
  "cleaner_arrivals": "llegadas de personas de limpieza",
  "response_time_SLA": "tiempo de respuesta",
  "score": "puntaje",
  "avg_score": "puntaje promedio",
  "score_goal": "meta de puntale",
  "top_problem_issues": "principales problemas",
  "top_problem_rooms": "principales salas",
  "top_problem_hours": "principales horas",
  "is_time_save": "tiempo ahorrado",
  "est_ROI": "retorno de Inversión estimado",
  "complaints_vs_visits": "quejas Vs visitas",
  "view_by_years": "ver años",
  "view_by_months": "ver meses",
  "view_by_weeks": "ver semanas",
  "view_by_days": "ver días",
  "minutes": "minutos",
  "company": "empresa",
  "site": "lugar",
  "alerts": "Información de dispositivos",
  "performance": "desempeño",
  "devices": "dispositivos",
  "surveys": "Inspecciones",
  "real_time": "tiempo real",
  "real-time": "tiempo real",
  "admin": "administrador",
  "all": "todo",
  "overview": "información general",
  "analytics": "analítica",
  "dayOfWeek": "Días",
  "service_log": "registro de servicio",
  "rooms": "Cuartos",
  "displayName": "Nombre del cuarto",
  "SLA": "SLA",
  "supervisors": "supervisores",
  "supervisor": "supervisor",
  "performance_analytics": "Analítica de desempeño",
  "arrivals": "Llegadas",
  "inspections": "Inspecciones",
  "avgResponseTime": "Tiempo de respuesta promedio",
  "missedSla": "Sla perdido",
  "cleaner_arrived": "Llegó la persona de limpieza",
  "load_more": "Cargar más",
  "login": "Iniciar sesión",
  "email": "Correo electrónico",
  "emails": "Correos electrónicos",
  "password": "Contraseña",
  "min": "min",
  "Logout": "Cerrar sesión",
  "custom_time": "Elegir fecha / Escoger horarios",
  "last_day": "Último día",
  "last_week": "Última semana",
  "last_month": "Último mes",
  "last_quarter": "Último trimestre",
  "last_6_months": "Últimos 6 meses",
  "last_year": "Último año",
  "data_by_hour": "Datos por horas",
  "general": "General",
  "cleaning": "Limpieza",
  "maintenance": "Mantenimiento",
  "start": "Inicio",
  "start_time": "hora de inicio",
  "management_co": "empresa de gestión",
  "cleaning_co": "empresa de limpieza",
  "companies": "empresas",
  "name": "nombre",
  "site_count": "# lugar",
  "associated_companies": "clientes finales asociados",
  "OK": "OK",
  "cancel": "cancelar",
  "client": "cliente",
  "dates": "fecha",
  "shift": "turno",
  "issue": "problema",
  "time": "tiempo",
  "top complaint of the week": "principal queja de la semana",
  "top room of the week": "principal sala de la semana",
  "top hour of the week": "principal hora de la semana",
  "top complaint of the month": "principal queja del mes",
  "top room of the month": "principal sala del mes",
  "top hour of the month": "principal hora del mes",
  "franchises": "franquicias",
  "add_franchise": "agregar franquicias",
  "room_names": "nombre de la sala",
  "type": "tipo",
  "please_select_company_site": "por favor elije una empresa y lugar",
  "number": "número",
  "create_building_map": "crear un mapa del lugar",
  "hey quickest way to add floors and rooms to a buildings via this quick wizard": "La forma más rápida de agregar pisos y habitaciones a un edificio es a través de este asistente rápido",
  "avg number of toilets per floor": "Promedio de baños por piso",
  "avg.number of toilet cells per room": "Promedio de inodoros por baño",
  "number of floors": "número de pisos",
  "avg.number of disabled toilets": "Promedio de baños desabilitados",
  "avg.number of soap dispensers": "Promedio de dispensadores de jabón",
  "reset_filter": "Restablecer filtro",
  "add": "agregar",
  "new_survey": "Nueva inspección",
  "api_configuration": "Configuración de API",
  "please_edit_the_api_information": "Por favor edite la información de la API",
  "method": "método",
  "authorization": "autorización",
  "api_url": "api_url",
  "token": "token",
  "api_request_info": "Información requerida de la API",
  "roomName": "Nombre del cuarto",
  "servikName": "Nombre de la queja",
  "siteName": "Nombre del sitio",
  "stage": "Etapa",
  "number_of_buttons": "Número de botonoes",
  "button_shape": "Forma del botón",
  "delay_time_stage": "Etapa de tiempo de retardo",
  "go_to_stage": "ir a la etapa",
  "color": "Color",
  "add_stage": "Agregar Etapa",
  "save": "Guardar",
  "number_of_issues": "número de problemas",
  "online": "conectado",
  "offline": "descontectado",
  "room_name": "nombre del cuarto",
  "installation_spot": "lugar",
  "device_id": "identificador del dispositivo",
  "date": "fecha",
  "status": "estado",
  "device_status": "estado del dispositivo",
  "none": "ninguno",
  "no_surveys_created": "No se han creado inspecciones",
  "dashboard": "Panel de control",
  "franchise_name": "Nombre de la Franquicia",
  "franchise_country": "País de la Franquicia",
  "choose_franchise": "Elige Franquicia",
  "choose_country": "Elige País",
  "select_template": "Seleccione Plantilla",
  "default_survey": "Inspección predeterminada",
  "cleaning_survey": "Inspección de limpieza",
  "kitchen_survey": "Inspección de cocina",
  "template": "Plantilla",
  "area_name": "Nombre del área",
  "rooms_names": "Nombres de los cuartos",
  "rooms_number": "Cuartos #",
  "assign_number": "Asignar #",
  "active_notification_task": "Activar notificaciones de tarea",
  "amount_limit": "Contador de intervalo",
  "after_max": "Contador después del máximo",
  "new_area": "nueva área",
  "tablet": "tables",
  "counter women": "Contador de mujeres",
  "counter men": "Contador de hombres",
  "counter handicap": "Contador de discapacitados",
  "counter man/woman": "Contador Hombre / Mujer",
  "counter man / woman": "Contador Hombre / Mujer",
  "sensor liquid": "Sensor de líquido",
  "double toilet paper": "Papel Higiénico Doble",
  "toilet paper": "Palel Higiénico",
  "tag": "Marcador",
  "qr tag": "etiqueta QR",
  "additional_info": "información adicional",
  "servikCategory": "tipo de llamada",
  "dishwashing": "Lavaplatos",
  "cafeteria": "Caferería",
  "clean room": "Cuarto de limpieza",
  "office": "Oficina",
  "meeting room": "Sala de reuniones",
  "cubic": "Cúbico",
  "women": "Mujer",
  "men": "Hombre",
  "handicap": "Discapacidad",
  "Combined": "Combinada",
  "kitchen": "Cocina",
  "east": "Este",
  "west": "Oeste",
  "north": "Norte",
  "south": "Sur",
  "right": "Derecha",
  "left": "Izquierda",
  "center": "Centro",
  "up": "Arriba",
  "down": "Abajo",
  "combined": "Combinado",
  "lobby": "Lobby",
  "entrance": "Entrada",
  "reception": "Recepción",
  "area": "Área",
  "mezzanine": "Mezzanine",
  "gallery": "Galería",
  "ground": "Suelo",
  "elevator": "Ascensor",
  "is_calculated": "es calculado",
  "calculated_room": "Habitación calculada",
  "splitted_survey": "encuesta dividida",
  "redirect_to_url": "Redirigir a URL",
  "survey_site": "Sitio de la encuesta",
  "clicks": "clics",
  "add_stage_number_to_signal": "adjuntar el número de etapa",
  "including_pad9": "incluyendo pad9",
  "default_name": "Nombre en ingles",
  "exposures": "exposições",
  "expose_results": "exponer los resultados",
  "events_expose": "eventos exponen",
  "expose_score": "exponer el puntaje",
  "shift_events_expose": "desplazar eventos y exponer",
  "type_of_site": "tipo de sitio",
  "sector": "sector",
  "last_time_of_arrival": "última hora de llegada",
  "arrival_and_status": "llegada & estado",
  "preview_arrivals": "vista previa de llegadas",
  "men toilet": "baño de hombres",
  "women toilet": "baño de mujeres",
  "shared toilet": "baño compartido",
  "handicapped toilet": "baño para discapacitados",
  "morning_shift": "turno de mañana",
  "afternoon_shift": "Turno de la tarde",
  "night_shift": "Turno nocturno",
  "update": "actualizar",
  "restart": "reiniciar",
  "send_data": "enviar datos",
  "send_count": "enviar contador",
  "blink": "parpadeo",
  "maintenance_btn": "botón de mantenimiento",
  "battery": "batería",
  "version": "versión",
  "actions": "acciones",
  "drains_battery": "agota la batería",
  "latest_version": "última versión",
  "no_errors": "no hay errores",
  "offline_calls": "Alertas fuera de línea",
  "offline_duration_calls": "Duración de las alertas sin conexión",
  "counterCalls": "Contador de llamadas",
  "active": "activo",
  "active_calculation": "calculo activo",
  "pad": "pad",
  "pad_counter": "contador pad",
  "counter_status": "estado del contador",
  "choose_state": "Elige Estado",
  "city": "Ciudad",
  "state": "Estado",
  "choose_city": "Elige Ciudad",
  "start_hour": "Hora de inicio",
  "end_hour": "Hora de finalización",
  "analytics_log": "Registro de análisis",
  "or": "o",
  "no_data_available": "No hay datos disponibles",
  "new_task": "Nueva tarea",
  "category": "Categoría",
  "title": "Título",
  "task_type": "Tipo de tarea",
  "task_category": "Categoría de tarea",
  "call_to_action": "Llamar a la acción",
  "schedule": "Horario",
  "insert_text": "Insertar texto",
  "select": "Seleccionar",
  "schedule_dates": "Fechas de programación",
  "language": "Idioma",
  "calendar": "Calendario",
  "operation": "Operación",
  "daily": "Diario",
  "weekly": "Semanal",
  "monthly": "Mensual",
  "quarterly": "Trimestral",
  "yearly": "Anual",
  "sunday": "Domingo",
  "monday": "Lunes",
  "tuesday": "Martes",
  "wednesday": "Miércoles",
  "thursday": "Jueves",
  "friday": "Viernes",
  "saturday": "Sábado",
  "january": "Enero",
  "february": "Febrero",
  "march": "Marzo",
  "april": "Abril",
  "may": "Mayo",
  "june": "Junio",
  "july": "Julio",
  "august": "Agosto",
  "september": "Septiembre",
  "october": "Octubre",
  "november": "Noviembre",
  "december": "Diciembre",
  "1st week": "1ª semana",
  "2nd week": "2ª semana",
  "3rd week": "3ª semana",
  "4th week": "4ª semana",
  "5th week": "5ª semana",
  "1st quarter": "1º trimestre",
  "2nd quarter": "2º trimestre",
  "3rd quarter": "3º trimestre",
  "4th quarter": "4º trimestre",
  "edit_task": "Editar tarea",
  "created_at": "Creado en",
  "hour": "Hora",
  "week_days": "Días de la semana",
  "month_weeks": "Semanas del mes",
  "month_days": "Días del mes",
  "quarter_months": "Meses del trimestre",
  "invalid_language": "Idioma inválido",
  "no_active_tasks_found": "No se encontraron tareas activas",
  "edit": "Editar",
  "send_date": "Fecha de envío",
  "invalid_date": "Fecha inválida",
  "cells_num": "# de celdas",
  "sinks_num": "# de lavabos",
  "urinals_num": "# de urinarios",
  "toilet_paper_size": "Tamaño del papel higiénico",
  "email_configuration": "Configuración de correo electrónico",
  "please_edit_the_email_information": "Por favor, edite la información del correo electrónico",
  "email_sender_info": "Información del remitente del correo electrónico",
  "email_subject_info": "Información del asunto del correo electrónico",
  "email_message_info": "Información del mensaje de correo electrónico",
  "companyName": "Nombre de la empresa",
  "email_language": "Idioma del correo electrónico",
  "complaint_language": "Idioma de la queja",
  "email_message_format": "Formato del mensaje de correo electrónico",
  "line": "Línea",
  "list": "Lista",
  "roomType": "Tipo de habitación",
  "By logging in, you agree to Buzzztech's": "Al iniciar sesión, acepta los Buzzztech",
  "Terms and Conditions": "Términos y condiciones",
  "and": "y",
  "Privacy Policy": "Política de privacidad",
  "All actions within this panel are recorded for quality control and improvement": "Todas las acciones dentro de este panel se registran para control de calidad y mejora",
  "You must agree to the terms and conditions": "Debe aceptar los términos y condiciones",
  "months": "Meses",
  "ref_id": "ID de referencia",
  "room_name_en": "Nombre de la habitación en inglés",
  "site_state": "Estatal del sitio",
  "site_city": "Ciudad del sitio",
  "site_country": "Sitio País",
  "first_date": "Primera fecha",
  "pinned": "Fijado",
  "no.": "Nº",
  "must_be_numbers": "Debe ser un número",
  "must_be_3_digits": "Debe tener 3 dígitos",
  "must_have_english_title": "Debe tener un título en inglés",
  "total_sites": "Total",
  "site_information": "Información del sitio",
  "holiday_offdays": "Días festivos y libres",
  "add_offday": "Día libre",
  "add_holiday": "Día festivo",
  "name_under_chars": "nombre de menos de 25 caracteres",
  "days_comma_separated": "días válidos, separados por una coma",
  "year": "año",
  "month": "mes",
  "day": "día",
  "holiday_name": "nombre de vacaciones",
  "offday_name": "nombre del día libre",
  "israel": "Israel",
  "USA": "EE.UU",
  "poland": "Polonia",
  "mexico": "México",
  "cyprus": "Chipre",
  "brazil": "Brasil",
  "tablet_survey": "Encuesta de tableta",
  "no_shift_survey": "Encuesta de turno",
  "number_of_rows": "Número de filas",
  "rating_shape": "Forma de calificación",
  "content_survey": "Encuesta de contenido",
  "rating_survey": "Encuesta de calificación",
  "device-alerts": "Problema",
  "device-active": "Activo",
  "device-search": "Buscar",
  "mac_id": "Dirección MAC",
  "qr_tag": "Etiqueta QR",
  "sensor": "Sensor",
  "image_survey": "Encuesta de imagen",
  "open_text": "Texto abierto",
  "open_text_survey": "Encuesta de texto abierto",
  "embedded_link": "Enlace incrustado",
  "device-counter": "Contadores",
  "count": "Contar",
  "last_date": "Ultima cita",
  "last_arrival":"Llegada del último limpiador",
  "cleaners": "Limpiadores",
  "viewer": "Espectador",
  "android": "Android",
  "ios": "iOS",
  "no_users_found": "No se encontraron usuarios",
  "no_cleaners_found": "No se encontraron limpiadores",
  "new_cleaner": "Nuevo limpiador",
  "edit_cleaner": "Editar limpiador",
  "please_select": "Por favor seleccione",
  "working_areas": "Áreas de trabajo",
  "isWorkingInSite": "Está trabajando en el sitio",
  "you_are_not_allowed_to_edit": "No tienes permiso para editar",
  "you_are_not_allowed_to_remove": "No tienes permiso para eliminar",
  "task": "Tarea",
  "portuguese": "Portugués",
  "russian": "Ruso",
  "spanish": "Español",
  "polish": "Polaco",
  "installer": "Instalador",
  "once": "Una vez",
  "time_saved": "Tiempo ahorrado",
  "yes": "Sí",
  "no": "No",
  "workingAreaNames": "Nombres de áreas de trabajo",
  "shift_rooms":"Habitaciones",
  "new_shift":"Nuevo turno",
  "add_shift_inactive": "Agregar turnos reflectantes",
  "remove_auto_shift_inactive": "Eliminar turnos reflectantes automatizados",
  "additional_options": "Opciones adicionales",
  "add_default_shifts": "Agregar turnos predeterminados",
  "no_user_found": "No se encontró usuario",
  "no_site_found": "No se encontró sitio",
  "service_provider": "Proveedor de servicios",
  "qr task": "Tarea QR",
  "qr_task": "Tarea QR",
  "all_day": "Todo el día",
  "auto_start": "Inicio automático",
  "english_title": "Título en inglés",
  "connectivity": "Conectividad",
  "power": "Energía",
  "Your work has been saved": "Tu trabajo ha sido guardado",
  "schedule_call_time": "Hora de llamada",
  "schedule_call_gap": "Intervalo de llamada",
  "gapInMinutes": "Intervalo en minutos",
  "no_manual_calls_available": "No hay llamadas manuales disponibles",
  "manual_calls": "Llamadas manuales",
  "cleandex_calls": "Llamadas de Cleandex",
  "cleandexCalls": "Llamadas de Cleandex",
  "manualCalls": "Llamadas manuales",
  "inactive": "Inactivo",
  "on hold": "En espera",
  "sequential": "Secuencial",
  "ratio": "Proporción",
  "must_choose": "Debes elegir",
  "surveyApp": "Aplicación de encuestas",
  "installation": "Instalación",
  "manager": "Gerente",
}

export const ar = {
  ...serviks.ar,
  monthMap: {
    1: "يناير",
    2: "فبراير",
    3: "مارس",
    4: "ابريل",
    5: "مايو",
    6: "يونيو",
    7: "يوليو",
    8: "اغسطس",
    9: "سبتمبر",
    10: "اوكتوبر",
    11: "نوفمبر",
    12: "ديسمبر",
  },
  weekMap: {
    1: 'الاحد',
    2: 'الاثنين',
    3: 'الثلاثاء',
    4: 'الاربعاء',
    5: 'الخميس',
    6: 'الجمعة',
    7: 'السبت',
  },
  "notified": "تنبيه",
  "supervisor_arrival": "وصول المشرف",
  "arrival": "وصول",
  "filter": "ترشيح",
  "logo": "لوغو",
  "counter": "عدّاد",
  "sms": "رسالة نصية",
  "shifts": "ورديات",
  "call": "طلب",
  "duration": "وقت منذ الافتتاح",
  "time_start": "وقت البداية",
  "settings": "تعريفات",
  "working_area": "منطقة عمل",
  "complaints_NC": "شكاوى بدون عداد",
  "SLA_NC": "SLA بدون عداد",
  "arrivals_NC": "مرات وصول بدون عدّاد",
  "staff": "طاقم",
  "cleaning_complaints": "شكاوى نظافة",
  "export": "إخراج",
  "maintenance_complaints": "شكوى صيانه",
  "maintenanceArrivals": "تصليحات صيانه",
  "maintenance_arrivals": "مرات الوصول للصيانة",
  "last": "الاخير",
  "survey_name": "تقرير",
  "survey": "اسم التقرير",
  "pad_survey": "تقرير للباد",
  "pad_kitchen": "تقرير مطبخ للباد",
  "number_of_assigned_devices": "عدد الاجهزة",
  "number_of_pages": "عدد الصفحات",
  "date_created": "تاريخ الإنشاء",
  "updated_by": "تم التحديث بواسطة",
  "date_updated": "تاريخ التحديث",
  "search": "بحث",
  "choose_type": "اختر نوع",
  "permitted_sites": "اذونات للمواقع",
  "permitted_franchise": "إذن لاصحاب الامتياز",
  "permitted_companies": "إذن للشركات",
  "panel_permitted": "إذن للوحة",
  "end_time": "وقت الانهاء",
  "last_update": "التحديث الاخير",
  "add_device": "اضف جهازا",
  "detailed": "تفصيل",
  "analysis": "تحليل",
  "suggestion": "اقتراحات",
  "country": "دولة",
  "management": "ادارة",
  "device": "اجهزة",
  "mapping": "مسح",
  "please_enter": "ادخل/ي رجاءا",
  "new_device": "جهازا جديدا",
  "assign_device": "اوصل جهازا",
  "installSpot": "مكان التركيب",
  "device_type": "نوع الجهاز",
  "deviceCode": "كود الجهاز",
  "device_code": "كود الجهاز",
  "room": "غرفة",
  "edit_room": "عدّل الغرفة",
  "edit_franchise": "عدّل صاحب الامتياز",
  "new_franchise": "اضف صاحب امتياز",
  "desc": "وصف",
  "address": "بريد الكتروني",
  "level": "مستوى",
  "no_companies_to_show": "لا شركات العرض",
  "company_type": "نوع الشركة",
  "region": "منطقة",
  "new_company": "شركة جديدة",
  "edit_company": "تعديل شركة",
  "edit_user": "تعديل مستخدم",
  "C.arrivals": "زيارات عمال النظافة",
  "C.complaints": "شكاوى نظافة",
  "M.complaints": "شكاوى الصيانة",
  "M.Arrivals": "زيارات الصيانة",
  "analyses": "تحليل بدون غرف",
  "clean_log": "مفكرة النظافة",
  "maintenance_log": "مفكرة الصيانة",
  "like": "لايك",
  "new_user": "مستخدم جديد",
  "shift_parameters": "مواصفات الوردية",
  "add_shift": "اضف وردية",
  "edit_shift": "تعديل وردية",
  "username": "اسم المستخدم",
  "first_name": "الاسم السخصي",
  "firstName": "الاسم السخصي",
  "description": "وصف",
  "last_name": "اسم العائلة",
  "lastName": "اسم العائلة",
  "phone": "الهاتف",
  "building_area": "منطقة البناء",
  "users": "المستخدمين",
  "add_user": "اضافة مستخدم",
  "sites": "مواقع",
  "new_site": "موقع جديد",
  "edit_site": "تعديل موقع",
  "add_company": "اضف شركة",
  "franchise": "الامتياز",
  "floor": "الطابق",
  "building": "البناية",
  "gender": "الجنس",
  "display_name": "الاسم",
  "zone": "المنطقة",
  "shift_number": "رقم الوردية",
  "shift_name": "اسم الوردية",
  "end": "النهاية",
  "min_sla": "وقت الاستجابة الادنى",
  "max_sla": "وقت الاستجابة الاقصى",
  "days": "ايام",
  "complaints_percent": "شكاوى %",
  "sla_percent": "وقت الاستجابة %",
  "arrivals_percent": "% وصول عاملة النظافة",
  "save_arrivals": "توفير",
  "company_name": "اسم الشركة",
  "site_name": "اسم الموقع",
  "site_desc": "وصف الموقع",
  "service_provider_name": "مورد الخدمة",
  "service_provider_type": "نوع مورد الخدمة",
  "service_provider_desc": "وصف مورد الخدمة",
  "user": "مستخدم",
  "map": "خارطة",
  "delete": "حذف",
  "new_room": "غرفة جديدة",
  "issue_times": "شكاوى واغلاقات",
  "solved": "تم الحل",
  "visits": "الزيارات",
  "complaints": "الشكاوى",
  "cleaner_arrivals": "مرات وصول عاملة النظافة",
  "response_time_SLA": "(SLA) وقت الاستجابة",
  "score": "العلامة",
  "avg_score": "متوسط العلامات",
  "score_goal": "العلامة المطلوبة",
  "top_problem_issues": "الشكاوى الاساسية",
  "top_problem_rooms": "الغرف الاساسية",
  "top_problem_hours": "الساعات الاساسية",
  "is_time_save": "الوقت الذي تم توفيره",
  "est_ROI": "(ROI) استعادة الاستثمار المقدّر",
  "complaints_vs_visits": "الشكاوى مقارنة بالزيارات",
  "view_by_years": "عرض السنوات",
  "view_by_months": "عرض الشهور",
  "view_by_weeks": "عرض الاسابيع",
  "view_by_days": "عرض الايام",
  "minutes": "الدقائق",
  "company": "الشركة",
  "site": "الموقع",
  "alerts": "معلومات الأجهزة",
  "performance": "الأداء",
  "devices": "اجهزة",
  "surveys": "تقارير",
  "real_time": "الوقت الحقيقي",
  "real-time": "الوقت الحقيقي",
  "admin": "المدير",
  "all": "الكل",
  "overview": "مسح عام",
  "analytics": "تحليل",
  "dayOfWeek": "ايام",
  "service_log": "تفاصيل الغرفة",
  "rooms": "الغرف",
  "displayName": "اسم الغرفة",
  "SLA": "SLA",
  "supervisors": "المشرفين",
  "supervisor": "المشرف",
  "performance_analytics": "مسح الغرفة",
  "arrivals": "عاملة النظافة",
  "inspections": "المراقبة",
  "avgResponseTime": "متوسط وقت الاستجابة",
  "missedSla": "التاخيرات",
  "cleaner_arrived": "وصول عامل/ة النظافة",
  "load_more": "حمل المزيد",
  "login": "اتصل/ي",
  "email": "بريد الكتروني",
  "emails": "عناوين البريد الالكتروني",
  "password": "الرمز",
  "min": "`دق`",
  "Logout": "انفصال",
  "custom_time": "حدد التاريخ",
  "last_day": "اليوم الاخير",
  "last_week": "الاسبوع الاخير",
  "last_month": "الشهر الاخير",
  "last_quarter": "ربع السنة الاخير",
  "last_6_months": "نصف السنة الاخيرة",
  "last_year": "السنة الاخيرة",
  "data_by_hour": "البيانات حسب الساعة",
  "general": "عام",
  "cleaning": "النظافة",
  "maintenance": "صيانة",
  "start": "فتح",
  "start_time": "وقت البداية",
  "management_co": "مكافحة الإدارة،",
  "cleaning_co": "تنظيف حفرة،",
  "companies": "الشركات،",
  "name": "الاسم",
  "site_count": "المواقع #",
  "associated_companies": "الشركات المرتبطة",
  "OK": "موافقة",
  "cancel": "الغاء",
  "client": "زبون",
  "dates": "التواريخ",
  "shift": "الوردية",
  "issue": "المشكلة",
  "time": "الوقت",
  "top complaint of the week": "الغرفة الاكثر اشكالية هذا الشهر",
  "top room of the week": "الغرفة الاكثر اشكالية هذا الاسبوع",
  "top hour of the week": "الساعة الاكثر اشكالية هذا الاسبوع",
  "top complaint of the month": "الشكوى الاكثر اشكالية هذا الشهر",
  "top room of the month": "الغرفة الاكثر اشكالية هذا الشهر",
  "top hour of the month": "الساعة الاكثر اشكالية هذا الشهر",
  "franchises": "الامتياز",
  "add_franchise": "إضافة الفوضوي",
  "room_names": "اسم الغرفة",
  "type": "النوع",
  "please_select_company_site": "يرجى اختيار شركة وموقع",
  "number": "ارقم",
  "create_building_map": "إنشاء تعيين لبناء",
  "hey quickest way to add floors and rooms to a buildings via this quick wizard": "مرحبا، الطريقة الاسرع لانشاء الغرف، الطوابق والبنايات بمساعدة المعالج السريع التالي",
  "avg number of toilets per floor": "متوسط ​​الحمامات على الأرض",
  "avg.number of toilet cells per room": "متوسط خلايا المراحيض في اغرفة",
  "number of floors": "عدد الطوابق",
  "avg.number of disabled toilets": "متوسط مراحيض ذوي الاعاقة في الطابق",
  "avg.number of soap dispensers": "متوسط مرافق الصابون في الغرفة",
  "reset_filter": "تنظيف الترشيح",
  "add": "اضافة",
  "new_survey": "تقرير جديد",
  "api_configuration": "تعريفت API",
  "please_edit_the_api_information": "تعديل تفاصيل API",
  "method": "نوع الطلب",
  "authorization": "التصريح",
  "api_url": "عنوان الطلب",
  "token": "مفتاح",
  "api_request_info": "معلومات للارسال",
  "roomName": "اسم الغرفة",
  "servikName": "اسم الشكوى",
  "siteName": "اسم الموقع",
  "stage": "الصفحة",
  "number_of_buttons": "عدد الازرار",
  "button_shape": "شكل الزر",
  "delay_time_stage": "وقت الانتظار",
  "go_to_stage": "انتقل الى الصفحة",
  "color": "اللون",
  "add_stage": "اضف صفحة",
  "save": "حفظ التقرير",
  "number_of_issues": "عدد الشكاوى",
  "online": "نشط",
  "offline": "غير نشط",
  "room_name": "اسم الغرفة",
  "installation_spot": "المكان",
  "device_id": "معرف الجهاز",
  "date": "التاريخ",
  "status": "الوضع",
  "device_status": "وضع الجهاز",
  "none": "لا يوجد",
  "no_surveys_created": "لا يوجد تقارير",
  "dashboard": "لوحة المراقبة",
  "franchise_name": "اسم صاحب الامتياز",
  "franchise_country": "دولة صاحب الامتياز",
  "choose_franchise": "اختر صاحب امتياز",
  "choose_country": "اختر دولة",
  "select_template": "اختر قالبا",
  "default_survey": "التقرير الافتراضي",
  "cleaning_survey": "تقرير النظافة",
  "kitchen_survey": "تقرير المطبخ",
  "template": "قالب",
  "area_name": "اسم التوزيع",
  "rooms_names": "اسماء الغرف",
  "rooms_number": "الغرف #",
  "assign_number": "المسؤول #",
  "active_notification_task": "تنبيهات النشاط",
  "amount_limit": "حد العد",
  "after_max": "حد التنبيه",
  "new_area": "منطقة عمل جديدة",
  "tablet": "تابلت",
  "counter women": "عدّاد نساء",
  "counter men": "عدّاد رجال",
  "counter handicap": "عدّاد ذوي الاعاقة",
  "counter man/woman": "عدّاد مدمج",
  "counter man / woman": "عدّاد مدمج",
  "sensor liquid": "جهاز استشعار سوائل",
  "double toilet paper": "جهاز استشعار ورق مزدوج",
  "toilet paper": "جهاز استشعار ورق",
  "tag": "تاج",
  "qr tag": "كود QR",
  "additional_info": "معلومات اخرى للإرسال",
  "servikCategory": "نوع الطلب",
  "dishwashing": "جلاية",
  "cafeteria": "كافيتيريا",
  "clean room": "غرفة نظيفة",
  "office": "مكتب",
  "meeting room": "غرفة اجتماعات",
  "cubic": "كيوبيك",
  "women": "نساء",
  "men": "رجال",
  "handicap": "معاقون",
  "Combined": "مدمج",
  "kitchen": "مطبخ",
  "east": "شرق",
  "west": "غرب",
  "north": "شمال",
  "south": "جنوب",
  "right": "يمين",
  "left": "يسار",
  "center": "المركز",
  "up": "للاعلى",
  "down": "للاسفل",
  "combined": "مدمج",
  "lobby": "لوبي",
  "entrance": "مدخل",
  "reception": "استقبال",
  "area": "المنطقة",
  "mezzanine": "الميزاني",
  "gallery": "المعرض",
  "ground": "الارضية",
  "elevator": "المصاعد",
  "is_calculated": "حساب الغرفة",
  "calculated_room": "حساب الغرفة",
  "splitted_survey": "تقرير مقسّم",
  "redirect_to_url": "ارشد الى العنوان",
  "survey_site": "منطقة التقرير",
  "clicks": "نقرات",
  "add_stage_number_to_signal": "اضف رقم المنصة الى الاشارة",
  "including_pad9": "يشمل Pad9.",
  "default_name": "الاسم الافتراضي.",
  "type_of_site": "نوع المنطقة",
  "No companies to show": "لا توجد شركات لعرضها",
  "add franchise": "اضف صاحبة امتياز",
  "room name": "اسم الغرفة",
  "please select a company & site": "يرجى اختيار الشركة والموقع",
  "create building map": "انشئ مسحا للبناية",
  "avg.number of toilets per floor": "متوسط غرف الحمامات في الطابق",
  "management co.": "شركة الادارة،",
  "cleaning co.": "شركة النظافة،",
  "exposures": "تعرضات",
  "expose_results": "عدد التعرضات",
  "events_expose": "الأحداث والتعرضات",
  "expose_score": "نتيجة التعرض",
  "shift_events_expose": "تحولات التعرض والأحداث",
  "sector": "قطاع",
  "last_time_of_arrival": "وقت الوصول الأخير",
  "arrival_and_status": "الوصول & الحالة",
  "preview_arrivals": "معاينة الوافدين",
  "men toilet": "مرحاض الرجال",
  "women toilet": "مرحاض النساء",
  "shared toilet": "مرحاض مشترك",
  "handicapped toilet": "مرحاض المعاقون",
  "morning_shift": "دوام صباحي",
  "afternoon_shift": "دوام بعد الظهر",
  "night_shift": "دوام ليلي",
  "update": "تحديث",
  "restart": "إعادة تشغيل",
  "send_data": "إرسال البيانات",
  "send_count": "إرسال العداد",
  "blink": "مومض",
  "maintenance_btn": "زر الصيانة",
  "battery": "بطارية",
  "version": "الإصدار",
  "actions": "إجراءات",
  "drains_battery": "يستنزف البطارية",
  "latest_errors": "آخر الأخطاء",
  "no_errors": "لا يوجد أخطاء",
  "offline_calls": "تنبيهات في وضع عدم الاتصال",
  "offline_duration_calls": "مدة التنبيهات في وضع عدم الاتصال",
  "counterCalls": "تنبيهات العداد",
  "active": "مفعل",
  "active_calculation": "حساب مفعل",
  "pad": "باد",
  "pad_counter": "عداد الباد",
  "counter_status": "حالة العداد",
  "choose_state": "اختر المحافظة",
  "city": "المدينة",
  "state": "المحافظة",
  "choose_city": "اختر المدينة",
  "start_hour": "بداية الساعة",
  "end_hour": "نهاية الساعة",
  "analytics_log": "سجل التحليلات",
  "or": "أو",
  "no_data_available": "لا توجد بيانات متاحة",
  "new_task": "مهمة جديدة",
  "category": "الفئة",
  "title": "العنوان",
  "task_type": "نوع المهمة",
  "task_category": "فئة المهمة",
  "call_to_action": "استدعاء الإجراء",
  "schedule": "الجدول الزمني",
  "insert_text": "أدخل النص",
  "select": "اختر",
  "schedule_dates": "تاريخ الجدول الزمني",
  "language": "اللغة",
  "calendar": "التقويم",
  "operation": "عملية",
  "daily": "يوميا",
  "weekly": "أسبوعيا",
  "monthly": "شهريا",
  "quarterly": "ربع سنويا",
  "yearly": "سنويا",
  "sunday": "الأحد",
  "monday": "الاثنين",
  "tuesday": "الثلاثاء",
  "wednesday": "الأربعاء",
  "thursday": "الخميس",
  "friday": "الجمعة",
  "saturday": "السبت",
  "january": "يناير",
  "february": "فبراير",
  "march": "مارس",
  "april": "أبريل",
  "may": "مايو",
  "june": "يونيو",
  "july": "يوليو",
  "august": "أغسطس",
  "september": "سبتمبر",
  "october": "أكتوبر",
  "november": "نوفمبر",
  "december": "ديسمبر",
  "1st week": "الأسبوع الأول",
  "2nd week": "الأسبوع الثاني",
  "3rd week": "الأسبوع الثالث",
  "4th week": "الأسبوع الرابع",
  "5th week": "الأسبوع الخامس",
  "1st quarter": "الربع الأول",
  "2nd quarter": "الربع الثاني",
  "3rd quarter": "الربع الثالث",
  "4th quarter": "الربع الرابع",
  "edit_task": "تعديل المهمة",
  "created_at": "تم الإنشاء في",
  "hour": "ساعة",
  "week_days": "أيام الأسبوع",
  "month_weeks": "أسابيع الشهر",
  "month_days": "أيام الشهر",
  "no_tasks_found": "لم يتم العثور على مهام",
  "invalid_language": "لغة غير صالحة",
  "no_active_tasks_found": "لم يتم العثور على مهام نشطة",
  "edit": "تعديل",
  "send_date": "تاريخ الإرسال",
  "invalid_date": "تاريخ غير صالح",
  "cells_num": "# الخلايا",
  "sinks_num": "# أحواض",
  "urinals_num": "# المباول",
  "toilet_paper_size": "حجم ورق الحمام",
  "email_configuration": "تكوين البريد الإلكتروني",
  "please_edit_the_email_information": "يرجى تعديل معلومات البريد الإلكتروني",
  "email_sender_info": "معلومات مرسل البريد الإلكتروني",
  "email_subject_info": "معلومات موضوع البريد الإلكتروني",
  "email_message_info": "معلومات رسالة البريد الإلكتروني",
  "companyName": "اسم الشركة",
  "email_language": "لغة البريد الإلكتروني",
  "complaint_language": "لغة الشكوى",
  "email_message_format": "تنسيق رسالة البريد الإلكتروني",
  "line": "خط",
  "list": "قائمة",
  "roomType": "نوع الغرفة",
  "By logging in, you agree to Buzzztech's": "بتسجيل الدخول ، فإنك توافق على شروط Buzzztech",
  "Terms and Conditions": "الشروط والأحكام",
  "and": "و",
  "Privacy Policy": "سياسة الخصوصية",
  "All actions within this panel are recorded for quality control and improvement": "يتم تسجيل جميع الإجراءات داخل هذا اللوح للتحكم في الجودة والتحسين",
  "You must agree to the terms and conditions": "يجب أن توافق على الشروط والأحكام",
  "months": "الشهور",
  "ref_id": "الرقم المرجع",
  "room_name_en": "اسم الغرفة باللغة الإنجليزية",
  "site_state": "ولاية الموقع",
  "site_city": "مدينة الموقع",
  "site_country": "دولة الموقع",
  "first_date": "الموعد الاول",
  "pinned": "مثبت",
  "no.": "رقم",
  "must_be_numbers": "يجب أن يكون رقمًا",
  "must_be_3_digits": "يجب أن يكون 3 أرقام",
  "must_have_english_title": "يجب أن يكون له عنوان إنجليزي",
  "total_sites": "المجموع",
  "site_information": "معلومات الموقع",
  "holiday_offdays": "أيام الإجازات والأعياد",
  "add_offday": "يوم الإجازة",
  "add_holiday": "الأعياد",
  "name_under_chars": "اسم أقل من 25 حرفًا",
  "days_comma_separated": "أيام صالحة ، مفصولة بفاصلة",
  "year": "السنة",
  "month": "الشهر",
  "day": "اليوم",
  "holiday_name": "اسم العطلة",
  "offday_name": "اسم يوم العطلة",
  "israel": "إسرائيل",
  "USA": "الولايات المتحدة الأمريكية",
  "poland": "بولندا",
  "mexico": "المكسيك",
  "cyprus": "قبرص",
  "brazil": "البرازيل",
  "tablet_survey": "استطلاع التابلت",
  "no_shift_survey": "لا يوجد وردية",
  "number_of_rows": "عدد الصفوف",
  "rating_shape": "شكل التقييم",
  "content_survey": "محتوى الاستطلاع",
  "rating_survey": "تقييم الاستطلاع",
  "device-alerts": "مشكلة",
  "device-active": "نشيط",
  "device-search": "يبحث",
  "mac_id": "عنوان ماك",
  "qr_tag": "علامة QR",
  "sensor": "المستشعر",
  "image_survey": "صورة الاستطلاع",
  "open_text": "نص مفتوح",
  "open_text_survey": "نص مفتوح",
  "embedded_link": "رابط مضمن",
  "device-counter": "عدادات",
  "count": "عدد",
  "last_date": "اخر موعد",
  "last_arrival":"آخر وصول نظافة",
  "cleaners": "عمال النظافة",
  "viewer": "عارض",
  "android": "أندرويد",
  "ios": "آيفون",
  "no_users_found": "لم يتم العثور على مستخدمين",
  "no_cleaners_found": "لم يتم العثور على عمال النظافة",
  "new_cleaner": "عامل نظافة جديد",
  "edit_cleaner": "تعديل عامل نظافة",
  "please_select": "يرجى اختيار",
  "working_areas": "مناطق العمل",
  "isWorkingInSite": "يعمل في الموقع",
  "you_are_not_allowed_to_edit": "غير مسموح لك بالتعديل",
  "you_are_not_allowed_to_remove": "غير مسموح لك بالحذف",
  "task": "مهمة",
  "portuguese": "البرتغالية",
  "russian": "الروسية",
  "spanish": "الأسبانية",
  "polish": "البولندية",
  "installer": "مثبت",
  "once": "مرة واحدة",
  "time_saved": "الوقت المحفوظ",
  "yes": "نعم",
  "no": "لا",
  "workingAreaNames": "اسماء مناطق العمل",
  "shift_rooms":"غرف الوردية",
  "new_shift":"وردية جديده",
  "add_shift_inactive": "اضافه وردية غير ناشطه",
  "remove_auto_shift_inactive": "يزيل وردية غير ناشطه",
  "additional_options": "المزيد من الخيارات",
  "add_default_shifts": "اضافه ورديات افتراضية",
  "no_user_found": "لم يتم العثور على مستخدم",
  "no_site_found": "لم يتم العثور على موقع",
  "service_provider": "مزود الخدمة",
  "qr task": "مهمة QR",
  "qr_task": "مهمة QR",
  "all_day": "طوال اليوم",
  "auto_start": "بدء تلقائي",
  "english_title": "العنوان بالإنجليزية",
  "connectivity": "الاتصال",
  "power": "الطاقة",
  "Your work has been saved": "تم حفظ عملك",
  "schedule_call_time": "وقت الطلب",
  "schedule_call_gap": "فجوة الطلب",
  "gapInMinutes": "الفجوة بالدقائق",
  "no_manual_calls_available": "لا توجد مكالمات يدوية متاحة",
  "manual_calls": "مكالمات يدوية",
  "cleandex_calls": "تنبيهات كلينديكس",
  "cleandexCalls": "تنبيهات كلينديكس",
  "manualCalls": "مكالمات يدوية",
  "inactive": "غير نشط",
  "on hold": "تجميد",
  "sequential": "تسلسلي",
  "ratio": "نسبة",
  "must_choose": "يجب اختيار",
  "surveyApp": "تطبيق الاستطلاع",
  "installation": "تثبيت",
  "manager": "مدير",
}

export const pl = {
  ...serviks.pl,
  "notified": "Powiadomiono",
  "supervisor_arrival": "Inspekcja wykonana",
  "arrival": "Serwis",
  "filter": "Filtr",
  "logo": "Logo",
  "counter": "Licznik wejść",
  "sms": "SMS",
  "shifts": "Zmiany",
  "call": "Zgłoszenie",
  "duration": "Czas trwania",
  "time_start": "Czas rozpoczęcia",
  "settings": "Ustawienia",
  "working_area": "Obszar pracy",
  "complaints_NC": "Zgłoszenia i NC",
  "SLA_NC": "SLA i NC",
  "arrivals_NC": "Serwisy i NC",
  "staff": "Personel",
  "cleaning_complaints": "Zgłoszenia na temat sprzątania",
  "export": "Eksport",
  "maintenance_complaints": "Zgłoszenia na temat konserwacji",
  "maintenanceArrivals": "Wykonane konserwacje",
  "maintenance_arrivals": "Wykonane konserwacje",
  "last": "Ostatni",
  "survey_name": "Nazwa badania",
  "survey": "Badanie",
  "pad_survey": "Badanie typu panel",
  "pad_kitchen": "Badanie typu kuchnia",
  "number_of_assigned_devices": "Liczba urządzeń",
  "number_of_pages": "Liczba stron",
  "date_created": "Data utworzenia",
  "updated_by": "Zaktualizowane przez",
  "date_updated": "Data aktualizacji",
  "search": "Szukaj",
  "choose_type": "Wybierz typ",
  "permitted_sites": "Dostępne budynki",
  "permitted_franchise": "Dostępni partnerzy",
  "permitted_companies": "Dostępne firmy",
  "panel_permitted": "Dostępny panel",
  "end_time": "Godzina zakończenia",
  "last_update": "Ostatnia aktualizacja",
  "add_device": "Dodaj urządzenie",
  "detailed": "Szczegóły",
  "analysis": "Analiza",
  "suggestion": "Sugestie",
  "country": "Kraj",
  "management": "Zarządzanie",
  "device": "Urządzenie",
  "mapping": "Mapowanie",
  "please_enter": "Wprowadź",
  "new_device": "Nowe urządzenie",
  "assign_device": "Przypisz urządzenie",
  "installSpot": "Miejsce instalacji",
  "device_type": "Typ urządzenia",
  "deviceCode": "Kod urządzenia",
  "device_code": "Kod urządzenia",
  "room": "Pomieszczenie",
  "edit_room": "Edytuj pomieszczenie",
  "edit_franchise": "Edytuj partnera",
  "new_franchise": "Nowy partner",
  "desc": "Opis",
  "address": "Adres",
  "level": "Piętro",
  "no_companies_to_show": "Brak firm do wyświetlenia",
  "company_type": "Typ firmy",
  "region": "Region",
  "new_company": "Nowa firma",
  "edit_company": "Edytuj firmę",
  "edit_user": "Edytuj użytkownika",
  "C.arrivals": "Serwis wykonany",
  "C.complaints": "Zgłoszenia na temat sprzątania",
  "M.complaints": "Zgłoszenia na temat konserwacji",
  "M.Arrivals": "Konserwacja wykonana",
  "analyses": "Analizy",
  "clean_log": "Dziennik serwisów",
  "maintenance_log": "Dziennik konserwacji",
  "like": "Lubię",
  "new_user": "Nowy użytkownik",
  "shift_parameters": "Parametry zmiany",
  "add_shift": "Dodaj zmianę",
  "edit_shift": "Edytuj zmianę",
  "username": "Nazwa użytkownika",
  "first_name": "Imię",
  "firstName": "Imię",
  "description": "Opis",
  "last_name": "Nazwisko",
  "lastName": "Nazwisko",
  "phone": "Telefon",
  "building_area": "Powierzchnia budynku",
  "users": "Użytkownicy",
  "add_user": "Dodaj użytkownika",
  "sites": "Miejsca",
  "new_site": "Nowy budynek",
  "edit_site": "Edytuj budynek",
  "add_company": "Dodaj firmę",
  "franchise": "Partner",
  "floor": "Piętro",
  "building": "Budynek",
  "gender": "Płeć",
  "display_name": "Wyświetlana nazwa",
  "zone": "Strefa",
  "shift_number": "Numer",
  "shift_name": "Nazwa",
  "end": "Koniec",
  "min_sla": "Min SLA",
  "max_sla": "Max SLA",
  "days": "Dni",
  "complaints_percent": "% Zgłoszeń",
  "sla_percent": "% SLA",
  "arrivals_percent": "% Serwisów",
  "save_arrivals": "Zapisz serwisy",
  "company_name": "Nazwa firmy",
  "site_name": "Nazwa miejsca",
  "site_desc": "Opis miejsca",
  "service_provider_name": "Nazwa dostawcy usług",
  "service_provider_type": "Typ dostawcy usług",
  "service_provider_desc": "Opis dostawcy usług",
  "user": "Użytkownik",
  "map": "Mapa",
  "delete": "Usuń",
  "new_room": "Nowe Pomieszczenie",
  "issue_times": "Wystąpienia",
  "solved": "Rozwiązane",
  "visits": "Wizyty",
  "complaints": "Zgłoszenia",
  "cleaner_arrivals": "Wykonane serwisy",
  "response_time_SLA": "Czas reakcji",
  "score": "Ocena",
  "avg_score": "Średnia ocena",
  "score_goal": "Planowana ocena",
  "top_problem_issues": "Najczęstsze problemy",
  "top_problem_rooms": "Problematyczne pomieszczenia",
  "top_problem_hours": "Problematyczne godziny",
  "is_time_save": "Oszczędzony czas",
  "est_ROI": "Szacowany ROI",
  "complaints_vs_visits": "Zgłoszenia vs Wizyty",
  "view_by_years": "W latach",
  "view_by_months": "W miesiącach",
  "view_by_weeks": "W tygodniach",
  "view_by_days": "W dniach",
  "minutes": "min",
  "company": "Firma",
  "site": "Budynek",
  "alerts": "Informacje o urządzeniach",
  "performance": "Wydajność",
  "devices": "Urządzenia",
  "surveys": "Badania",
  "real_time": "Na żywo",
  "real-time": "Na żywo",
  "admin": "Admin",
  "all": "Wszystko",
  "overview": "Przegląd",
  "analytics": "Analiza",
  "last_time_of_arrival": "Ostatni serwis",
  "arrival_and_status": "Serwis i status",
  "preview_arrivals": "Przegląd serwisów",
  "men toilet": "Toaleta męska",
  "women toilet": "Toaleta damska",
  "shared toilet": "Toaleta wspólna",
  "handicapped toilet": "Toaleta dla niepełnosprawnych",
  monthMap: {
    1: 'Styczeń',
    2: 'Luty',
    3: 'Marzec',
    4: 'Kwiecień',
    5: 'Maj',
    6: 'Czerwiec',
    7: 'Lipiec',
    8: 'Sierpień',
    9: 'Wrzesień',
    10: 'Październik',
    11: 'Listopad',
    12: 'Grudzień',
  },
  weekMap: {
    1: 'Niedziela',
    2: 'Poniedziałek',
    3: 'Wtorek',
    4: 'Środa',
    5: 'Czwartek',
    6: 'Piątek',
    7: 'Sobota',
  },
  "dayOfWeek": "Dzień tygodnia",
  "service_log": "Dziennik działań",
  "rooms": "Pomieszczenia",
  "displayName": "Nazwa wyświetlana",
  "SLA": "SLA",
  "supervisors": "Koordynatorzy",
  "supervisor": "Koordynator",
  "performance_analytics": "Analizy wydajności",
  "arrivals": "Serwisy",
  "inspections": "Inspekcje",
  "avgResponseTime": "Średni czas reakcji",
  "missedSla": "Nie dotrzymano SLA",
  "cleaner_arrived": "Serwis wykonany",
  "load_more": "Pokaż więcej",
  "login": "Zaloguj",
  "email": "Email",
  "emails": "Emaile",
  "password": "Hasło",
  "min": "min",
  "Logout": "Wyloguj",
  "custom_time": "Wybierz",
  "last_day": "Poprzedni dzień",
  "last_week": "Poprzedni tydzień",
  "last_month": "Poprzedni miesiąc",
  "last_quarter": "Poprzedni kwartał",
  "last_6_months": "Ostatnie 6 miesięcy",
  "last_year": "Poprzedni rok",
  "data_by_hour": "Godzinowo",
  "general": "Ogólne",
  "cleaning": "Sprzątanie",
  "maintenance": "Konserwacja",
  "start": "Początek",
  "start_time": "Godzina rozpoczęcia",
  "management_co": "Firma zarządzająca",
  "cleaning_co": "Firma sprzątająca",
  "companies": "Firmy",
  "name": "Nazwa",
  "site_count": "Numer budynku",
  "associated_companies": "Powiązane firmy",
  "OK": "OK",
  "cancel": "Anuluj",
  "client": "Klient",
  "dates": "Daty",
  "shift": "Zmiana",
  "issue": "Problem",
  "time": "Czas",
  "top complaint of the week": "Najczęstsze zgłoszenia w tym tygodniu",
  "top room of the week": "Problematyczne pomieszczenie w tym tygodniu",
  "top hour of the week": "Problematyczna godzina w tym tygodniu",
  "top complaint of the month": "Najczęstsze zgłoszenie w tym miesiącu",
  "top room of the month": "Problematyczne pomieszczenie w tym miesiącu",
  "top hour of the month": "Problematyczna godzina w tym miesiącu",
  "franchises": "Partnerzy",
  "add_franchise": "Dodaj partnera",
  "room_names": "Nazwy pomieszczeń",
  "type": "Typ",
  "please_select_company_site": "Proszę wybrać firmę/budynek",
  "number": "Numer",
  "create_building_map": "Utwórz mapę budynku",
  "hey quickest way to add floors and rooms to a buildings via this quick wizard": "Hej, najszybciej dodasz piętra i pomieszczenia do budynku za pomocą tego prostego kreatora",
  "avg number of toilets per floor": "Średnia liczba toalet na piętro",
  "avg.number of toilet cells per room": "Średnia liczba kabin w toalecie",
  "number of floors": "Liczba pięter",
  "avg.number of disabled toilets": "Średnia liczba toalet dla niepełnosprawnych",
  "avg.number of soap dispensers": "Średnia liczba dozowników mydła",
  "reset_filter": "Wyczyść filtr",
  "add": "Dodaj",
  "new_survey": "Nowe badanie",
  "api_configuration": "Konfiguracja API",
  "please_edit_the_api_information": "Proszę wprowadzić informacje API",
  "method": "Metoda",
  "authorization": "Autoryzacja",
  "api_url": "URL_API",
  "token": "Token",
  "api_request_info": "Informacje o żądaniu API",
  "roomName": "Pomieszczenie",
  "servikName": "Nazwa usługi",
  "siteName": "Nazwa budynku",
  "stage": "Etap",
  "number_of_buttons": "Liczba przycisków",
  "button_shape": "Kształt przycisku",
  "delay_time_stage": "Czas opóźnienia etapu",
  "go_to_stage": "Przejdź do etapu",
  "color": "Kolor",
  "add_stage": "Dodaj etap",
  "save": "Zapisz",
  "number_of_issues": "Liczba problemów",
  "online": "Online",
  "offline": "Offline",
  "room_name": "Nazwa pomieszczenia",
  "installation_spot": "Miejsce instalacji",
  "device_id": "ID urządzenia",
  "date": "Data",
  "status": "Status",
  "device_status": "Status urządzenia",
  "none": "Brak",
  "no_surveys_created": "Nie utworzono żadnych badań",
  "dashboard": "Kokpit",
  "franchise_name": "Nazwa partnera",
  "franchise_country": "Kraj partnera",
  "choose_franchise": "Wybierz partnera",
  "choose_country": "Wybierz kraj",
  "select_template": "Wybierz szablon",
  "default_survey": "Domyślne badanie",
  "cleaning_survey": "Badanie sprzątania",
  "kitchen_survey": "Badanie kuchni",
  "template": "Szablon",
  "area_name": "Nazwa obszaru",
  "rooms_names": "Nazwy pokoi",
  "rooms_number": "Numery pokoi",
  "assign_number": "Przypisz numer",
  "active_notification_task": "Aktywne powiadomienie",
  "amount_limit": "Limit",
  "after_max": "Licznik po Max",
  "new_area": "Nowy obszar",
  "tablet": "Tablet",
  "counter women": "Licznik wejść / damska",
  "counter men": "Licznik wejść / męska",
  "counter handicap": "Licznik wejść / niepełnosprawni",
  "counter man/woman": "Licznik wejsć męska/damska",
  "counter man / woman": "Licznik wejść męska / damska",
  "sensor liquid": "Sensor poziomu płynu",
  "double toilet paper": "Podwójna rolka papieru toaletowego",
  "toilet paper": "Rolka papieru toaletowego",
  "tag": "Kod",
  "qr tag": "Kod QR",
  "additional_info": "Dodatkowe informacje",
  "servikCategory": "Typ połączenia",
  "dishwashing": "Zmywanie naczyń",
  "cafeteria": "Bistro",
  "clean room": "Pomieszczenie gospodarcze",
  "office": "Biuro",
  "meeting room": "Pokój konferencyjny",
  "cubic": "Kubik",
  "women": "Kobiety",
  "men": "Mężczyźni",
  "handicap": "Niepełnosprawni",
  "Combined": "Wspólne",
  "kitchen": "Kuchnia",
  "east": "Wschód",
  "west": "Zachód",
  "north": "Północ",
  "south": "Południe",
  "right": "Prawo",
  "left": "Lewo",
  "center": "Środek",
  "up": "Góra",
  "down": "Dół",
  "combined": "Połączone",
  "lobby": "Lobby",
  "entrance": "Wejście",
  "reception": "Recepcja",
  "area": "Obszar",
  "mezzanine": "Antresola",
  "gallery": "Galeria",
  "ground": "Podłoga",
  "elevator": "Winda",
  "is_calculated": "Uwzględniaj w statystykach",
  "calculated_room": "Uwzględnione pomieszczenie",
  "splitted_survey": "Podzielone badanie",
  "redirect_to_url": "Przekieruj do URL",
  "survey_site": "Badanie budynku",
  "clicks": "Kliknięcia",
  "add_stage_number_to_signal": "Przypisz numer etapu",
  "including_pad9": "W tym Pad9",
  "default_name": "Domyślna nazwa",
  "exposures": "Ekspozycje",
  "expose_results": "Ekspozycje",
  "events_expose": "Szczegóły ekspozycji",
  "expose_score": "Poziom ekspozycji",
  "shift_events_expose": "Wydarzenia w trakcie zmiany",
  "type_of_site": "Typ budynku",
  "morning_shift": "Zmiana poranna",
  "afternoon_shift": "Zmiana popołudniowa",
  "night_shift": "Zmiana nocna",
  "update": "Aktualizuj",
  "restart": "Zrestartuj",
  "send_data": "Wysyłaj dane",
  "send_count": "Wysyłaj odczyty licznika wejść",
  "blink": "Miganie LED",
  "maintenance_btn": "Przycisk konserwacji",
  "battery": "Bateria",
  "version": "Wersja",
  "actions": "Ustawienia",
  "drains_battery": "Rozładowuje baterię",
  "latest_errors": "Ostatnie błędy",
  "no_errors": "Brak błędów",
  "offline_calls": "Zgłoszenia w trybie offline",
  "offline_duration_calls": "Czas przyjmowania zgłoszeń w trybie offline",
  "counterCalls": "Wezwania licznikiem wejść",
  "active": "Aktywny",
  "active_calculation": "Uwzględniaj w statystykach",
  "pad": "pad",
  "pad_counter": "Licznik wejść",
  "counter_status": "Status licznika wejść",
  "choose_state": "Wybierz województwo",
  "choose_city": "Wybierz miasto",
  "city": "Miasto",
  "state": "Województwo",
  "start_hour": "Godzina rozpoczęcia",
  "end_hour": "Godzina zakończenia",
  "analytics_log": "Logi analityczne",
  "or": "lub",
  "no_data_available": "Brak danych",
  "new_task": "Nowe zadanie",
  "category": "Kategoria",
  "title": "Tytuł",
  "task_type": "Typ zadania",
  "task_category": "Kategoria zadania",
  "call_to_action": "Działanie",
  "schedule": "Harmonogram",
  "insert_text": "Wstaw tekst",
  "select": "Wybierz",
  "schedule_dates": "Harmonogram",
  "language": "Język",
  "calendar": "Kalendarz",
  "operation": "Operacja",
  "daily": "Codziennie",
  "weekly": "Tygodniowo",
  "monthly": "Miesięcznie",
  "quarterly": "Kwartalnie",
  "yearly": "Rocznie",
  "sunday": "Niedziela",
  "monday": "Poniedziałek",
  "tuesday": "Wtorek",
  "wednesday": "Środa",
  "thursday": "Czwartek",
  "friday": "Piątek",
  "saturday": "Sobota",
  "january": "Styczeń",
  "february": "Luty",
  "march": "Marzec",
  "april": "Kwiecień",
  "may": "Maj",
  "june": "Czerwiec",
  "july": "Lipiec",
  "august": "Sierpień",
  "september": "Wrzesień",
  "october": "Październik",
  "november": "Listopad",
  "december": "Grudzień",
  "1st week": "Pierwszy tydzień",
  "2nd week": "Drugi tydzień",
  "3rd week": "Trzeci tydzień",
  "4th week": "Czwarty tydzień",
  "5th week": "Piąty tydzień",
  "1st quarter": "Pierwszy kwartał",
  "2nd quarter": "Drugi kwartał",
  "3rd quarter": "Trzeci kwartał",
  "4th quarter": "Czwarty kwartał",
  "edit_task": "Edytuj zadanie",
  "created_at": "Utworzono",
  "hour": "Godzina",
  "week_days": "Dni tygodnia",
  "month_weeks": "Tygodnie miesiąca",
  "month_days": "Dni miesiąca",
  "no_tasks_found": "Nie znaleziono zadań",
  "invalid_language": "Nieprawidłowy język",
  "no_active_tasks_found": "Nie znaleziono aktywnych zadań",
  "edit": "Edytuj",
  "send_date": "Wyślij datę",
  "invalid_date": "Nieprawidłowa data",
  "cells_num": "Liczba kabin",
  "sinks_num": "Liczba zlewów",
  "urinals_num": "Liczba pisuarów",
  "toilet_paper_size": "Rozmiar papieru toaletowego",
  "email_configuration": "Konfiguracja e-mail",
  "please_edit_the_email_information": "Proszę wprowadzić informacje e-mail",
  "email_sender_info": "Nadawca",
  "email_subject_info": "Temat wiadomości",
  "email_message_info": "Wiadomość",
  "companyName": "Nazwa firmy",
  "email_language": "Język e-mail",
  "complaint_language": "Język zgłoszeń",
  "email_message_format": "Format wiadomości e-mail",
  "line": "Linia",
  "list": "Lista",
  "roomType": "Typ pomieszczenia",
  "By logging in, you agree to Buzzztech's": "Logując się akceptujesz regulamin korzystania z usługi Buzzztech",
  "Terms and Conditions": "Regulamin",
  "and": "i",
  "Privacy Policy": "Polityka prywatności",
  "All actions within this panel are recorded for quality control and improvement": "Wszystkie działania w tym panelu są zapisywane w celu kontroli jakości i ciągłego udoskonalania usługi",
  "You must agree to the terms and conditions": "Zaakceptuj regulamin korzystania",
  "months": "Miesiące",
  "ref_id": "ID referencyjne",
  "room_name_en": "Nazwa pomieszczenia (EN)",
  "site_state": "Państwo",
  "site_city": "Miasto",
  "site_country": "Kraj",
  "first_date": "Data początkowa",
  "pinned": "Przypięty",
  "no.": "Nr",
  "must_be_numbers": "Musi być liczbą",
  "must_be_3_digits": "Minimum 3 cyfry",
  "must_have_english_title": "Musi mieć angielski tytuł",
  "total_sites": "Liczba budynków",
  "site_information": "Informacje o budynku",
  "holiday_offdays": "Święta i dni wolne",
  "add_offday": "Dodaj dzień wolny",
  "add_holiday": "Dodaj święto",
  "name_under_chars": "nazwa poniżej 25 znaków",
  "days_comma_separated": "dni, oddzielone przecinkiem",
  "year": "rok",
  "month": "miesiąc",
  "day": "dzień",
  "holiday_name": "nazwa święta",
  "offday_name": "nazwa dnia wolnego",
  "israel": "Izrael",
  "USA": "USA",
  "poland": "Polska",
  "mexico": "Meksyk",
  "cyprus": "Cypr",
  "brazil": "Brazylia",
  "tablet_survey": "Badanie z tabletem",
  "no_shift_survey": "Brak zmiany badania",
  "number_of_rows": "Liczba wierszy",
  "rating_shape": "Kształt oceny",
  "content_survey": "Zawartość badania",
  "rating_survey": "Ocena badania",
  "device-alerts": "Problem",
  "device-active": "Aktywny",
  "device-search": "Szukaj",
  "mac_id": "Adres MAC",
  "qr_tag": "Kod QR",
  "sensor": "Czujnik",
  "image_survey": "Badanie obrazu",
  "open_text": "Otwarty tekst",
  "open_text_survey": "Badanie tekstu",
  "embedded_link": "Osadzony link",
  "device-counter": "Licznik wejść",
  "count": "Liczba",
  "last_date": "Ostatnia data",
  "last_arrival":"Ostatni serwis",
  "cleaners": "Zespół serwisowy",
  "viewer": "Widz",
  "android": "Android",
  "ios": "iOS",
  "no_users_found": "Nie znaleziono użytkowników",
  "no_cleaners_found": "Nie znaleziono pracownika serwisu",
  "new_cleaner": "Nowy serwisant",
  "edit_cleaner": "Edytuj serwisanta",
  "please_select": "Proszę wybrać",
  "working_areas": "Obszary robocze",
  "isWorkingInSite": "Pracuje w budynku",
  "you_are_not_allowed_to_edit": "Nie masz uprawnień do edycji",
  "you_are_not_allowed_to_remove": "Nie masz uprawnień do usunięcia",
  "task": "Zadania",
  "portuguese": "Portugalski",
  "russian": "Rosyjski",
  "spanish": "Hiszpański",
  "polish": "Polski",
  "installer": "Instalator",
  "once": "Raz",
  "time_saved": "Oszczędzony czas",
  "yes": "Tak",
  "no": "Nie",
  "workingAreaNames": "Nazwy obszarów pracy",
  "shift_rooms":"Pomieszczenia",
  "new_shift":"Nowa zmiana",
  "add_shift_inactive": "Dodaj nieaktywne zmiany",
  "remove_auto_shift_inactive": "Usuń automatyczne zmiany nieaktywne",
  "additional_options": "Opcje zaawansowane",
  "add_default_shifts": "Dodaj domyślne zmiany",
  "no_user_found": "Nie znaleziono użytkownika",
  "no_site_found": "Nie znaleziono budynku",
  "service_provider": "Dostawca usług",
  "qr task": "Zadanie QR",
  "qr_task": "Zadanie QR",
  "all_day": "Cały dzień",
  "auto_start": "Automatyczny start",
  "english_title": "Tytuł w języku angielskim",
  "connectivity": "Połączenie",
  "power": "Zasilanie",
  "Your work has been saved": "Twoja praca została zapisana",
  "schedule_call_time": "Harmonogram czasu połączenia",
  "schedule_call_gap": "Harmonogram przerwy w połączeniu",
  "gapInMinutes": "Przerwa w minutach",
  "no_manual_calls_available": "Brak dostępnych połączeń ręcznych",
  "manual_calls": "Połączenia ręczne",
  "cleandex_calls": "Zgłoszenia Cleandex",
  "cleandexCalls": "Zgłoszenia Cleandex",
  "manualCalls": "Połączenia ręczne",
  "inactive": "Nieaktywny",
  "on hold": "Wstrzymane",
  "sequential": "Kolejny",
  "ratio": "Stosunek",
  "must_choose": "Musisz wybrać",
  "surveyApp": "Aplikacja badawcza",
  "installation": "Instalacja",
  "manager": "Menedżer",
}

export const uk = {
  ...serviks.uk,
  "notified": "Повідомлено",
  "supervisor_arrival": "Інспекція виконана",
  "arrival": "Сервіс",
  "filter": "Фільтр",
  "logo": "Логотип",
  "counter": "Лічильник входів",
  "sms": "SMS",
  "shifts": "Зміни",
  "call": "Виклик",
  "duration": "Тривалість",
  "time_start": "Час початку",
  "settings": "Налаштування",
  "working_area": "Робоча зона",
  "complaints_NC": "Скарги та NC",
  "SLA_NC": "SLA та NC",
  "arrivals_NC": "Сервіси та NC",
  "staff": "Персонал",
  "cleaning_complaints": "Скарги на прибирання",
  "export": "Експорт",
  "maintenance_complaints": "Скарги на обслуговування",
  "maintenanceArrivals": "Виконані обслуговування",
  "maintenance_arrivals": "Виконані обслуговування",
  "last": "Останній",
  "survey_name": "Назва опитування",
  "survey": "Опитування",
  "pad_survey": "Опитування типу панель",
  "pad_kitchen": "Опитування типу кухня",
  "number_of_assigned_devices": "Кількість призначених пристроїв",
  "number_of_pages": "Кількість сторінок",
  "date_created": "Дата створення",
  "updated_by": "Оновлено користувачем",
  "date_updated": "Дата оновлення",
  "search": "Пошук",
  "choose_type": "Виберіть тип",
  "permitted_sites": "Дозволені сайти",
  "permitted_franchise": "Дозволені франшизи",
  "permitted_companies": "Дозволені компанії",
  "panel_permitted": "Дозволена панель",
  "end_time": "Час завершення",
  "last_update": "Останнє оновлення",
  "add_device": "Додати пристрій",
  "detailed": "Детально",
  "analysis": "Аналіз",
  "suggestion": "Пропозиція",
  "country": "Країна",
  "management": "Управління",
  "device": "Пристрій",
  "mapping": "Карта",
  "please_enter": "Будь ласка, введіть",
  "new_device": "Новий пристрій",
  "assign_device": "Призначити пристрій",
  "installSpot": "Місце встановлення",
  "device_type": "Тип пристрою",
  "deviceCode": "Код пристрою",
  "device_code": "Код пристрою",
  "room": "Кімната",
  "edit_room": "Редагувати кімнату",
  "edit_franchise": "Редагувати франшизу",
  "new_franchise": "Нова франшиза",
  "desc": "Опис",
  "address": "Адреса",
  "level": "Рівень",
  "no_companies_to_show": "Немає компаній для відображення",
  "company_type": "Тип компанії",
  "region": "Регіон",
  "new_company": "Нова компанія",
  "edit_company": "Редагувати компанію",
  "edit_user": "Редагувати користувача",
  "C.arrivals": "Виконані сервіси",
  "C.complaints": "Скарги на прибирання",
  "M.complaints": "Скарги на обслуговування",
  "M.Arrivals": "Виконані обслуговування",
  "analyses": "Аналізи",
  "clean_log": "Журнал сервісу",
  "maintenance_log": "Журнал обслуговування",
  "like": "Лайк",
  "new_user": "Новий користувач",
  "shift_parameters": "Параметри зміни",
  "add_shift": "Додати зміну",
  "edit_shift": "Редагувати зміну",
  "username": "Ім'я користувача",
  "first_name": "Ім'я",
  "firstName": "Ім'я",
  "description": "Опис",
  "last_name": "Прізвище",
  "lastName": "Прізвище",
  "phone": "Телефон",
  "building_area": "Площа будівлі",
  "users": "Користувачі",
  "add_user": "Додати користувача",
  "sites": "Місця",
  "new_site": "Нова будівля",
  "edit_site": "Редагувати будівлю",
  "add_company": "Додати компанію",
  "franchise": "Франшиза",
  "floor": "Поверх",
  "building": "Будівля",
  "gender": "Стать",
  "display_name": "Відображуване ім'я",
  "zone": "Зона",
  "shift_number": "Номер",
  "shift_name": "Назва",
  "end": "Кінець",
  "min_sla": "Мін SLA",
  "max_sla": "Макс SLA",
  "days": "Дні",
  "complaints_percent": "% Скарг",
  "sla_percent": "% SLA",
  "arrivals_percent": "% Сервісів",
  "save_arrivals": "Зберегти сервіси",
  "company_name": "Назва компанії",
  "site_name": "Назва місця",
  "site_desc": "Опис місця",
  "service_provider_name": "Назва постачальника послуг",
  "service_provider_type": "Тип постачальника послуг",
  "service_provider_desc": "Опис постачальника послуг",
  "user": "Користувач",
  "map": "Карта",
  "delete": "Видалити",
  "new_room": "Нове приміщення",
  "issue_times": "Часи виникнення",
  "solved": "Вирішено",
  "visits": "Візити",
  "complaints": "Скарги",
  "cleaner_arrivals": "Виконані сервіси",
  "response_time_SLA": "Час реакції",
  "score": "Оцінка",
  "avg_score": "Середня оцінка",
  "score_goal": "Планована оцінка",
  "top_problem_issues": "Найбільші проблеми",
  "top_problem_rooms": "Проблемні приміщення",
  "top_problem_hours": "Проблемні години",
  "is_time_save": "Економія часу",
  "est_ROI": "Приблизний ROI",
  "complaints_vs_visits": "Скарги проти візитів",
  "view_by_years": "За роками",
  "view_by_months": "За місяцями",
  "view_by_weeks": "За тижнями",
  "view_by_days": "За днями",
  "minutes": "хв",
  "company": "Компанія",
  "site": "Будівля",
  "alerts": "Сповіщення про пристрої",
  "performance": "Продуктивність",
  "devices": "Пристрої",
  "surveys": "Опитування",
  "real_time": "В реальному часі",
  "real-time": "В реальному часі",
  "admin": "Адмін",
  "all": "Все",
  "overview": "Огляд",
  "analytics": "Аналітика",
  "last_time_of_arrival": "Останній сервіс",
  "arrival_and_status": "Сервіс і статус",
  "preview_arrivals": "Перегляд сервісів",
  "men toilet": "Чоловічий туалет",
  "women toilet": "Жіночий туалет",
  "shared toilet": "Спільний туалет",
  "handicapped toilet": "Туалет для людей з обмеженими можливостями",
  "monthMap": {
      1: "Січень",
      2: "Лютий",
      3: "Березень",
      4: "Квітень",
      5: "Травень",
      6: "Червень",
      7: "Липень",
      8: "Серпень",
      9: "Вересень",
      10: "Жовтень",
      11: "Листопад",
      12: "Грудень"
    },
  "weekMap": {
    1: "Неділя",
    2: "Понеділок",
    3: "Вівторок",
    4: "Середа",
    5: "Четвер",
    6: "П'ятниця",
    7: "Субота"
  },
  "dayOfWeek": "День тижня",
  "service_log": "Журнал обслуговування",
  "rooms": "Кімнати",
  "displayName": "Відображувана назва",
  "SLA": "SLA",
  "supervisors": "Супервізори",
  "supervisor": "Супервізор",
  "performance_analytics": "Аналіз продуктивності",
  "arrivals": "Сервіси",
  "inspections": "Інспекції",
  "avgResponseTime": "Середній час реакції",
  "missedSla": "Невиконане SLA",
  "cleaner_arrived": "Виконано сервіс",
  "load_more": "Завантажити ще",
  "login": "Увійти",
  "email": "Електронна пошта",
  "emails": "Електронні листи",
  "password": "Пароль",
  "min": "хв",
  "Logout": "Вийти",
  "custom_time": "Вибрати",
  "last_day": "Останній день",
  "last_week": "Минулий тиждень",
  "last_month": "Минулий місяць",
  "last_quarter": "Минулий квартал",
  "last_6_months": "Останні 6 місяців",
  "last_year": "Минулий рік",
  "data_by_hour": "По годинах",
  "general": "Загальне",
  "cleaning": "Прибирання",
  "maintenance": "Технічне обслуговування",
  "start": "Початок",
  "start_time": "Час початку",
  "management_co": "Керівна компанія",
  "cleaning_co": "Прибиральна компанія",
  "companies": "Компанії",
  "name": "Назва",
  "site_count": "Кількість місць",
  "associated_companies": "Пов'язані компанії",
  "OK": "OK",
  "cancel": "Скасувати",
  "client": "Клієнт",
  "dates": "Дати",
  "shift": "Зміна",
  "issue": "Проблема",
  "time": "Час",
  "top complaint of the week": "Найпоширеніші скарги на тиждень",
  "top room of the week": "Проблемне приміщення на цьому тижні",
  "top hour of the week": "Проблемний час на цьому тижні",
  "top complaint of the month": "Найпоширеніша скарга цього місяця",
  "top room of the month": "Проблемне приміщення цього місяця",
  "top hour of the month": "Проблемний час цього місяця",
  "franchises": "Франшизи",
  "add_franchise": "Додати франшизу",
  "room_names": "Назви приміщень",
  "type": "Тип",
  "please_select_company_site": "Будь ласка, виберіть фірму/будівлю",
  "number": "Номер",
  "create_building_map": "Створити карту будівлі",
  "hey quickest way to add floors and rooms to a buildings via this quick wizard": "Привіт, найшвидший спосіб додати поверхи та кімнати до будівлі за допомогою цього швидкого майстра",
  "avg number of toilets per floor": "Середня кількість туалетів на поверсі",
  "avg.number of toilet cells per room": "Середня кількість кабінок у туалеті",
  "number of floors": "Кількість поверхів",
  "avg.number of disabled toilets": "Середня кількість туалетів для інвалідів",
  "avg.number of soap dispensers": "Середня кількість диспенсерів для милка",
  "reset_filter": "Скинути фільтр",
  "add": "Додати",
  "new_survey": "Нове опитування",
  "api_configuration": "Конфігурація API",
  "please_edit_the_api_information": "Будь ласка, відредагуйте інформацію API",
  "method": "Метод",
  "authorization": "Авторизація",
  "api_url": "URL API",
  "token": "Токен",
  "api_request_info": "Інформація про запит API",
  "roomName": "Назва кімнати",
  "servikName": "Назва скарги",
  "siteName": "Назва будівлі",
  "stage": "Етап",
  "number_of_buttons": "Кількість кнопок",
  "button_shape": "Форма кнопки",
  "delay_time_stage": "Час затримки етапу",
  "go_to_stage": "Перейти до етапу",
  "color": "Колір",
  "add_stage": "Додати етап",
  "save": "Зберегти",
  "number_of_issues": "Кількість проблем",
  "online": "Онлайн",
  "offline": "Офлайн",
  "room_name": "Назва кімнати",
  "installation_spot": "Місце установки",
  "device_id": "Ідентифікатор пристрою",
  "date": "Дата",
  "status": "Статус",
  "device_status": "Статус пристрою",
  "none": "Немає",
  "no_surveys_created": "Не створено жодних опитувань",
  "dashboard": "Інформаційна панель",
  "franchise_name": "Назва франшизи",
  "franchise_country": "Країна франшизи",
  "choose_franchise": "Виберіть франшизу",
  "choose_country": "Виберіть країну",
  "select_template": "Виберіть шаблон",
  "default_survey": "Стандартне опитування",
  "cleaning_survey": "Опитування про прибирання",
  "kitchen_survey": "Опитування кухні",
  "template": "Шаблон",
  "area_name": "Назва області",
  "rooms_names": "Назви кімнат",
  "rooms_number": "Номери кімнат",
  "assign_number": "Призначити номер",
  "active_notification_task": "Активне сповіщення",
  "amount_limit": "Ліміт",
  "after_max": "Після макс.",
  "new_area": "Нова область",
  "tablet": "Планшет",
  "counter women": "Лічильник / жінки",
  "counter men": "Лічильник / чоловіки",
  "counter handicap": "Лічильник / інваліди",
  "counter man/woman": "Лічильник чоловіки/жінки",
  "counter man / woman": "Лічильник чоловіки / жінки",
  "sensor liquid": "Рівень рідини",
  "double toilet paper": "Подвійний туалетний папір",
  "toilet paper": "Туалетний папір",
  "tag": "Мітка",
  "qr tag": "QR-код",
  "additional_info": "Додаткова інформація",
  "servikCategory": "Категорія сервісу",
  "dishwashing": "Миття посуду",
  "cafeteria": "Кафетерій",
  "clean room": "Прибиральна кімната",
  "office": "Офіс",
  "meeting room": "Зала для зустрічей",
  "cubic": "Кубічний",
  "women": "Жінки",
  "men": "Чоловіки",
  "handicap": "Інваліди",
  "Combined": "Комбінований",
  "kitchen": "Кухня",
  "east": "Схід",
  "west": "Захід",
  "north": "Північ",
  "south": "Південь",
  "right": "Право",
  "left": "Ліво",
  "center": "Центр",
  "up": "Вгору",
  "down": "Вниз",
  "combined": "Комбіноване",
  "lobby": "Лобі",
  "entrance": "Вхід",
  "reception": "Рецепція",
  "area": "Область",
  "mezzanine": "Мезонін",
  "gallery": "Галерея",
  "ground": "Підлога",
  "elevator": "Ліфт",
  "is_calculated": "Враховувати у статистиці",
  "calculated_room": "Врахована кімната",
  "splitted_survey": "Розділене дослідження",
  "redirect_to_url": "Перенаправлення на URL",
  "survey_site": "Дослідження місця",
  "clicks": "Кліки",
  "add_stage_number_to_signal": "Додати номер етапу до сигналу",
  "including_pad9": "Включаючи Pad9",
  "default_name": "Спеціальне ім'я",
  "exposures": "Експозиції",
  "expose_results": "Результати експозиції",
  "events_expose": "Події експозиції",
  "expose_score": "Оцінка експозиції",
  "shift_events_expose": "Події зміни експозиції",
  "type_of_site": "Тип місця",
  "morning_shift": "Ранкова зміна",
  "afternoon_shift": "Післяполуденна зміна",
  "night_shift": "Нічна зміна",
  "update": "Оновлення",
  "restart": "Перезапуск",
  "send_data": "Надсилати дані",
  "send_count": "Надсилати лічильник",
  "blink": "Миготіння",
  "maintenance_btn": "Кнопка обслуговування",
  "battery": "Батарея",
  "version": "Версія",
  "actions": "Дії",
  "drains_battery": "Розряджає батарею",
  "latest_errors": "Останні помилки",
  "no_errors": "Без помилок",
  "offline_calls": "Виклики в автономному режимі",
  "offline_duration_calls": "Тривалість викликів в автономному режимі",
  "counterCalls": "Виклики лічильником",
  "active": "Активний",
  "active_calculation": "Враховувати у статистиці",
  "pad": "планшет",
  "pad_counter": "Лічильник",
  "counter_status": "Статус лічильника",
  "choose_state": "Виберіть область",
  "choose_city": "Виберіть місто",
  "city": "Місто",
  "state": "Область",
  "start_hour": "Початкова година",
  "end_hour": "Кінцева година",
  "analytics_log": "Журнал аналітики",
  "or": "або",
  "no_data_available": "Дані відсутні",
  "new_task": "Нове завдання",
  "category": "Категорія",
  "title": "Назва",
  "task_type": "Тип завдання",
  "task_category": "Категорія завдання",
  "call_to_action": "Виклик до дії",
  "schedule": "Розклад",
  "insert_text": "Вставити текст",
  "select": "Вибрати",
  "schedule_dates": "Розклад",
  "language": "Мова",
  "calendar": "Календар",
  "operation": "Операція",
  "daily": "Щоденно",
  "weekly": "Щотижня",
  "monthly": "Щомісяця",
  "quarterly": "Квартально",
  "yearly": "Щорічно",
  "sunday": "Неділя",
  "monday": "Понеділок",
  "tuesday": "Вівторок",
  "wednesday": "Середа",
  "thursday": "Четвер",
  "friday": "П'ятниця",
  "saturday": "Субота",
  "january": "Січень",
  "february": "Лютий",
  "march": "Березень",
  "april": "Квітень",
  "may": "Травень",
  "june": "Червень",
  "july": "Липень",
  "august": "Серпень",
  "september": "Вересень",
  "october": "Жовтень",
  "november": "Листопад",
  "december": "Грудень",
  "1st week": "Перший тиждень",
  "2nd week": "Другий тиждень",
  "3rd week": "Третій тиждень",
  "4th week": "Четвертий тиждень",
  "5th week": "П'ятий тиждень",
  "1st quarter": "Перший квартал",
  "2nd quarter": "Другий квартал",
  "3rd quarter": "Третій квартал",
  "4th quarter": "Четвертий квартал",
  "edit_task": "Редагувати завдання",
  "created_at": "Створено",
  "hour": "Година",
  "week_days": "Дні тижня",
  "month_weeks": "Тижні місяця",
  "month_days": "Дні місяця",
  "no_tasks_found": "Задачі не знайдено",
  "invalid_language": "Неправильна мова",
  "no_active_tasks_found": "Активні задачі не знайдено",
  "edit": "Редагувати",
  "send_date": "Відправити дату",
  "invalid_date": "Неправильна дата",
  "cells_num": "Кількість кабін",
  "sinks_num": "Кількість раковин",
  "urinals_num": "Кількість унітазів",
  "toilet_paper_size": "Розмір туалетного паперу",
  "email_configuration": "Налаштування електронної пошти",
  "please_edit_the_email_information": "Будь ласка, введіть інформацію про електронну пошту",
  "email_sender_info": "Відправник",
  "email_subject_info": "Тема повідомлення",
  "email_message_info": "Повідомлення",
  "companyName": "Назва компанії",
  "email_language": "Мова електронної пошти",
  "complaint_language": "Мова скарг",
  "email_message_format": "Формат повідомлення електронної пошти",
  "line": "Рядок",
  "list": "Список",
  "roomType": "Тип приміщення",
  "By logging in, you agree to Buzzztech's": "Логуючись, ви погоджуєтеся з умовами користування сервісом Buzzztech",
  "Terms and Conditions": "Умови користування",
  "and": "і",
  "Privacy Policy": "Політика конфіденційності",
  "All actions within this panel are recorded for quality control and improvement": "Всі дії в цій панелі записуються для контролю якості та покращення",
  "You must agree to the terms and conditions": "Ви повинні погодитися з умовами користування",
  "months": "Місяці",
  "ref_id": "ID посилання",
  "room_name_en": "Назва приміщення (EN)",
  "site_state": "Штат",
  "site_city": "Місто",
  "site_country": "Країна",
  "first_date": "Перша дата",
  "pinned": "Закріплено",
  "no.": "№",
  "must_be_numbers": "Повинно бути числом",
  "must_be_3_digits": "Мінімум 3 цифри",
  "must_have_english_title": "Має мати англійський заголовок",
  "total_sites": "Загальна кількість будівель",
  "site_information": "Інформація про будівлю",
  "holiday_offdays": "Свята та вихідні",
  "add_offday": "Додати вихідний",
  "add_holiday": "Додати свято",
  "name_under_chars": "назва менше 25 символів",
  "days_comma_separated": "дні, розділені комою",
  "year": "рік",
  "month": "місяць",
  "day": "день",
  "holiday_name": "назва свята",
  "offday_name": "назва вихідного дня",
  "israel": "Ізраїль",
  "USA": "США",
  "poland": "Польща",
  "mexico": "Мексика",
  "cyprus": "Кіпр",
  "brazil": "Бразилія",
  "tablet_survey": "Опитування з планшетом",
  "no_shift_survey": "Відсутнє опитування про зміни",
  "number_of_rows": "Кількість рядків",
  "rating_shape": "Форма оцінки",
  "content_survey": "Зміст опитування",
  "rating_survey": "Оцінка опитування",
  "device-alerts": "Попередження",
  "device-active": "Активний",
  "device-search": "Пошук",
  "mac_id": "MAC-адреса",
  "qr_tag": "QR-код",
  "sensor": "Датчик",
  "image_survey": "Опитування зображення",
  "open_text": "Відкритий текст",
  "open_text_survey": "Опитування тексту",
  "embedded_link": "Вбудоване посилання",
  "device-counter": "Лічильник пристроїв",
  "count": "Кількість",
  "last_date": "Остання дата",
  "last_arrival":"Останній прихід",
  "cleaners": "Прибиральники",
  "viewer": "Переглядач",
  "android": "Android",
  "ios": "iOS",
  "no_users_found": "Користувачів не знайдено",
  "no_cleaners_found": "Прибиральники не знайдені",
  "new_cleaner": "Новий прибиральник",
  "edit_cleaner": "Редагувати прибиральника",
  "please_select": "Будь ласка, виберіть",
  "working_areas": "Робочі зони",
  "isWorkingInSite": "Працює на місці",
  "you_are_not_allowed_to_edit": "Вам не дозволено редагувати",
  "you_are_not_allowed_to_remove": "Вам не дозволено видаляти",
  "task": "Завдання",
  "portuguese": "Португальська",
  "russian": "Російська",
  "spanish": "Іспанська",
  "polish": "Польська",
  "installer": "Інсталятор",
  "once": "Одного разу",
  "time_saved": "Збережений час",
  "yes": "Так",
  "no": "Ні",
  "workingAreaNames": "Назви робочих зон",
  "shift_rooms":"Приміщення",
  "new_shift":"Нова зміна",
  "add_shift_inactive": "Додати неактивні зміни",
  "remove_auto_shift_inactive": "Видалити автоматичні неактивні зміни",
  "additional_options": "Додаткові опції",
  "add_default_shifts": "Додати типові зміни",
  "no_user_found": "Користувача не знайдено",
  "no_site_found": "Не знайдено будівлі",
  "service_provider": "Постачальник послуг",
  "qr task": "QR-код завдання",
  "qr_task": "QR-код завдання",
  "all_day": "Весь день",
  "auto_start": "Автозапуск",
  "english_title": "Англійський заголовок",
  "connectivity": "Підключення",
  "power": "Живлення",
  "Your work has been saved": "Вашу роботу збережено",
  "schedule_call_time": "Час виклику",
  "schedule_call_gap": "Інтервал виклику",
  "gapInMinutes": "Інтервал в хвилинах",
  "no_manual_calls_available": "Немає доступних вручну викликів",
  "manual_calls": "Вручні виклики",
  "cleandex_calls": "Виклики Cleandex",
  "cleandexCalls": "Виклики Cleandex",
  "manualCalls": "Вручні виклики",
  "inactive": "Неактивний",
  "on hold": "На утриманні",
  "sequential": "Послідов",
  "ratio": "Відношення",
  "must_choose": "Потрібно вибрати",
  "surveyApp": "Додаток опитування",
  "installation": "Установка",
  "manager": "Менеджер",
}

export const ro = {
  ...serviks.ro,
  "notified": "Notificat",
  "supervisor_arrival": "Sosirea supervizorului",
  "arrival": "Sosire",
  "filter": "Filtru",
  "logo": "Logo",
  "counter": "Contor",
  "sms": "SMS",
  "shifts": "Schimburi",
  "call": "Apel",
  "duration": "Durată",
  "time_start": "Timpul de începere",
  "settings": "Setări",
  "working_area": "Zonă de lucru",
  "complaints_NC": "Reclamații",
  "SLA_NC": "SLA",
  "arrivals_NC": "Sosiri",
  "staff": "Personal",
  "cleaning_complaints": "Reclamații de curățare",
  "export": "Export",
  "maintenance_complaints": "Reclamații de întreținere",
  "maintenanceArrivals": "Sosiri de întreținere",
  "maintenance_arrivals": "Sosiri de întreținere",
  "last": "Ultimul",
  "survey_name": "Numele sondajului",
  "survey": "Sondaj",
  "pad_survey": "Sondaj pad",
  "pad_kitchen": "Bucătărie pad",
  "number_of_assigned_devices": "Numărul de dispozitive atribuite",
  "number_of_pages": "Numărul de pagini",
  "date_created": "Data creării",
  "updated_by": "Actualizat de",
  "date_updated": "Data actualizării",
  "search": "Căutare",
  "choose_type": "Alegeți tipul",
  "permitted_sites": "Site-uri permise",
  "permitted_franchise": "Franciză permisă",
  "permitted_companies": "Companii permise",
  "panel_permitted": "Panou permis",
  "end_time": "Timpul de încheiere",
  "last_update": "Ultima actualizare",
  "add_device": "Adăugați dispozitiv",
  "detailed": "Detaliat",
  "analysis": "Analiză",
  "suggestion": "Sugestie",
  "country": "Țară",
  "management": "Management",
  "device": "Dispozitiv",
  "mapping": "Hartă",
  "please_enter": "Vă rugăm să introduceți",
  "new_device": "Dispozitiv nou",
  "assign_device": "Atribuiți dispozitivul",
  "installSpot": "Locul de instalare",
  "device_type": "Tipul dispozitivului",
  "deviceCode": "Codul dispozitivului",
  "device_code": "Codul dispozitivului",
  "room": "cameră",
  "edit_room": "Editați cameră",
  "edit_franchise": "Editați franciza",
  "new_franchise": "Franciză nouă",
  "desc": "Descriere",
  "address": "Adresă",
  "level": "Nivel",
  "no_companies_to_show": "Nu există companii de afișat",
  "company_type": "Tipul companiei",
  "region": "Regiune",
  "new_company": "Companie nouă",
  "edit_company": "Editați compania",
  "edit_user": "Editați utilizatorul",
  "C.arrivals": "Sosiri",
  "C.complaints": "Reclamații",
  "M.complaints": "Reclamații",
  "M.Arrivals": "Sosiri",
  "analyses": "Analize",
  "clean_log": "Jurnal de curățare",
  "maintenance_log": "Jurnal de întreținere",
  "like": "Asemănător",
  "new_user": "Utilizator nou",
  "shift_parameters": "Parametrii de schimb",
  "add_shift": "Adăugați schimb",
  "edit_shift": "Editați schimb",
  "username": "Nume de utilizator",
  "first_name": "Prenume",
  "firstName": "Prenume",
  "description": "Descriere",
  "last_name": "Nume",
  "lastName": "Nume",
  "phone": "Telefon",
  "building_area": "Zonă de construcție",
  "users": "Utilizatori",
  "add_user": "Adăugați utilizator",
  "sites": "Site-uri",
  "new_site": "Site nou",
  "edit_site": "Editați site-ul",
  "add_company": "Adăugați companie",
  "franchise": "Franciză",
  "floor": "Etaj", 
  "building": "Clădire", 
  "gender": "Gen", 
  "display_name": "Numele afișat", 
  "zone": "Zonă", 
  "shift_number": "Numărul de schimburi",
  "shift_name": "Numele schimbului",
  "end": "Sfârșit", 
  "min_sla": "SLA minim", 
  "max_sla": "SLA maxim",
  "days": "Zile",  
  "complaints_percent": "Procentajul reclamațiilor", 
  "sla_percent": "Procentajul SLA", 
  "arrivals_percent": "Procentajul sosirilor", 
  "save_arrivals": "Salvați sosirile", 
  "company_name": "Numele companiei", 
  "site_name": "Numele site-ului", 
  "site_desc": "Descrierea site-ului", 
  "service_provider_name": "Numele furnizorului de servicii", 
  "service_provider_type": "Tipul furnizorului de servicii", 
  "service_provider_desc": "Descrierea furnizorului de servicii",
  "user": "Utilizator", 
  "map": "Hartă", 
  "delete": "Șterge", 
  "new_room": "Cameră nouă", 
  "issue_times": "Timpurile problemelor", 
  "solved": "Rezolvat", 
  "visits": "Vizite",
  "complaints": "Reclamații", 
  "cleaner_arrivals": "Sosirile curățătorului",   
  "response_time_SLA": "Timpul de răspuns SLA",
  "score": "Scor", 
  "avg_score": "Scor mediu",
  "score_goal": "Scorul dorit",
  "top_problem_issues": "Cele mai mari probleme",
  "top_problem_rooms": "Cele mai mari probleme",
  "top_problem_hours": "Cele mai mari probleme",
  "is_time_save": "Este timpul salvat", 
  "est_ROI": "Estimarea ROI",
  "complaints_vs_visits": "Reclamații vs. Vizite",
  "view_by_years": "Vizualizare pe ani",
  "view_by_months": "Vizualizare pe luni",
  "view_by_weeks": "Vizualizare pe săptămâni",
  "view_by_days": "Vizualizare pe zile",
  "minutes": "Minute",
  "company": "Companie",
  "site": "Site",
  "alerts": "Alerte",
  "performance": "Performanță", 
  "devices": "Dispozitive",
  "surveys": "Sondaje", 
  "real_time": "Timp real",
  "real-time": "Timp real",
  "admin": "Admin",
  "all": "Toate",
  "overview": "Prezentare generală",
  "analytics": "Analize", 
  "last_time_of_arrival": "Ultima dată de sosire", 
  "arrival_and_status": "Sosire și stare",
  "preview_arrivals": "Previzualizare sosiri",
  "men toilet": "Toaletă pentru bărbați", 
  "women toilet": "Toaletă pentru femei",
  "shared toilet": "Toaletă comună", 
  "handicapped toilet": "Toaletă pentru persoane cu dizabilități", 
  monthMap: {
    1: "Ianuarie",
    2: "Februarie",
    3: "Martie", 
    4: "Aprilie", 
    5: "Mai", 
    6: "Iunie",
    7: "Iulie", 
    8: "August", 
    9: "Septembrie",
    10: "Octombrie",
    11: "Noiembrie",
    12: "Decembrie" 
  },
  weekMap: {
    1: "Luni", 
    2: "Marți", 
    3: "Miercuri", 
    4: "Joi",
    5: "Vineri",
    6: "Sâmbătă",
    7: "Duminică"
  },
  "dayOfWeek": "Ziua săptămânii",
  "service_log": "Jurnal de serviciu",
  "rooms": "Camere",
  "displayName": "Numele afișat",
  "SLA": "SLA",
  "supervisors": "Supervizori",
  "supervisor": "Supervizor",
  "performance_analytics": "Analize de performanță",
  "arrivals": "Sosiri",
  "inspections": "Inspecții",
  "avgResponseTime": "Timpul mediu de răspuns",
  "missedSla": "SLA ratat",
  "cleaner_arrived": "Curățătorul a sosit",
  "load_more": "Încărcați mai mult",
  "login": "Autentificare",
  "email": "Email",
  "emails": "Emailuri", 
  "password": "Parolă",
  "min": "Min",
  "Logout": "Deconectare",
  "custom_time": "Timp personalizat",
  "last_day": "Ultima zi",
  "last_week": "Ultima săptămână",
  "last_month": "Ultima lună",
  "last_quarter": "Ultimul trimestru",
  "last_6_months": "Ultimele 6 luni",
  "last_year": "Ultimul an",
  "data_by_hour": "Date pe oră",
  "general": "General",
  "cleaning": "Curățare",
  "maintenance": "Întreținere",
  "start": "Start",
  "start_time": "Timpul de începere",
  "management_co": "Companii de management",
  "cleaning_co": "Companii de curățare",
  "companies": "Companii",
  "name": "Nume",
  "site_count": "Numărul de site-uri",
  "associated_companies": "Companii asociate",
  "OK": "OK",
  "cancel": "Anulare",
  "client": "Client",
  "dates": "Date",
  "shift": "Schimb",
  "issue": "Problemă",
  "time": "Timp",
  "top complaint of the week": "Topul plângerilor săptămânii",
  "top room of the week": "Topul camerei săptămânii",
  "top hour of the week": "Topul orei săptămânii",
  "top complaint of the month": "Topul plângerilor lunii",
  "top room of the month": "Topul camerei lunii",
  "top hour of the month": "Topul orei lunii",
  "franchises": "Francize",
  "add_franchise": "Adăugați franciză",
  "room_names": "Numele camerelor",
  "type": "Tip",
  "please_select_company_site": "Vă rugăm să selectați compania și site-ul",
  "number": "Număr",
  "create_building_map": "Creați harta clădirii", 
  "hey quickest way to add floors and rooms to a buildings via this quick wizard": "Cel mai rapid mod de a adăuga etaje și camere într-o clădire prin intermediul acestui asistent rapid",
  "avg number of toilets per floor": "Numărul mediu de toalete pe etaj",
  "avg.number of toilet cells per room": "Numărul mediu de celule de toaletă pe cameră",
  "number of floors": "Numărul de etaje",
  "avg.number of disabled toilets": "Numărul mediu de toalete pentru persoane cu dizabilități",
  "avg.number of soap dispensers": "Numărul mediu de dozatoare de săpun",
  "reset_filter": "Resetați filtrul",
  "add": "Adăugați",
  "new_survey": "Sondaj nou",
  "api_configuration": "Configurare API",
  "please_edit_the_api_information": "Vă rugăm să editați informațiile API",
  "method": "Metodă",
  "authorization": "Autorizare",
  "api_url": "URL API",
  "token": "Token",
  "api_request_info": "Informații despre cererea API",
  "roomName": "Numele camerei",
  "servikName": "Numele reclamației",
  "siteName": "Numele site-ului",
  "stage": "Etapa",
  "number_of_buttons": "Numărul de butoane",
  "button_shape": "Forma butonului",
  "delay_time_stage": "Timpul de întârziere al etapei",
  "go_to_stage": "Du-te la etapă",
  "color": "Culoare",
  "add_stage": "Adăugați etapă",
  "save": "Salvați",
  "number_of_issues": "Numărul de probleme",
  "online": "Online",
  "offline": "Offline",
  "room_name": "Numele camerei",
  "installation_spot": "Locul de instalare",
  "device_id": "ID dispozitiv",
  "date": "Data",
  "status": "Stare",
  "device_status": "Starea dispozitivului",
  "none": "Niciunul",
  "no_surveys_created": "Nu s-au creat sondaje", 
  "dashboard": "Tablou de bord",
  "franchise_name": "Numele francizei",
  "franchise_country": "Țara francizei",
  "choose_franchise": "Alegeți franciza",
  "choose_country": "Alegeți țara",
  "select_template": "Selectați șablonul",
  "default_survey": "Sondaj implicit",
  "cleaning_survey": "Sondaj de curățare",
  "kitchen_survey": "Sondaj de bucătărie",
  "template": "Șablon",
  "area_name": "Numele zonei",
  "rooms_names": "Numele camerelor",
  "rooms_number": "Numărul camerelor",
  "assign_number": "Atribuiți numărul",
  "active_notification_task": "Activare notificare",
  "amount_limit": "Limita de sumă",
  "after_max": "După maxim",
  "new_area": "Zonă nouă",
  "tablet": "Tabletă",
  "counter women": "Contor femei",
  "counter men": "Contor bărbați",
  "counter handicap": "Contor persoane cu dizabilități",
  "counter man/woman": "Contor bărbați/femei",
  "counter man / woman": "Contor bărbați / femei",
  "sensor liquid": "Senzor lichid",
  "double toilet paper": "Hârtie igienică dublă",
  "toilet paper": "Hârtie igienică",
  "tag": "Etichetă",
  "qr tag": "Etichetă QR",
  "additional_info": "Informații suplimentare",
  "servikCategory": "Categorie servik",
  "dishwashing": "Spălarea vaselor",
  "cafeteria": "Cafenea",
  "clean room": "Cameră curată",
  "office": "Oficiu",
  "meeting room": "Sală de întâlniri",
  "cubic": "Cubic",
  "women": "Femei",
  "men": "Bărbați",
  "handicap": "Persoane cu dizabilități",
  "Combined": "Combinat",
  "kitchen": "Bucătărie",
  "east": "Est",
  "west": "Vest",
  "north": "Nord",
  "south": "Sud",
  "right": "Dreapta",
  "left": "Stânga",
  "center": "Centru",
  "up": "Sus",
  "down": "Jos",
  "combined": "Combinat",
  "lobby": "Hol",
  "entrance": "Intrare",
  "reception": "Recepție",
  "area": "Zonă",
  "mezzanine": "Mezanin",
  "gallery": "Galerie",
  "ground": "Parter",
  "elevator": "Lift",
  "is_calculated": "Este calculat",
  "calculated_room": "Cameră calculată",
  "splitted_survey": "Sondaj împărțit",
  "redirect_to_url": "Redirecționare către URL",
  "survey_site": "Site-ul sondajului",
  "clicks": "Clicuri",
  "add_stage_number_to_signal": "Adăugați numărul etapei la semnal",
  "including_pad9": "Inclusiv pad9",
  "default_name": "Nume implicit",
  "exposures": "Expuneri",
  "expose_results": "Rezultatele expunerii",
  "events_expose": "Evenimente expuse",
  "expose_score": "Scor expus",
  "shift_events_expose": "Evenimente de schimbare expuse",
  "type_of_site": "Tipul site-ului",
  "morning_shift": "Schimb de dimineață",
  "afternoon_shift": "Schimb de după-amiază",
  "night_shift": "Schimb de noapte",
  "update": "Actualizare",
  "restart": "Repornire",
  "send_data": "Trimiteți datele",
  "send_count": "Trimiteți numărul",
  "blink": "Clipi",
  "maintenance_btn": "Buton de întreținere",
  "battery": "Baterie",
  "version": "Versiune",
  "actions": "Acțiuni",
  "drains_battery": "Bateria se scurge",
  "latest_errors": "Ultimele erori",
  "no_errors": "Fără erori",
  "offline_calls": "Apeluri offline",
  "offline_duration_calls": "Durata apelurilor offline",
  "counterCalls": "Apeluri de contor",
  "active": "Activ", 
  "active_calculation": "Calcul activ",
  "pad": "Pad",
  "pad_counter": "Contor pad",
  "counter_status": "Starea contorului",
  "choose_state": "Alegeți statul",
  "choose_city": "Alegeți orașul",
  "start_hour": "Ora de începere",
  "end_hour": "Ora de încheiere", 
  "analytics_log": "Jurnal de analize",
  "or": "sau", 
  "no_data_available": "Nu sunt date disponibile",
  "new_task": "Sarcină nouă",
  "category": "Categorie",
  "title": "Titlu",
  "task_type": "Tip de sarcină",
  "task_category": "Categorie de sarcină",
  "call_to_action": "Apel la acțiune",
  "schedule": "Programare",
  "insert_text": "Introduceți text",
  "select": "Selectați",
  "schedule_dates": "Programați datele",
  "language": "Limbă",
  "calendar": "Calendar", 
  "operation": "Operație", 
  "daily": "Zilnic",
  "weekly": "Săptămânal",
  "monthly": "Lunar",
  "quarterly": "Trimestrial",
  "yearly": "Anual",
  "sunday": "Duminică",
  "monday": "Luni", 
  "tuesday": "Marți",
  "wednesday": "Miercuri",
  "thursday": "Joi",
  "friday": "Vineri", 
  "saturday": "Sâmbătă", 
  "january": "Ianuarie",
  "february": "Februarie",
  "march": "Martie",
  "april": "Aprilie",
  "may": "Mai",
  "june": "Iunie",
  "july": "Iulie",
  "august": "August",
  "september": "Septembrie",
  "october": "Octombrie",
  "november": "Noiembrie",
  "december": "Decembrie",
  "1st week": "1 săptămână",
  "2nd week": "2 săptămână",
  "3rd week": "3 săptămână",
  "4th week": "4 săptămână",
  "5th week": "5 săptămână",
  "1st quarter": "1 trimestru",
  "2nd quarter": "2 trimestru",
  "3rd quarter": "3 trimestru",
  "4th quarter": "4 trimestru",
  "edit_task":  "Editați sarcina",
  "created_at":  "Creat la",
  "hour":  "Oră",
  "week_days": "Zilele săptămânii",
  "month_weeks": "Săptămânile lunii",
  "month_days": "Zilele lunii",
  "no_tasks_found": "Nu s-au găsit sarcini",
  "invalid_language": "Limbă invalidă",
  "no_active_tasks_found": "Nu s-au găsit sarcini active",
  "edit": "Editați",
  "send_date": "Trimiteți data",
  "invalid_date": "Data invalidă",
  "cells_num": "Numărul de celule",
  "sinks_num": "Numărul de chiuvete",
  "urinals_num": "Numărul de urinoare",
  "toilet_paper_size": "Dimensiunea hârtiei igienice",
  "email_configuration": "Configurare email",
  "please_edit_the_email_information": "Vă rugăm să editați informațiile de email",
  "email_sender_info": "Informații despre expeditorul de email",
  "email_subject_info": "Informații despre subiectul emailului",
  "email_message_info": "Informații despre mesajul emailului",
  "companyName": "Numele companiei",
  "email_language": "Limbă de email",
  "complaint_language": "Limbă de reclamație",
  "email_message_format": "Formatul mesajului email",
  "line": "Linie",
  "list": "Listă",
  "roomType": "Tipul camerei",
  "By logging in, you agree to Buzzztech's": "Prin conectare, sunteți de acord cu",
  "Terms and Conditions": "Termeni și condiții",
  "and": "și",
  "Privacy Policy": "Politica de confidențialitate",
  "All actions within this panel are recorded for quality control and improvement": "Toate acțiunile din acest panou sunt înregistrate pentru controlul calității și îmbunătățirea",
  "You must agree to the terms and conditions": "Trebuie să fiți de acord cu termenii și condițiile",
  "months": "Luni",
  "ref_id": "ID de referință",
  "room_name_en": "Numele camerei în engleză",
  "site_state": "Statul site-ului",
  "site_city": "Orașul site-ului",
  "city": "Oraș",
  "state": "Stat", 
  "site_country": "Țara site-ului",
  "first_date": "Prima dată",
  "pinned": "Pinned",
  "no.": "Nr.",
  "must_be_numbers": "Trebuie să fie numere",
  "must_be_3_digits": "Trebuie să fie 3 cifre",
  "must_have_english_title": "Trebuie să aibă titlu în engleză",
  "total_sites": "Total site-uri",
  "site_information": "Informații despre site",
  "holiday_offdays": "Sărbători și zile libere",
  "add_offday": "Adăugați zi liberă",
  "add_holiday": "Adăugați sărbătoare",
  "name_under_chars": "Numele trebuie să fie sub 50 de caractere",
  "days_comma_separated": "zile, separate prin virgulă",
  "year": "an",
  "month": "lună",
  "day": "zi",
  "holiday_name": "numele sărbătorii",
  "offday_name": "numele zilei libere",
  "israel": "Israel",
  "USA": "SUA",
  "poland": "Polonia",
  "mexico": "Mexic",
  "cyprus": "Cipru",
  "brazil": "Brazilia",
  "tablet_survey": "Sondaj tabletă",
  "no_shift_survey": "Niciun sondaj de schimb",
  "number_of_rows": "Numărul de rânduri",
  "rating_shape": "Forma de evaluare",
  "content_survey": "Conținutul sondajului",
  "rating_survey": "Evaluarea sondajului",
  "device-alerts": "Alerte dispozitiv",
  "device-active": "Dispozitiv activ",
  "device-search": "Căutare dispozitiv",
  "mac_id": "Adresa MAC",
  "qr_tag": "Cod QR",
  "sensor": "Senor",
  "image_survey": "Sondaj de imagine",
  "open_text": "Text deschis",
  "open_text_survey": "Sondaj de text deschis",
  "embedded_link": "Link încorporat",
  "device-counter": "Contor dispozitiv",
  "count": "Număr",
  "last_date": "Ultima dată",
  "last_arrival": "Ultima sosire",
  "cleaners": "Curățători",
  "viewer": "Vizualizator",
  "android": "Android",
  "ios": "iOS",
  "no_users_found": "Nu s-au găsit utilizatori",
  "no_cleaners_found": "Nu s-au găsit curățători",
  "new_cleaner": "Curățător nou",
  "edit_cleaner": "Editați curățătorul",
  "please_select": "Vă rugăm să selectați",
  "working_areas": "Zone de lucru",
  "isWorkingInSite": "Lucrează în site",
  "you_are_not_allowed_to_edit": "Nu aveți permisiunea de a edita",
  "you_are_not_allowed_to_remove": "Nu aveți permisiunea de a șterge",
  "task": "Sarcină",
  "portuguese": "Portugheză",
  "russian": "Rusă",
  "spanish": "Spaniolă",
  "polish": "Poloneză",
  "installer": "Instalator",
  "once": "O dată",
  "time_saved": "Timp salvat",
  "yes": "Da",
  "no": "Nu",
  "workingAreaNames": "Numele zonelor de lucru",
  "shift_rooms": "Camere de schimb",
  "new_shift": "Schimb nou",
  "add_shift_inactive": "Adăugați schimburi inactive",
  "remove_auto_shift_inactive": "Eliminați schimburi automate inactive",
  "additional_options": "Opțiuni suplimentare",
  "add_default_shifts": "Adăugați schimburi implicite",
  "no_user_found": "Utilizatorul nu a fost găsit",
  "no_site_found": "Site-ul nu a fost găsit",
  "service_provider": "Furnizor de servicii",
  "qr task": "Sarcină QR",
  "qr_task": "Sarcină QR",
  "all_day": "Toată ziua",
  "auto_start": "Pornire automată",
  "english_title": "Titlu în engleză",
  "connectivity": "Conectivitate",
  "power": "Putere",
  "Your work has been saved": "Munca ta a fost salvată",
  "schedule_call_time": "Timpul de apelare",
  "schedule_call_gap": "Intervalul de apelare",
  "gapInMinutes": "Interval în minute",
  "no_manual_calls_available": "Nu sunt apeluri manuale disponibile",
  "manual_calls": "Apeluri manuale",
  "cleandexCalls": "Apeluri Cleandex",
  "manualCalls": "Apeluri manuale",
  "inactive": "Inactiv",
  "on hold": "În așteptare",
  "sequential": "Secvențial",
  "ratio": "Raport",
  "must_choose": "Trebuie să alegeți",
  "surveyApp": "Aplicație de sondaj",
  "installation": "Instalare",
  "manager": "Manager",
}