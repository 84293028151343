<template>
    <div v-if="!isAdminOpen" class="side-nav-container">
        <ul class="nav-item-list">
          <template v-for="item in sideBarNavItems">
            <li :key="item.name" v-if="hasPermission(item.permission) && !hiddenNavItem.includes(item.name)">
              <router-link :to="item.path">{{$t(item.name)}}</router-link>
            </li>
          </template>
        </ul>
        <template v-if="loggedUser">
          <div class="seperator"></div>
          <button class="admin-link" v-on="loggedUser.type === 'admin' ? { click: adminButtonClick } : { click: logout }">
                <img :src="require('@/assets/admin_icon.svg')">
                <span>
                  {{$t(loggedUser.type === 'admin' ? 'settings' : 'Logout')}}
                </span>
          </button>
        </template>
    </div>
    <div v-else class="side-nav-container admin">
        <ul class="nav-item-list">
          <li>
            <div class="back-btn" @click="adminButtonClick">
              <TriangleIcon :direction="isRTL ? 'right' : 'left'" />
              <span>{{ $t('admin') }}</span>
            </div>
          </li>
           <template v-for="item in sideBarAdminNavItems">
            <li :key="item.name" v-if="hasPermission(item.permission) && !hiddenNavItem.includes(item.name)">
              <router-link :to="item.path">{{$t(item.name)}}</router-link>
            </li>
          </template>
        </ul>
        <button class="admin-link" @click="logout">
              <img :src="require('@/assets/admin_icon.svg')">
              <span>
                {{$t('Logout')}}
              </span>
        </button>
    </div>
</template>

<script>
import authService from '@/modules/auth/services/authService';
import TriangleIcon from '@/modules/common/components/TriangleIcon';
import { mapGetters } from 'vuex';
import siteService from "../../control-panel/services/siteService";
import { sideBarAdminNavItems, sideBarNavItems} from '../consts/consts';

export default {
  data() {
    return {
      alerts: [],
      isAdminOpen: false,
      hiddenNavItem:[],
      sideBarAdminNavItems,
      sideBarNavItems
    }
  },
  async created() {
    if (this.loggedUser.siteIdsToShow.length === 0 && this.loggedUser.type === 'admin') {
      this.adminButtonClick()
    }
    this.alerts = await this.getAlerts();
  },
  async mounted() {
   await this.hideNavItems()
  },
  computed: mapGetters(['isRTL', 'loggedUser','filterSelected']),
  methods: {
     hasPermission(item) {
      const user = this.loggedUser;
      if(!user) return false;
      const hasPermision = !!user.roles.find(role => role === item)
      return hasPermision
    },
    async hideNavItems() {
      const defaultHiddenNavItems = {
          operation: ['task'],
          survey: ['real_time', 'task', 'planning', 'users'],
          task: ['survey', 'real_time'],
          realtime: ['dashboard', 'survey', 'rooms', 'devices', 'working_area', 'users', 'task'],
          cleandex: [],
          default: ['task']
      }
      // Reset hiddenNavItem to empty by default
      this.hiddenNavItem = []
      // Early return if filterSelected or site is not defined
        if (!this.filterSelected || !this.filterSelected.site) {
        this.hiddenNavItem = ['site', 'user']
        return this.hiddenNavItem;
      }
      try {
        const siteInfo = await siteService.getSiteById(this.filterSelected.site)
        // Ensure siteInfo is valid and has a type
        if (siteInfo && siteInfo.type) {
          if (siteInfo.type === 'realtime' && this.$route.path !== '/real-time') {
              this.$router.push('/real-time');
          }
          // Assign hiddenNavItem based on site type
          this.hiddenNavItem = defaultHiddenNavItems[siteInfo.type] || defaultHiddenNavItems.default
        }
      } catch (err) {
        console.error('Error fetching site info:')
        this.hiddenNavItem = []
      }
      return this.hiddenNavItem
    },
    adminButtonClick() {
      this.isAdminOpen = !this.isAdminOpen;
    },
    getAlerts() {
      return Promise.resolve([1,2,3]);
    },
    logout() {
      authService.logout();
      this.$router.push('/login');
    }
  },
  watch:{
    'filterSelected.site': {
      deep: true,
      immediate: true,
      handler: function (newValue) {
        this.hideNavItems();
      },
    },
  },
  components: { TriangleIcon }
}
</script>

<style lang="scss" scoped>
@import "@/styles/components/sideNavbar";
</style>